<template>
  <div
    :id="message._id"
    ref="message"
    class="vac-message-wrapper"
  >
    <!-- <pre>{{message}}</pre> -->
    <div
      v-if="showDate"
      class="vac-card-info vac-card-date"
    >
      {{ getHeaderText(message) }}
    </div>

    <div
      v-if="newMessage._id === message._id"
      class="vac-line-new"
    >
      {{ textMessages.NEW_MESSAGES }}
    </div>

    <div
      v-if="message.system"
      class="vac-card-info vac-card-system"
    >
      <format-message
        :all-contacts="allContacts"
        :content="message.content"
        :users="roomUsers"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        @open-user-tag="openUserTag"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </format-message>
    </div>

    <div
      v-else
      class="vac-message-box"
      :class="{ 'vac-offset-current': message.fromMe, 'align-center': showCheckbox }"
    >
      <slot
        name="message"
        v-bind="{ message }"
      >
        <!-- <div
          v-if="message.avatar && message.senderId !== currentUserId"
          class="vac-avatar"
          :style="{ 'background-image': `url('${message.avatar}')` }"
        /> -->
        <!-- <div
          v-if="hasSenderUserAvatar && !message.avatar"
          class="vac-avatar-offset"
        /> -->
        <transition name="vac-fade-message">
          <div
            v-if="showCheckbox"
            :class="{ 'ml-3': !message.fromMe }"
          >
            <v-simple-checkbox
              v-model="message.selected"
              :ripple="false"
            ></v-simple-checkbox>
          </div>
        </transition>

        <div
          class="vac-message-container"
          :class="{
            'vac-message-container-offset': messageOffset || !sameDay,
          }"
        >
          <div
            class="vac-message-card"
            :class="{
              'vac-message-highlight': isMessageHover,
              'vac-message-current': message.fromMe,
              'vac-message-deleted': message.deleted,
            }"
            @mouseover="onHoverMessage"
            @mouseleave="onLeaveMessage"
          >
            <div
              v-if="isGroup && !message.fromMe && (messageOffset || !sameDay)"
              class="vac-text-username"
              :class="{
                'vac-username-reply': !message.deleted && message.replyMessage,
              }"
            >
            <v-hover v-slot:default="{ hover }">
              <span :class="hover ? 'text-decoration-underline' : ''" :style="hover ? 'cursor: pointer' : ''" @click.prevent="goToRoomHeader(message.chatId, message.sender_id)">
                {{ getContactsName(message.sender_id, message.sender_name) }}
              </span>
            </v-hover>
                          <!-- <span> {{ name }} </span> -->
            </div>

            <message-reply
              v-if="!message.deleted && message.replyMessage"
              :message="message"
              :room-users="roomUsers"
              :all-contacts="allContacts"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              @click.native="scrollToMessage"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </message-reply>

            <div v-if="message.deleted">
              <slot name="deleted-icon">
                <svg-icon
                  name="deleted"
                  class="vac-icon-deleted"
                />
              </slot>
              <span>{{ textMessages.MESSAGE_DELETED }}</span>
            </div>

            <format-message
              v-else-if="(!message.files || !message.files.length) && message.data.type != 'location'"
              :content="message.content"
              :all-contacts="allContacts"
              :users="roomUsers"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              @open-user-tag="openUserTag"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </format-message>

            <message-files
              v-else-if="(!isAudio || message.files.length > 1) && message.data.type != 'location'"
              :current-user-id="currentUserId"
              :message="message"
              :room-users="roomUsers"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :all-contacts="allContacts"
              @open-file="openFile"
              @open-user-tag="openUserTag"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </message-files>

            <message-location
              v-else-if="message.data.type == 'location'"
              :current-user-id="currentUserId"
              :message="message"
              :room-users="roomUsers"
              :all-contacts="allContacts"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              @open-file="openFile"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </message-location>

            <template v-else>
              <audio-player
                :message-id="message._id"
                :src="message.files[0].url"
                @update-progress-time="progressTime = $event"
                @hover-audio-progress="hoverAudioProgress = $event"
              >
                <template
                  v-for="(i, name) in $scopedSlots"
                  #[name]="data"
                >
                  <slot
                    :name="name"
                    v-bind="data"
                  />
                </template>
              </audio-player>

              <div
                v-if="!message.deleted"
                class="vac-progress-time"
              >
                {{ progressTime }}
              </div>
            </template>

            <div
              v-if="
                message.buttonOptions &&
                  (message.buttonOptions.header || message.buttonOptions.content || message.buttonOptions.footer)
              "
            >
              <div
                v-if="message.buttonOptions.header"
                class="font-weight-bold mb-1"
              >
                <format-message
                  :content="message.buttonOptions.header"
                  :users="roomUsers"
                  :all-contacts="allContacts"
                  :text-formatting="textFormatting"
                  :link-options="linkOptions"
                  @open-user-tag="openUserTag"
                >
                  <template
                    v-for="(i, name) in $scopedSlots"
                    #[name]="data"
                  >
                    <slot
                      :name="name"
                      v-bind="data"
                    />
                  </template>
                </format-message>
              </div>
              <div
                v-if="message.buttonOptions.content"
                class="mb-1"
              >
                <format-message
                  :content="message.buttonOptions.content"
                  :users="roomUsers"
                  :all-contacts="allContacts"
                  :text-formatting="textFormatting"
                  :link-options="linkOptions"
                  @open-user-tag="openUserTag"
                >
                  <template
                    v-for="(i, name) in $scopedSlots"
                    #[name]="data"
                  >
                    <slot
                      :name="name"
                      v-bind="data"
                    />
                  </template>
                </format-message>
              </div>
              <div
                v-if="message.buttonOptions.footer"
                class="text-caption mb-1"
              >
                <format-message
                  :content="message.buttonOptions.footer"
                  :all-contacts="allContacts"
                  :users="roomUsers"
                  :text-formatting="textFormatting"
                  :link-options="linkOptions"
                  @open-user-tag="openUserTag"
                >
                  <template
                    v-for="(i, name) in $scopedSlots"
                    #[name]="data"
                  >
                    <slot
                      :name="name"
                      v-bind="data"
                    />
                  </template>
                </format-message>
              </div>
              <v-divider class="mb-1"></v-divider>
            </div>

            <div v-if="message.isButtons && message.isButtons.length > 0">
              <v-col
                v-for="button in message.isButtons"
                :key="button.index"
                cols="12"
                class="py-1"
              >
                <template v-if="button.urlButton">
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="openUrlButton(button.urlButton.url)"
                  >
                    <v-icon left>
                      {{ icons.mdiLink }}
                    </v-icon>
                    {{ button.urlButton.displayText }}
                  </v-btn>
                </template>
                <template v-else-if="button.quickReplyButton">
                  <v-btn
                    color="primary"
                    outlined
                    small
                    class="no-text-transform"
                  >
                    <span class="long-reply">
                      <v-icon left>
                        {{ icons.mdiReply }}
                      </v-icon>
                      {{ button.quickReplyButton.displayText }}
                    </span>
                  </v-btn>
                </template>
                <template v-else-if="button.callButton">
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="openCallButton(button.callButton.phoneNumber)"
                  >
                    <v-icon left>
                      {{ icons.mdiPhone }}
                    </v-icon>
                    {{ button.callButton.displayText }}
                  </v-btn>
                </template>
              </v-col>
            </div>

            <div class="vac-text-timestamp">
              <div
                v-if="message.edited && !message.deleted"
                class="vac-icon-edited"
              >
                <slot name="pencil-icon">
                  <svg-icon name="pencil" />
                </slot>
              </div>
              <span>
                <template v-if="message.labels && message.labels.length > 0">
                  <v-tooltip
                    v-for="label in message.labels"
                    :key="label.id"
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">

                      <v-icon
                        fab
                        size="15px"
                        v-bind="attrs"
                        :color="label.label.color"
                        @click="goToLabel()"
                        v-on="on"
                      >
                        {{ icons.mdiLabel }}
                      </v-icon>
                    </template>
                    <span>{{ label.labelName }}</span>
                  </v-tooltip>
                  <!-- <v-icon
                    v-for="label in message.labels"
                    :key="label.id"
                    :color="label.label.color"
                  >{{
                    icons.mdiLabel
                  }}</v-icon> -->
                </template>
                {{ message.timestamp }}
              </span>
              <span> {{ message.fromMe ? message.data.hasOwnProperty("operator") ? message.data.operator : "send via whatsapp" : "" }} </span>
              <span v-if="isCheckmarkVisible">
                <slot
                  name="checkmark-icon"
                  v-bind="{ message }"
                >
                  <v-icon
                    v-if="message.seen"
                    class="vac-icon-check"
                    small
                    color="info"
                  >
                    {{ icons.mdiCheckAll }}
                  </v-icon>
                  <v-icon
                    v-else-if="message.distributed"
                    class="vac-icon-check"
                    small
                  >
                    {{ icons.mdiCheckAll }}
                  </v-icon>
                  <v-icon
                    v-else-if="message.send"
                    class="vac-icon-check"
                    small
                  >
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <v-icon
                    v-else-if="message.saved"
                    class="vac-icon-check"
                    small
                  >
                    {{ icons.mdiClockOutline }}
                  </v-icon>
                </slot>
              </span>
            </div>
            <!-- <pre>{{message}}</pre> -->
            <message-actions
              :current-user-id="currentUserId"
              :message="message"
              :message-actions="messageActions"
              :room-footer-ref="roomFooterRef"
              :show-reaction-emojis="showReactionEmojis"
              :hide-options="hideOptions"
              :message-hover="messageHover"
              :hover-message-id="hoverMessageId"
              :hover-audio-progress="hoverAudioProgress"
              @hide-options="$emit('hide-options', false)"
              @update-message-hover="messageHover = $event"
              @update-options-opened="optionsOpened = $event"
              @update-emoji-opened="emojiOpened = $event"
              @message-action-handler="messageActionHandler"
              @send-message-reaction="sendMessageReaction"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </message-actions>
          </div>

          <message-reactions
            :current-user-id="currentUserId"
            :message="message"
            @send-message-reaction="sendMessageReaction"
          />
        </div>
        <!-- <div
          v-if="message.avatar && message.senderId === currentUserId"
          class="vac-avatar vac-avatar-current"
          :style="{ 'background-image': `url('${message.avatar}')` }"
        /> -->
        <!-- <div
          v-if="hasCurrentUserAvatar && !message.avatar"
          class="vac-avatar-current-offset"
        /> -->
        <div
          v-if="index === 0"
          id="searched-message-container"
        ></div>
        <div
          v-if="index == messages.length - 1"
          id="last-message-container"
        ></div>
      </slot>
    </div>
  </div>
</template>

<script>
import { mdiCheck, mdiCheckAll, mdiClockOutline, mdiLabel, mdiLink, mdiPhone, mdiReply } from '@mdi/js'
import FormatMessage from '../../components/FormatMessage/FormatMessage'
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import AudioPlayer from './AudioPlayer/AudioPlayer'
import MessageActions from './MessageActions/MessageActions'
import MessageFiles from './MessageFiles/MessageFiles'
import MessageLocation from './MessageLocation/MessageLocation'
import MessageReactions from './MessageReactions/MessageReactions'
import MessageReply from './MessageReply/MessageReply'

const { messagesValidation } = require('../../utils/data-validation')
const { isAudioFile } = require('../../utils/media-file')

export default {
  name: 'MessageContainer',

  setup() {
    return {
      icons: {
        mdiLabel,
        mdiCheck,
        mdiCheckAll,
        mdiClockOutline,
        mdiLink,
        mdiReply,
        mdiPhone,
      },
    }
  },

  components: {
    SvgIcon,
    FormatMessage,
    AudioPlayer,
    MessageReply,
    MessageFiles,
    MessageLocation,
    MessageActions,
    MessageReactions,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    index: { type: Number, required: true },
    message: { type: Object, required: true },
    isGroup: { type: Boolean, required: true },
    messages: { type: Array, required: true },
    editedMessage: { type: Object, required: true },
    roomUsers: { type: Array, default: () => [] },
    messageActions: { type: Array, required: true },
    roomFooterRef: { type: HTMLDivElement, default: null },
    newMessages: { type: Array, default: () => [] },
    showReactionEmojis: { type: Boolean, required: true },
    showNewMessagesDivider: { type: Boolean, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    hideOptions: { type: Boolean, required: true },
    showCheckbox: { type: Boolean, default: false },
    allContacts: { type: Array, default: () => [] },
    allContactsFirestore: { type: Array, default: () => [] },
  },

  emits: [
    'hide-options',
    'message-added',
    'open-file',
    'open-user-tag',
    'message-action-handler',
    'send-message-reaction',
  ],

  data() {
    return {
      hoverMessageId: null,
      messageHover: false,
      optionsOpened: false,
      emojiOpened: false,
      newMessage: {},
      progressTime: '- : -',
      hoverAudioProgress: false,
      name: '',
      allUnamed: [],
      countRun: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    showDate() {
      if (this.index == 0) {
        return true
      }

      return this.index > 0 && this.message.date !== this.messages[this.index - 1].date
    },
    sameDay() {
      let sameDay = true
      if (
        this.index > 0 &&
        this.message &&
        this.message.date &&
        this.messages[this.index - 1] &&
        this.messages[this.index - 1].date &&
        this.message.senderId === this.messages[this.index - 1].senderId
      ) {
        sameDay = this.$moment(this.messages[this.index - 1].date, 'DD MMM YYYY').isSame(
          this.$moment(this.message.date, 'DD MMM YYYY'),
          'day',
        )
      }

      return sameDay
    },
    messageOffset() {
      return this.index > 0 && this.message.senderId !== this.messages[this.index - 1].senderId
    },
    isMessageHover() {
      return this.editedMessage._id === this.message._id || this.hoverMessageId === this.message._id
    },
    isAudio() {
      return this.message.files?.some(file => isAudioFile(file))
    },
    isCheckmarkVisible() {
      return (
        this.message.fromMe &&
        !this.message.deleted &&
        (this.message.saved || this.message.send || this.message.distributed || this.message.seen)
      )
    },
    hasCurrentUserAvatar() {
      return this.messages.some(message => message.senderId === this.currentUserId && message.avatar)
    },
    hasSenderUserAvatar() {
      return this.messages.some(message => message.senderId !== this.currentUserId && message.avatar)
    },
    messagesFilter() {
      return this.$store.getters['inbox/getMessages']
    },
  },

  watch: {
    newMessages: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val.length || !this.showNewMessagesDivider) {
          return (this.newMessage = {})
        }

        this.newMessage = val.reduce((res, obj) => (obj.index < res.index ? obj : res))
      },
    },
    message(data) {
      /* const filter = this.messagesFilter
      const contact = filter.find(d => d._id === data.participant.split('@')[0])
      // //console.log('varnew', this.message.participant.split('@')[0], data, contact?.username)
      //console.log('varnew', this.message.participant, data, data.username)

      if (contact?.username) {
        this.name = contact?.username
      } else {
        this.name = ''
      } */
    },
  },
  destroyed() {},
  mounted() {
    messagesValidation(this.message)

    // //console.log(this.message, `INI TEXT MSG`)
    this.$emit('message-added', {
      message: this.message,
      index: this.index,
      ref: this.$refs.message,
    })
  },

  methods: {
    goToLabel() {
      this.$router.push({ name: 'label-list' })
    },
    goToRoomHeader(chatId, senderId) {
      let chat_id = chatId.split('-')[0]
      let payload = `${chat_id}-${senderId}`
      this.$store.commit('global/setChatHeaderPayload', payload)
    },
    getUserSender(msg) {
      //  const res = await this.$store.dispatch('rooms/messageSendBy', {
      //   subId: this.user.sub_id,
      //   msgId: msg,
      // })
      // //console.log(res)

      // if(res.data){
      //   return `res.data.data.operator`
      // }else{
      return 'send via whatsapp'

      // }
    },
    getContactsName(phoneNumber, usernameSender) {
      // //console.log(phoneNumber, usernameSender, this.message, `GET CONTACT NAME`)

      const foundContact = this.roomUsers.find(x => x._id === phoneNumber)
      const foundContactList = this.allContacts.find(x => x.phone_number === phoneNumber)

      // if (foundContact && foundContact.bailey_name) {
      //   return foundContact.bailey_name
      // }

      // if (foundContact && foundContact.notify_name) {
      //   return foundContact.notify_name
      // }
      const userData = this.allContactData.find(
        contact => contact.phone_number == phoneNumber && contact.notify_name !== phoneNumber,
      )

      // if (phoneNumber == 6282161616156) {
      //   //console.log(userData, `INI USER DATA`)
      // }

      if (foundContactList) {
        if (foundContactList.name.replace(/[+ -]/g, '') !== phoneNumber) {
          return foundContactList.name
        }
      }

      if (foundContact) {
        if (foundContact.username.replace(/[+ -]/g, '') !== phoneNumber) {
          return foundContact.username
        }
      }

      if (userData) {
        if (userData.bailey_name) {
          return userData.bailey_name
        }

        if (userData.notify_name) {
          return userData.notify_name
        }

        if (userData.contact_list_name) {
          return userData.contact_list_name
        }

        // if (this.couchAllContact) {
        //   const foundContact = this.couchAllContact.find(x => x.phone_number === phoneNumber)

        //   // //console.log(foundContact, `INI KONTAK COUCH`)

        //   if (foundContact) {
        //     return foundContact.name
        //   }
        // }

        // if (userData.data.username) {
        //   return userData.data.username
        // }
      }

      // if (phoneNumber == 6282161616156) {
      //   //console.log(foundContact, foundContactList, `INI KONTAK`)
      // }

      // //console.log(usernameSender, `INI USERNAME SENDER`)

      return this.formatPhoneNumber(phoneNumber)

      // if (foundContact && !foundContact.notify_name && !foundContact.bailey_name) {
      //   if (foundContactList) {
      //     return foundContactList.name
      //   }
      //   if (foundContact) {
      //     const checkker = foundContact.username.indexOf('+')
      //     const checkker2 = foundContact.username.indexOf('-')
      //     if (checkker === -1 && checkker2 === -1) {
      //       // //console.log()
      //       // if (phoneNumber == '6287804075601') {
      //       //   //console.log(checkker, '11111122222333333', usernameSender)
      //       //   //console.log(checkker2, '11111122222333333', usernameSender)
      //       // }
      //       return foundContact.username
      //     }
      //   }

      //   if (this.allUnamed.indexOf(phoneNumber) === -1) {
      //     this.allUnamed.push(phoneNumber)
      //   }

      //   if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
      //     const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
      //     const splitOne = phoneNumber.substr(2, 3)
      //     const splitTwo = phoneNumber.substr(5, 4)
      //     const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
      //     phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      //   }

      //   return usernameSender
      // }
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      return phoneNumber
    },
    onHoverMessage() {
      this.messageHover = true
      if (this.canEditMessage()) this.hoverMessageId = this.message._id
    },
    canEditMessage() {
      return !this.message.deleted
    },
    onLeaveMessage() {
      if (!this.optionsOpened && !this.emojiOpened) this.messageHover = false
      this.hoverMessageId = null
    },
    openFile(file) {
      this.$emit('open-file', { message: this.message, file })
    },
    openUserTag(user) {
      this.$emit('open-user-tag', user)
    },
    messageActionHandler(action) {
      this.messageHover = false
      this.hoverMessageId = null

      setTimeout(() => {
        this.$emit('message-action-handler', { action, message: this.message })
      }, 300)
    },
    sendMessageReaction({ emoji, reaction }) {
      this.$emit('send-message-reaction', {
        messageId: this.message._id,
        reaction: emoji,
        remove: reaction && reaction.indexOf(this.currentUserId) !== -1,
      })
      this.messageHover = false
    },
    openUrlButton(url) {
      window.open(url, '_blank')
    },
    openCallButton(phoneNumber) {
      window.open(`tel:${phoneNumber}`, '_blank')
    },
    scrollToMessage() {
      let id = ''

      if (this.message.data && this.message.data.quotedStanzaID) {
        id = this.message.data.quotedStanzaID
      }

      //console.log(this.message, `INI MSG ID`)

      if (id) {
        setTimeout(() => {
          const element = document.getElementById(id)

          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            })
          }
        }, 50)
      }
    },
    getHeaderText(date) {
      if (date.couch_timestamp) {
        if (this.$moment(+date.couch_timestamp).isSame(this.$moment(), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment(+date.couch_timestamp).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment(+date.couch_timestamp).format('DD/MM/YY')
      }

      if (date.reply_time) {
        if (this.$moment.unix(date.reply_time / 1000).isSame(new Date(), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment.unix(date.reply_time / 1000).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment.unix(date.reply_time / 1000).format('DD/MM/YY')
      }

      if (date.seconds) {
        if (this.$moment.unix(date.seconds).isSame(new Date(), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment.unix(date.seconds).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment.unix(date.seconds).format('DD/MM/YY')
      }

      return ''
    },
  },
}
</script>

<style scoped>
.long-reply {
  display: inline-block;
  max-width: 395px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1200px) {
  .long-reply {
    display: inline-block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1100px) {
  .long-reply {
    display: inline-block;
    max-width: 255px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1000px) {
  .long-reply {
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 912px) {
  .long-reply {
    display: inline-block;
    max-width: 345px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 540px) {
  .long-reply {
    display: inline-block;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 510px) {
  .long-reply {
    display: inline-block;
    max-width: 255px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 393px) {
  .long-reply {
    display: inline-block;
    max-width: 232px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 378px) {
  .long-reply {
    display: inline-block;
    max-width: 235px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 283px) {
  .long-reply {
    display: inline-block;
    max-width: 155px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
</style>
