<template>
  <div
    v-show="(isMobile && !showRoomsList) || !isMobile || singleRoom"
    class="vac-col-messages"
    @touchstart="touchStart"
  >
    <eshop-list
      ref="eshop"
      @closeModal="e => closeModalCheck(e)"
    />
    <meeting-list
      ref="meeting"
      @closeModal="e => closeModalMeeting(e)"
    />
    <slot
      v-if="showNoRoom"
      name="no-room-selected"
    >
      <div class="vac-container-center vac-room-empty">
        <div>{{ textMessages.ROOM_EMPTY + 'hello' }}</div>
      </div>
    </slot>

    <room-header
      v-if="!showNoRoom"
      id="fixing-room-2"
      :current-user-id="currentUserId"
      :is-mobile="isMobile"
      :menu-actions="menuActions"
      :room="room"
      :room-info-enabled="roomInfoEnabled"
      :filtered-quick-replies="filteredQuickReplies.length ? true : false"
      :show-rooms-list="showRoomsList"
      :single-room="singleRoom"
      :text-messages="textMessages"
      :assign-to-user-list="assignToUserList"
      :replies-history="repliesHistory"
      :all-contacts="allContacts"
      :all-contacts-firestore="allContactsFirestore"
      @toggle-rooms-list="$emit('toggle-rooms-list')"
      @room-info="$emit('room-info')"
      @change-pinned="$emit('change-pinned')"
      @change-operator="$emit('change-operator', $event)"
      @menu-action-handler="$emit('menu-action-handler', $event)"
      @openQuickReply="e => openQuickReplyCheck(e)"
      @update-room="$emit('update-room', $event)"
    >
      <template
        v-for="(i, name) in $scopedSlots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </room-header>
    <!-- mobile -->
    <!-- desktop-->
    <div
      v-if="!showNoRoom"
      :id="indexPageRoom ? 'fixing-room' : ''"
      ref="scrollContainer"
      class="vac-container-scroll"
      :style="{ display: messages.length === 0 ? 'none' : 'block' }"
      @scroll="onContainerScroll"
    >
      <loader :show="loadingMessages" />
      <div class="vac-messages-container">
        <div :class="{ 'vac-messages-hidden': loadingMessages }">
          <transition name="vac-fade-message">
            <div>
              <div
                v-if="showNoMessages"
                class="vac-text-started"
              >
                <slot name="messages-empty">
                  {{ textMessages.MESSAGES_EMPTY }}
                </slot>
              </div>
              <div
                v-if="showMessagesStarted && !hideMessagesStarted"
                class="vac-text-started"
              >
                {{ textMessages.CONVERSATION_ON_WHATSAPP }}
              </div>
              <div
                v-else
                class="mb-2"
              ></div>
            </div>
          </transition>
          <transition name="vac-fade-message">
            <infinite-loading
              v-if="messages.length"
              :class="{ 'vac-infinite-loading': !messagesLoaded }"
              :distance="40"
              direction="top"
              force-use-infinite-wrapper=".vac-container-scroll"
              spinner="spiral"
              web-component-name="vue-advanced-chat"
              @infinite="loadMoreMessages"
            >
              <template #spinner>
                <loader
                  :infinite="true"
                  :show="true"
                />
              </template>
              <template #no-results>
                <div />
              </template>
              <template #no-more>
                <div />
              </template>
            </infinite-loading>
          </transition>
          <!-- <transition-group :key="roomId" name="vac-fade-message" tag="span"> -->
          <div
            v-for="(m, i) in messages"
            :key="m.indexId || m._id"
          >
            <message
              :current-user-id="currentUserId"
              :edited-message="editedMessage"
              :hide-options="hideOptions"
              :index="i"
              :link-options="linkOptions"
              :message="m"
              :message-actions="messageActions"
              :messages="messages"
              :new-messages="newMessages"
              :room-footer-ref="$refs.roomFooter"
              :room-users="room.users"
              :is-group="room.phone_number_show === 'Group' ? true : false"
              :show-new-messages-divider="showNewMessagesDivider"
              :show-reaction-emojis="showReactionEmojis"
              :text-formatting="textFormatting"
              :text-messages="textMessages"
              :show-checkbox="showCheckbox"
              :all-contacts="allContacts"
              :all-contacts-firestore="allContactsFirestore"
              @message-added="onMessageAdded"
              @message-action-handler="messageActionHandler"
              @open-file="openFile"
              @open-user-tag="openUserTag"
              @send-message-reaction="sendMessageReaction"
              @hide-options="hideOptions = $event"
            >
              <template
                v-for="(idx, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </message>
          </div>
          <!-- </transition-group> -->
        </div>
      </div>
    </div>

    <div v-if="!loadingMessages">
      <transition name="vac-bounce">
        <div
          v-if="scrollIcon"
          id="vac-icon-scroll"
          class="vac-icon-scroll"
          @click="scrollToBottom"
        >
          <transition name="vac-bounce">
            <div
              v-if="scrollMessagesCount"
              class="vac-badge-counter vac-messages-count"
            >
              {{ scrollMessagesCount }}
            </div>
          </transition>
          <slot name="scroll-icon">
            <svg-icon
              name="dropdown"
              param="scroll"
            />
          </slot>
        </div>
      </transition>
    </div>

    <!-- mobile -->
    <div
      v-show="Object.keys(room).length && showFooter && showCheckbox"
      id="fixing-footer-2"
      ref="roomFooter"
      :class="{
        'vac-app-box-shadow': shadowFooter,
      }"
      class="vac-room-footer"
    >
      <div class="vac-box-footer">
        <div class="vac-icon-textarea-left">
          <div class="vac-svg-button">
            <v-icon
              color="primary"
              @click="selectMessagesActionHandler('close')"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>
        </div>
        <div class="vac-checkbox-footer">
          {{ selectedMessages.length }} selected
        </div>
        <div class="vac-icon-textarea">
          <div
            class="vac-svg-button"
            :class="{ 'vac-disabled': selectedMessages.length == 0 }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="selectedMessages.length > 0 ? 'primary' : 'secondary'"
                  v-on="on"
                  @click="selectMessagesActionHandler('labelMessages')"
                >
                  {{ icons.mdiLabel }}
                </v-icon>
              </template>
              <span>{{ $t('inbox.labelMessages') }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="vac-icon-textarea">
          <div
            class="vac-svg-button"
            :class="{ 'vac-disabled': selectedMessages.length == 0 }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="selectedMessages.length > 0 ? 'primary' : 'secondary'"
                  v-on="on"
                  @click="selectMessagesActionHandler('exportMessages')"
                >
                  {{ icons.mdiExport }}
                </v-icon>
              </template>
              <span>{{ $t('inbox.exportMessages') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- desktop -->
    <div
      v-show="Object.keys(room).length && showFooter && !showCheckbox"
      id="fixing-footer"
      ref="roomFooter"
      :class="{
        'vac-app-box-shadow': shadowFooter,
      }"
      class="vac-room-footer"
    >
      <div
        v-show="
          user.role !== 'Admin' &&
            user.chat_mode &&
            user.chat_mode !== 'Full Access' &&
            user.chat_mode !== 'View Only' &&
            !room.assign_to
        "
      >
        <div class="d-flex mx-auto my-auto">
          <v-btn
            color="primary"
            large
            class="mx-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : ''"
            @click="$emit('change-operator', user)"
          >
            <v-icon class="mr-2">
              {{ icons.mdiMessageTextOutline }}
            </v-icon>
            start chat
          </v-btn>
        </div>
      </div>
      <div
        v-show="
          user.role === 'Admin' ||
            !user.chat_mode ||
            user.chat_mode === 'Full Access' ||
            ((user.chat_mode === 'Write to assigned only' || user.chat_mode === 'Read and Write to assigned only') &&
              room.assign_to &&
              room.assign_to.email === user.email)
        "
      >
        <room-emojis
          :active-up-or-down="activeUpOrDownEmojis"
          :filtered-emojis="filteredEmojis"
          :select-item="selectEmojiItem"
          @select-emoji="selectEmoji($event)"
          @activate-item="activeUpOrDownEmojis = 0"
        />

        <room-users-tag
          :active-up-or-down="activeUpOrDownUsersTag"
          :filtered-users-tag="filteredUsersTag"
          :select-item="selectUsersTagItem"
          @select-user-tag="selectUserTag($event)"
          @activate-item="activeUpOrDownUsersTag = 0"
        />

        <room-templates-text
          :active-up-or-down="activeUpOrDownTemplatesText"
          :filtered-templates-text="filteredTemplatesText"
          :select-item="selectTemplatesTextItem"
          @select-template-text="selectQuickReply($event)"
          @activate-item="activeUpOrDownTemplatesText = 0"
        />

        <room-quick-replies
          :active-up-or-down="activeUpOrDownQuickReplies"
          :filtered-quick-replies="filteredQuickReplies"
          :select-item="selectQuickRepliesItem"
          :room="room"
          @select-quick-reply="selectQuickReply($event)"
          @activate-item="activeUpOrDownQuickReplies = 0"
          @openQuickReply="e => openQuickReplyCheck(e)"
        />

        <room-message-reply
          v-if="messageReply"
          :link-options="linkOptions"
          :message-reply="messageReply"
          :room="room"
          :room-users="room.users"
          :all-contacts="allContacts"
          :text-formatting="textFormatting"
          @reset-message="resetMessage"
        >
          <template
            v-for="(i, name) in $scopedSlots"
            #[name]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </room-message-reply>

        <room-files
          :files="files"
          @remove-file="removeFile"
          @reset-message="resetMessage"
        >
          <template
            v-for="(i, name) in $scopedSlots"
            #[name]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </room-files>
        <!-- footer chat-->
        <!-- desktop-->
        <div
          class="vac-box-footer"
          :class="{ 'vac-box-footer-border': !files.length }"
        >
          <!-- <div
          v-if="showAudio && !files.length"
          class="vac-icon-textarea-left"
        >
          <template v-if="isRecording">
            <div
              class="vac-svg-button vac-icon-audio-stop"
              @click="stopRecorder"
            >
              <slot name="audio-stop-icon">
                <svg-icon name="close-outline" />
              </slot>
            </div>

            <div class="vac-dot-audio-record" />

            <div class="vac-dot-audio-record-time">
              {{ recordedTime }}
            </div>

            <div
              class="vac-svg-button vac-icon-audio-confirm"
              @click="toggleRecorder(false)"
            >
              <slot name="audio-stop-icon">
                <svg-icon name="checkmark" />
              </slot>
            </div>
          </template>

          <template v-else>
            <div
              :class="{ 'vac-disabled': !isChannelOnline }"
              class="vac-svg-button"
              @click="toggleRecorder(true)"
            >
              <slot name="microphone-icon">
                <v-icon
                  v-if="isChannelOnline"
                  color="primary"
                >
                  {{ icons.mdiMicrophone }}
                </v-icon>
                <svg-icon
                  v-else
                  class="vac-icon-microphone-disabled"
                  name="microphone"
                />
              </slot>
            </div>
          </template>
        </div> -->

          <textarea
            ref="roomTextarea"
            :class="{
              'vac-textarea-outline': editedMessage._id,
            }"
            :disabled="!isChannelOnline || loadSendMessage"
            :placeholder="textMessages.TYPE_MESSAGE"
            :style="{
              'min-height': `20px`,
              'padding-left': `12px`,
            }"
            class="vac-textarea"
            @input="onChangeInput"
            @paste="onPasteImage"
            @keydown.esc="escapeTextarea"
            @keydown.enter.exact.prevent="selectItem"
            @keydown.tab.exact.prevent=""
            @keydown.tab="selectItem"
            @keydown.up.exact.prevent=""
            @keydown.up="updateActiveUpOrDown(-1)"
            @keydown.down.exact.prevent=""
            @keydown.down="updateActiveUpOrDown(1)"
          />
          <!-- footer message, input, attachment -->
          <div class="vac-icon-textarea">
            <v-progress-circular
              v-if="loadSendMessage"
              indeterminate
              color="primary"
              size="22"
              class="mr-4"
            ></v-progress-circular>
            <div
              v-if="editedMessage._id"
              class="vac-svg-button"
              @click="resetMessage"
            >
              <slot name="edit-close-icon">
                <svg-icon name="close-outline" />
              </slot>
            </div>

            <emoji-picker-container
              v-if="showEmojis"
              v-click-outside="() => (emojiOpened = false)"
              :emoji-opened="emojiOpened"
              :is-channel-online="isChannelOnline"
              :position-top="true"
              @add-emoji="addEmoji"
              @open-emoji="emojiOpened = $event"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </emoji-picker-container>

            <!-- <div
            v-if="showFiles"
            :class="{ 'vac-disabled': !isChannelOnline }"
            class="vac-svg-button"
            @click="launchFilePicker"
          >
            <slot name="paperclip-icon">
              <v-icon color="primary">
                {{ icons.mdiAttachment }}
              </v-icon>
            </slot>
          </div> -->

            <v-speed-dial
              v-model="fabAttachments"
              direction="top"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator>
                <div
                  v-if="showFiles && !room.msg_id"
                  :class="{ 'vac-disabled': !isChannelOnline }"
                  class="vac-svg-button"
                >
                  <slot name="paperclip-icon">
                    <v-icon
                      v-if="fabAttachments"
                      color="primary"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                    <v-icon
                      v-else
                      color="primary"
                    >
                      {{ icons.mdiAttachment }}
                    </v-icon>
                  </slot>
                </div>
              </template>
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    fab
                    v-bind="attrs"
                    v-on="on"
                    @click="launchFilePicker('media')"
                  >
                    <v-icon large>
                      {{ icons.mdiImage }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Photos & Videos</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    fab
                    v-bind="attrs"
                    v-on="on"
                    @click="launchFilePicker('doc')"
                  >
                    <v-icon large>
                      {{ icons.mdiFileDocument }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Documents</span>
              </v-tooltip>
              <v-tooltip
                v-if="user.package === 'business_plus'"
                left
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    fab
                    v-bind="attrs"
                    v-on="on"
                    @click="openEshopList()"
                  >
                    <v-icon large>
                      {{ icons.mdiShoppingOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>E-Shop</span>
              </v-tooltip>
              <!-- <v-tooltip
              v-if="user.package === 'business_plus' || user.package === 'business'"
              left
            >
              <template #activator="{ on, attrs }">
                <v-btn

                  color="primary"
                  small
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="openMeeting()"
                >
                  <v-icon large>
                    {{ icons.mdiAccountGroup }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Meeting</span>
            </v-tooltip> -->
            </v-speed-dial>

            <div
              v-if="textareaActionEnabled"
              class="vac-svg-button"
              @click="textareaActionHandler"
            >
              <slot name="custom-action-icon">
                <svg-icon name="deleted" />
              </slot>
            </div>

            <input
              v-if="showFiles"
              ref="file"
              :accept="acceptedFiles"
              :disabled="!isChannelOnline && loadSendMessage"
              multiple
              style="display: none"
              type="file"
              @change="onFileChange($event.target.files)"
            />

            <div
              v-if="!room.msg_id"
              :class="{ 'vac-disabled': !isChannelOnline || loadSendMessage }"
              class="vac-svg-button"
              @click="filteredQuickReplies.length ? resetFooterList() : updateFooterList('quickReplies=')"
            >
              <v-icon color="primary">
                {{ icons.mdiMessageReplyText }}
              </v-icon>
            </div>

            <div
              v-if="showSendIcon"
              :class="{ 'vac-send-disabled': isMessageEmpty || isFileLoading || loadSendMessage }"
              class="vac-svg-button"
              @click="sendMessage(null)"
            >
              <slot name="send-icon">
                <v-icon :color="!isMessageEmpty && !loadSendMessage ? 'primary' : ''">
                  {{ icons.mdiSend }}
                </v-icon>
              </slot>
            </div>
          </div>
        </div>
        <!-- mobile-->
      </div>
      <div v-show="user.chat_mode && user.chat_mode === 'View Only'">
        <div class="d-flex mx-auto my-auto">
          <span
            class="mx-auto warning--text"
            :class="$vuetify.breakpoint.mdAndUp ? 'mb-4' : 'mb-2'"
          >
            <v-icon color="warning">
              {{ icons.mdiLock }}
            </v-icon>
            <strong>You have view only enabled</strong>
          </span>
        </div>
      </div>
      <div
        v-show="
          user.chat_mode &&
            user.chat_mode === 'Write to assigned only' &&
            room.assign_to &&
            room.assign_to.email !== user.email
        "
      >
        <div class="d-flex mx-auto my-auto">
          <span
            class="mx-auto warning--text"
            :class="$vuetify.breakpoint.mdAndUp ? 'mb-4' : 'mb-2'"
          >
            <v-icon color="warning">
              {{ icons.mdiLock }}
            </v-icon>
            <strong> You have write to assigned only enabled</strong></span>
        </div>
      </div>
    </div>

    <v-dialog v-model="openDialogSurvey">
      <v-card>
        <v-card-title>
          <!-- <span class="text-h5 font-weight-bold">{{ currentSurvey ? currentSurvey.survey.title : '' }}</span> -->
        </v-card-title>
        <v-divider />
        <v-card-text>
          <survey
            :key="keySurvey"
            :survey="survey"
          ></survey>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eshopList from '@/components/dialog/eshop.vue'
import meetingList from '@/components/dialog/meeting.vue'
import {
  mdiAccountGroup,
  mdiAttachment,
  mdiChatOutline,
  mdiClose,
  mdiExport,
  mdiFileDocument,
  mdiImage,
  mdiLabel,
  mdiLock,
  mdiMessageReplyText,
  mdiMessageTextOutline,
  mdiMicrophone,
  mdiSend,
  mdiShoppingOutline,
} from '@mdi/js'
import { Database } from 'emoji-picker-element'
import vClickOutside from 'v-click-outside'
import InfiniteLoading from 'vue-infinite-loading'
import eFormMixin from '@/mixins/eFormMixin'
import * as SurveyVue from 'survey-vue'
import _uniqBy from 'lodash/uniqBy'
import { connectStorageEmulator } from '@firebase/storage'
import EmojiPickerContainer from '../../components/EmojiPickerContainer/EmojiPickerContainer'
import Loader from '../../components/Loader/Loader'
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import filteredItems from '../../utils/filter-items'
import Recorder from '../../utils/recorder'
import Message from '../Message/Message'
import RoomEmojis from './RoomEmojis/RoomEmojis'
import RoomFiles from './RoomFiles/RoomFiles'
import RoomHeader from './RoomHeader/RoomHeader'
import RoomMessageReply from './RoomMessageReply/RoomMessageReply'
import RoomQuickReplies from './RoomQuickReplies/RoomQuickReplies'
import RoomTemplatesText from './RoomTemplatesText/RoomTemplatesText'
import RoomUsersTag from './RoomUsersTag/RoomUsersTag'

const { Survey } = SurveyVue
Survey.cssType = 'modern'

const { detectMobile, iOSDevice } = require('../../utils/mobile-detection')

const debounce = (func, delay) => {
  let inDebounce

  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

export default {
  name: 'Room',

  components: {
    InfiniteLoading,
    Loader,
    SvgIcon,
    EmojiPickerContainer,
    RoomHeader,
    RoomFiles,
    RoomMessageReply,
    RoomUsersTag,
    RoomEmojis,
    RoomTemplatesText,
    RoomQuickReplies,
    Message,
    eshopList,
    meetingList,
    Survey,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [eFormMixin],
  setup() {
    return {
      icons: {
        mdiMessageReplyText,
        mdiSend,
        mdiMicrophone,
        mdiAttachment,
        mdiClose,
        mdiLabel,
        mdiExport,
        mdiImage,
        mdiFileDocument,
        mdiShoppingOutline,
        mdiAccountGroup,
        mdiLock,
        mdiChatOutline,
        mdiMessageTextOutline,
      },
    }
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    singleRoom: { type: Boolean, required: true },
    showRoomsList: { type: Boolean, required: true },
    isMobile: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    roomsOrigin: { type: Array, required: true },
    roomId: { type: [String, Number], required: true },
    loadFirstRoom: { type: Boolean, required: true },
    messages: { type: Array, required: true },
    roomMessage: { type: String, default: null },
    messagesLoaded: { type: Boolean, required: true },
    menuActions: { type: Array, required: true },
    messageActions: { type: Array, required: true },
    showSendIcon: { type: Boolean, required: true },
    showFiles: { type: Boolean, required: true },
    showAudio: { type: Boolean, required: true },
    audioBitRate: { type: Number, required: true },
    audioSampleRate: { type: Number, required: true },
    showEmojis: { type: Boolean, required: true },
    showReactionEmojis: { type: Boolean, required: true },
    showNewMessagesDivider: { type: Boolean, required: true },
    showFooter: { type: Boolean, required: true },

    // acceptedFiles: { type: String, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    loadingRooms: { type: Boolean, required: true },
    roomInfoEnabled: { type: Boolean, required: true },
    textareaActionEnabled: { type: Boolean, required: true },
    templatesText: { type: Array, default: null },
    quickReplies: { type: Array, default: null },
    statusInstances: { type: Array, default: [] },
    currentAction: { type: String, default: null },
    isUploadingAttachment: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: false },
    indexPageRoom: { type: Boolean, default: false },
    hideMessagesStarted: { type: Boolean, default: false },
    assignToUserList: { type: Array, default: () => [] },
    repliesHistory: { type: Boolean, default: false },
    loadSendMessage: { type: Boolean, default: false },
    allContacts: { type: Array, default: () => [] },
    allContactsFirestore: { type: Array, default: () => [] },
    roomsGrouped: { type: Array, default: () => [] },
    roomsMessages: { type: Array, default: () => [] },
  },

  emits: [
    'toggle-rooms-list',
    'room-info',
    'menu-action-handler',
    'edit-message',
    'send-message',
    'send-message-eshop',
    'send-message-meeting',
    'delete-message',
    'change-pinned',
    'change-operator',
    'message-action-handler',
    'fetch-messages',
    'send-message-reaction',
    'typing-message',
    'open-file',
    'open-user-tag',
    'textarea-action-handler',
    'select-messages-action-handler',
    'update-room',
  ],

  data() {
    return {
      message: '',
      editedMessage: {},
      messageReply: null,
      infiniteState: null,
      loadingMessages: false,
      loadingMoreMessages: false,
      files: [],
      fileDialog: false,
      emojiOpened: false,
      hideOptions: true,
      scrollIcon: false,
      scrollMessagesCount: 0,
      newMessages: [],
      keepKeyboardOpen: false,
      filteredEmojis: [],
      filteredUsersTag: [],
      selectedUsersTag: [],
      filteredTemplatesText: [],
      filteredQuickReplies: [],
      selectEmojiItem: null,
      selectUsersTagItem: null,
      selectTemplatesTextItem: null,
      selectQuickRepliesItem: null,
      activeUpOrDownEmojis: null,
      activeUpOrDownUsersTag: null,
      activeUpOrDownTemplatesText: null,
      activeUpOrDownQuickReplies: null,
      textareaCursorPosition: null,
      cursorRangePosition: null,
      emojisDB: new Database(),
      recorder: this.initRecorder(),
      isRecording: false,
      format: 'mp3',
      screen: {
        height: true,
      },
      showRoom: false,
      acceptedFiles: '*',
      fabAttachments: false,
      isProcessingOnClickOfSendingQuickReply: false,
      openDialogSurvey: false,
      keySurvey: 0,
      selectedSurvey: {},
      survey: {},
    }
  },

  computed: {
    room() {
      const room = this.rooms.find(room => room.roomId === this.roomId) || {}

      // if (
      //   room &&
      //   room.hasOwnProperty('assign_to') &&
      //   room.assign_to !== null &&
      //   this.$store.getters['auth/getUser'].role !== 'Admin'
      // ) {
      //   if (
      //     room.assign_to.hasOwnProperty('email') &&
      //     room.assign_to.email != this.$store.getters['auth/getUser'].email
      //   ) {
      //     room = {}
      //   }
      // }

      return room
    },
    showNoMessages() {
      return this.room.roomId && !this.messages.length && !this.loadingMessages && !this.loadingRooms
    },
    showNoRoom() {
      const noRoomSelected = (!this.rooms.length && !this.loadingRooms) || (!this.room.roomId && !this.loadFirstRoom)

      if (noRoomSelected) {
        this.loadingMessages = false /* eslint-disable-line vue/no-side-effects-in-computed-properties */
      }

      return noRoomSelected
    },
    showMessagesStarted() {
      return this.messages.length && this.messagesLoaded
    },
    isMessageEmpty() {
      if (!this.message) {
        this.message = ''
      }

      return !this.files.length && !this.message.trim()
    },
    isFileLoading() {
      return this.files.some(e => e.loading)
    },
    recordedTime() {
      return new Date(this.recorder.duration * 1000).toISOString().substr(14, 5)
    },
    shadowFooter() {
      return (
        !!this.filteredEmojis.length ||
        !!this.filteredUsersTag.length ||
        !!this.filteredTemplatesText.length ||
        !!this.filteredQuickReplies.length ||
        !!this.files.length ||
        !!this.messageReply
      )
    },
    isChannelOnline() {
      // const self = this

      // if (self.statusInstances && self.statusInstances.length > 0) {
      //   if (Object.keys(self.room).length) {
      //     const room = self.statusInstances.find(item => item._id == self.room.instance._id)

      //     if (room) {
      //       if (room.status != 1) {
      //         return false
      //       }
      //     }
      //   }
      // }

      return true
    },
    selectedMessages() {
      return this.messages.filter(message => message.selected === true)
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  watch: {
    message(val) {
      this.getTextareaRef().value = val
    },
    loadingMessages(val) {
      if (val) {
        this.infiniteState = null
      } else {
        if (this.infiniteState) this.infiniteState.loaded()
        this.focusTextarea(true)
      }
    },
    room: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.roomId && (!oldVal || newVal.roomId !== oldVal.roomId)) {
          this.onRoomChanged()
        }
      },
    },
    roomMessage: {
      immediate: true,
      handler(val) {
        if (val) this.message = this.roomMessage
      },
    },
    messages: {
      deep: true,
      handler(newVal, oldVal) {
        // //console.log(newVal, `INI NEWVAL`)
        if (this.currentAction == null && this.currentAction != 'labelMessage') {
          newVal.forEach((message, i) => {
            if (this.showNewMessagesDivider && !message.seen && message.senderId !== this.currentUserId) {
              this.newMessages.push({
                _id: message._id,
                index: i,
              })
            }
          })
          if (oldVal?.length === newVal?.length - 1) {
            this.newMessages = []
          }
          if (this.infiniteState) {
            this.infiniteState.loaded()
          }
          setTimeout(() => (this.loadingMoreMessages = false))
        }
      },
    },
    messagesLoaded(val) {
      if (val) this.loadingMessages = false
      if (this.infiniteState) this.infiniteState.complete()
    },
    loadSendMessage(val) {
      if (!val) {
        this.$nextTick(() => {
          this.focusTextarea()
        })
      }
    },
  },

  mounted() {
    // //console.log(this.singleRoom, this.showRoomList, `INI di room`)
    // //console.log(this.messages.length, `INI PANJANG MSG`)
    // style room
    const doc = document.getElementById('fixing-room')
    const doc2 = document.getElementById('fixing-footer')
    const doc3 = document.getElementById('fixing-room-2')

    /* this.$eventBus.$on('checkShowListContact', () => {
      //console.log(
        'checkShowListContact',
        doc.classList.contains('active'),
        doc2.classList.contains('active'),
        doc3.classList.contains('active'),
      )

      if (doc.classList.contains('active') === true) {
        doc.classList.remove('active')
        doc2.classList.remove('active')
        doc3.classList.remove('active')
      } else {
        doc.classList.add('active')
        doc2.classList.add('active')
        doc3.classList.add('active')
      }
      //console.log('checkHeader', doc)
    })

    this.$eventBus.$on('banner-alert', data => {
      //console.log('checkHeader-room', data)

      if (data === true) {
        doc.classList.add('active-banner')
        doc2.classList.add('active-banner')
        doc3.classList.add('active-banner')
      }
    })
    */
    /*
    this.$eventBus.$on('sidebar-right', () => {
      const doc = document.getElementById('fixing-room')
      const doc2 = document.getElementById('fixing-footer')
      const doc3 = document.getElementById('fixing-room-2')

      if (doc.classList.contains('active-sidebar') === true) {
        doc.classList.remove('active-sidebar')
        doc2.classList.remove('active-sidebar')
        doc3.classList.remove('active-sidebar')
      } else {
        doc.classList.add('active-sidebar')
        doc2.classList.add('active-sidebar')
        doc3.classList.add('active-sidebar')
      }
      //console.log('checkHeader', doc)
    })
*/

    this.newMessages = []
    const isMobile = detectMobile()

    this.getTextareaRef().addEventListener(
      'keyup',
      debounce(e => {
        if (e.key === 'Enter' && !e.shiftKey && !this.fileDialog) {
          if (isMobile) {
            this.message += '\n'
            setTimeout(() => this.onChangeInput())
          } else if (
            !this.filteredEmojis.length &&
            !this.filteredUsersTag.length &&
            !this.filteredTemplatesText.length &&
            !this.filteredQuickReplies.length
          ) {
            this.sendMessage(null)
          }
        }

        setTimeout(() => {
          this.updateFooterList('@')
          this.updateFooterList(':')
          this.updateFooterList('/')
        }, 60)
      }),
      50,
    )

    this.getTextareaRef().addEventListener('click', () => {
      if (isMobile) this.keepKeyboardOpen = true

      this.updateFooterList('@')
      this.updateFooterList(':')
      this.updateFooterList('/')
    })

    this.getTextareaRef().addEventListener('blur', () => {
      this.resetFooterList()
      if (isMobile) setTimeout(() => (this.keepKeyboardOpen = false))
    })
  },

  beforeDestroy() {
    this.stopRecorder()
  },

  methods: {
    assignOperator() {
      // //console.log('🚀 ~ file: Room.vue ~ line 1097 ~ assignOperator ~ this.user', this.user)
    },
    openQuickReplyCheck(header = null) {
      // //console.log(header)
      if (header) {
        if (this.filteredQuickReplies.length) {
          this.updateFooterList('quickReplies=')
        } else {
          this.resetFooterList()
        }
      } else {
        this.filteredQuickReplies.length ? this.resetFooterList() : this.updateFooterList('quickReplies=')
      }
    },
    async closeModalMeeting(e) {
      const text = `Hi ${this.getContactsNameRoom(this.room.phone_number, this.room.name)},

    Anda kami undang untuk mengikuti meeting ${e.title} pada
    Tanggal : ${e.date}
    Jam       : ${e.time}
    Link      : https://meet.jit.si/${e.meetingId}-${e.titleTrim}
      `
      this.$emit('send-message-meeting', {
        to: [this.room.phone_number],
        msg: text,
        attachment: null,
        meetingId: e.meetingId,
      })
      this.resetMessage(true)
    },
    async closeModalCheck(e) {
      if (e.image.url) {
        this.files.push(e.image)
      }

      this.$emit('send-message-eshop', {
        to: [this.room.phone_number],
        msg: e.text,
        attachment: this.files,
        instance_id: this.room.instance_id,
      })
      this.resetMessage(true)

      // this.sendMessage(obj)
    },
    async openMeeting() {
      const { meeting } = this.$refs
      meeting.dialog.meeting = true
    },
    openEshopList() {
      const refs = this.$refs.eshop
      refs.dialog.eshop = true
    },
    getContactsNameRoom(phoneNumber, roomName) {
      // Use WA name
      const findContact = this.$store.getters['global/getAllContactData'].find(
        contactData => contactData.phone_number === phoneNumber,
      )
      if (findContact) {
        return findContact.notify_name || findContact.bailey_name
      }

      if (this.allContactsFirestore) {
        const foundContact = this.allContactsFirestore.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }
      if (this.allContacts) {
        const foundContact = this.allContacts.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }

      return roomName
    },
    getTextareaRef() {
      return this.$refs.roomTextarea
    },
    touchStart(touchEvent) {
      if (this.singleRoom) return

      if (touchEvent.changedTouches.length === 1) {
        const posXStart = touchEvent.changedTouches[0].clientX
        const posYStart = touchEvent.changedTouches[0].clientY

        addEventListener('touchend', touchEvent => this.touchEnd(touchEvent, posXStart, posYStart), { once: true })
      }
    },
    touchEnd(touchEvent, posXStart, posYStart) {
      if (touchEvent.changedTouches.length === 1) {
        const posXEnd = touchEvent.changedTouches[0].clientX
        const posYEnd = touchEvent.changedTouches[0].clientY

        const swippedRight = posXEnd - posXStart > 100
        const swippedVertically = Math.abs(posYEnd - posYStart) > 50

        if (swippedRight && !swippedVertically) {
          this.$emit('toggle-rooms-list')
        }
      }
    },
    onRoomChanged() {
      this.loadingMessages = true
      this.scrollIcon = false
      this.scrollMessagesCount = 0
      this.resetMessage(true, true)

      if (this.roomMessage) {
        this.message = this.roomMessage
        setTimeout(() => this.onChangeInput())
      }

      if (!this.messages.length && this.messagesLoaded) {
        this.loadingMessages = false
      }

      const unwatch = this.$watch(
        () => this.messages,
        val => {
          if (!val || !val.length) return

          const element = this.$refs.scrollContainer
          if (!element) return

          unwatch()

          setTimeout(() => {
            element.scrollTo({ top: element.scrollHeight })
            this.loadingMessages = false
          })
        },
      )
    },
    onMessageAdded({ message, index, ref }) {
      if (index !== this.messages.length - 1) return

      const autoScrollOffset = ref.offsetHeight + 60

      setTimeout(() => {
        if (this.getBottomScroll(this.$refs.scrollContainer) < autoScrollOffset) {
          this.scrollToBottom()
        } else if (message.senderId === this.currentUserId) {
          this.scrollToBottom()
        } else {
          this.scrollIcon = true
          this.scrollMessagesCount++
        }
      })
    },
    onContainerScroll(e) {
      this.hideOptions = true

      if (!e.target) return

      const bottomScroll = this.getBottomScroll(e.target)
      if (bottomScroll < 60) this.scrollMessagesCount = 0
      this.scrollIcon = bottomScroll > 500 || this.scrollMessagesCount
    },
    updateFooterList(tagChar) {
      // //console.log('🚀 ~ file: Room.vue ~ line 1238 ~ updateFooterList ~ tagChar', tagChar)
      if (!this.loadSendMessage) {
        if (!this.getTextareaRef()) return

        if (tagChar === '@' && (!this.room.users || this.room.users.length <= 1)) {
          return
        }

        if (tagChar === '/' && !this.templatesText) {
          return
        }

        if (tagChar === 'quickReplies=' && this.quickReplies.length == 0) {
          this.$eventBus.$emit('showDialogShownSavedReplies')

          return
        }

        if (this.textareaCursorPosition === this.getTextareaRef().selectionStart) {
          return
        }

        this.textareaCursorPosition = this.getTextareaRef().selectionStart

        let position = this.textareaCursorPosition

        while (
          position > 0 &&
          this.message.charAt(position - 1) !== tagChar &&
          // eslint-disable-next-line no-unmodified-loop-condition
          (this.message.charAt(position - 1) !== ' ' || tagChar !== ':')
        ) {
          position--
        }

        const beforeTag = this.message.charAt(position - 2)
        const notLetterNumber = !beforeTag.match(/^[0-9a-zA-Z]+$/)

        if (this.message.charAt(position - 1) === tagChar && (!beforeTag || beforeTag === ' ' || notLetterNumber)) {
          const query = this.message.substring(position, this.textareaCursorPosition)
          if (tagChar === ':') {
            this.updateEmojis(query)
          } else if (tagChar === '@') {
            this.updateShowUsersTag(query)
          } else if (tagChar === '/') {
            this.updateShowTemplatesText(query)
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (tagChar === 'quickReplies=') {
            this.updateShowQuickReplies()
          } else {
            this.resetFooterList(tagChar)
          }
        }
      }
    },
    getCharPosition(tagChar) {
      const cursorPosition = this.getTextareaRef().selectionStart

      let position = cursorPosition
      while (position > 0 && this.message.charAt(position - 1) !== tagChar) {
        position--
      }

      const endPosition = this.getTextareaRef().selectionEnd

      return { position, endPosition }
    },
    async updateEmojis(query) {
      if (!query) return

      const emojis = await this.emojisDB.getEmojiBySearchQuery(query)
      this.filteredEmojis = emojis.map(emoji => emoji.unicode)
    },
    selectEmoji(emoji) {
      this.selectEmojiItem = false

      if (!emoji) return

      const { position, endPosition } = this.getCharPosition(':')

      this.message =
        this.message.substr(0, position - 1) + emoji + this.message.substr(endPosition, this.message.length - 1)

      this.cursorRangePosition = position
      this.focusTextarea()
    },
    async updateShowUsersTag(query) {
      const response = await this.$store.dispatch('inboxNavbar/getUsers', {
        subId: this.user.sub_id,
        roomId: window.location.pathname.split('/')[2],
      })

      if (response.status) {
        response.data.forEach(user => {
          const findUserData = this.$store.getters['global/getAllContactData'].find(
            userData => userData.phone_number === user._id && userData.notify_name !== user._id,
          )

          const findUserContact = this.allContacts.find(contact => contact.phone_number === user._id)

          if (findUserContact) {
            if (findUserContact.phone_number === user._id) {
              // //console.log('🚀 ~ file: Room.vue ~ line 1344 ~ updateShowUsersTag ~ user', user)

              // if (!findUserContact.phone_number?.includes('-') && findUserContact.phone_number?.length <= 16) {
              //   const countryCode = `+${findUserContact.phone_number.substr(0, 2)}` // +62
              //   const splitOne = findUserContact.phone_number.substr(2, 3)
              //   const splitTwo = findUserContact.phone_number.substr(5, 4)
              //   const splitThree = findUserContact.phone_number.substr(9, findUserContact.phone_number.length - 1)
              //   user.username = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
              // } else
              if (findUserContact.name === user._id) {
                if (findUserData) {
                  if (findUserData.bailey_name) {
                    user.username = findUserData.bailey_name
                  } else if (findUserData.notify_name) {
                    user.username = findUserData.notify_name
                  } else if (findUserData.contact_list_name) {
                    user.username = findUserData.contact_list_name
                  } else {
                    const countryCode = `+${findUserContact.phone_number.substr(0, 2)}` // +62
                    const splitOne = findUserContact.phone_number.substr(2, 3)
                    const splitTwo = findUserContact.phone_number.substr(5, 4)
                    const splitThree = findUserContact.phone_number.substr(9, findUserContact.phone_number.length - 1)
                    user.username = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
                  }
                } else {
                  const countryCode = `+${findUserContact.phone_number.substr(0, 2)}` // +62
                  const splitOne = findUserContact.phone_number.substr(2, 3)
                  const splitTwo = findUserContact.phone_number.substr(5, 4)
                  const splitThree = findUserContact.phone_number.substr(9, findUserContact.phone_number.length - 1)
                  user.username = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
                }
              } else if (findUserContact.name !== user._id) {
                user.username = findUserContact.name
              }
            }

            // users.push(findUserContact)
          } else if (!findUserContact && findUserData && findUserData.bailey_name) {
            user.username = findUserData.bailey_name
          } else if (!findUserContact && findUserData && findUserData.notify_name) {
            if (findUserData.notify_name === user._id) {
              const countryCode = `+${user._id.substr(0, 2)}` // +62
              const splitOne = user._id.substr(2, 3)
              const splitTwo = user._id.substr(5, 4)
              const splitThree = user._id.substr(9, user._id.length - 1)
              user.username = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
            } else {
              user.username = findUserData.notify_name
            }
          } else if (!findUserContact && findUserData && findUserData.contact_list_name) {
            user.username = findUserData.contact_list_name
          } else if (!findUserContact && user && !findUserData) {
            if (user._id === user.username.replace(/[+ -]/g, '')) {
              if (!user._id?.includes('-') && user._id?.length <= 16) {
                const countryCode = `+${user._id.substr(0, 2)}` // +62
                const splitOne = user._id.substr(2, 3)
                const splitTwo = user._id.substr(5, 4)
                const splitThree = user._id.substr(9, user._id.length - 1)
                user.username = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
              }
            } else if (user.bailey_name) {
              user.username = user.bailey_name
            } else if (user.notify_name) {
              user.username = user.notify_name
            } else if (user.contact_list_name) {
              user.username = user.contact_list_name
            }
          }
        })

        // //console.log(response, window.location.pathname.split('/'), query, `INI FILTER`)
        this.filteredUsersTag = filteredItems(response.data, 'username', query, true).filter(
          user => user._id !== this.currentUserId,
        )
      }

      // //console.log(this.filteredUsersTag, this.currentUserId, `ini user tag`)
    },
    selectUserTag(user, editMode = false) {
      this.selectUsersTagItem = false

      if (!user) return

      const { position, endPosition } = this.getCharPosition('@')

      const space = this.message.substr(endPosition, endPosition).length ? '' : ' '

      this.message =
        this.message.substr(0, position) +
        user.username +
        space +
        this.message.substr(endPosition, this.message.length - 1)

      this.selectedUsersTag = [...this.selectedUsersTag, { ...user }]

      if (!editMode) {
        this.cursorRangePosition = position + user.username.length + space.length + 1
      }

      this.focusTextarea()
    },
    updateShowTemplatesText(query) {
      this.filteredTemplatesText = filteredItems(this.templatesText, 'tag', query, true)
    },
    updateShowQuickReplies() {
      this.filteredQuickReplies = this.quickReplies
    },
    selectTemplateText(template) {
      this.selectTemplatesTextItem = false

      if (!template) return

      const { position, endPosition } = this.getCharPosition('/')

      const space = this.message.substr(endPosition, endPosition).length ? '' : ' '

      this.message =
        this.message.substr(0, position - 1) +
        template.text +
        space +
        this.message.substr(endPosition, this.message.length - 1)

      this.cursorRangePosition = position + template.text.length + space.length + 1

      this.focusTextarea()
    },
    async selectQuickReply(quickReply) {
      this.selectQuickRepliesItem = false
      this.selectTemplatesTextItem = false

      // //console.log(quickReply, 'data')

      // let instance = {}
      // if (quickReply.hasOwnProperty('sub_id')) {
      //   //console.log('masuk sini')
      //   const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
      //     sub_id: this.user.sub_id,
      //     token: this.user.token,
      //     key: 'instances',
      //   })
      //   instance = instancePg.data.value.find(el => el.instance_id === quickReply.sub_id)
      //   //console.log('instance :', instance)
      //   if (instance.phone_number_id) {
      //     //console.log('masokkkkkkkkkkkkkkkk')
      //   }
      // }

      const userTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
      })

      // //console.log(userTemplatePg, 'data')

      if (!quickReply) return
      if (this.isProcessingOnClickOfSendingQuickReply) return
      if (quickReply.hasOwnProperty('message_templates')) {
        // find instance type
        const instancesPg = await this.$store.dispatch('global/getSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
        })
        const instance = instancesPg.data.value.find(el => el._id === this.room.instance_id)

        const obj = {
          to: [this.room.phone_number],
          endpoint: `${this.user.sub_id}-${this.room.instance_id}`,
          post: {
            msg: quickReply.message_templates.message
              .replace('{{phone_number}}', this.room.phone_number)
              .replace('{{name}}', this.getContactsNameRoom(this.room.phone_number, this.room.roomName)),
            attachment: quickReply.message_templates.attachments,
          },
          deleteForMe: false,
          subId: this.user.sub_id,
          senderNumber: `${this.room.roomOwnerId}:aa` + `@bb`,
          roomId: this.room.roomId,
        }

        // do operation based on instance type
        if (instance.hasOwnProperty('phone_number_id')) {
          // WHATSAPP CLOUD
          if (obj.post.attachment.length === 0) {
            const payload = {
              sub_id: this.user.sub_id,
              instance_id: this.user,
              content: obj.post.msg,
              recipient_number: obj.to[0],
              room_id: obj.roomId,
            }
            // //console.log(payload)
            const url = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/wa-cloud/messages`
            const response = await this.$axios.post(url, payload)
            // //console.log('wa cloud template send response :', response)
            this.resetMessage(true)
            this.$emit('typing-message', false)
            this.isProcessingOnClickOfSendingQuickReply = false
          }
        } else {
          // WHATSAPP
          if (quickReply.message_templates.hasOwnProperty('buttonFooter')) {
            obj.post.buttonFooter = quickReply.message_templates.buttonFooter
          }
          if (quickReply.message_templates.hasOwnProperty('buttonHeader')) {
            obj.post.buttonHeader = quickReply.message_templates.buttonHeader
          }
          if (quickReply.message_templates.hasOwnProperty('listButton')) {
            obj.post.buttons = quickReply.message_templates.listButton
          }
          if (
            this.messages &&
            this.messages.length !== 0 &&
            this.messages[this.messages.length - 1].fromMe === false &&
            this.room.assign_to &&
            this.room.assign_to.email == this.user.email
          ) {
            obj.reply_id_message = this.messages[this.messages.length - 1].source_id
            obj.reply_timestamp = this.messages[this.messages.length - 1].seconds * 1000
          }
          obj.operator = this.user.email

          this.isProcessingOnClickOfSendingQuickReply = true
          this.$emit('typing-message', true)

          await this.$store.dispatch('broadcast/sendQuickReplyWithBroadcast', obj)

          this.resetMessage(true)
          this.$emit('typing-message', false)
          this.isProcessingOnClickOfSendingQuickReply = false
        }
      } else if (quickReply.isEform && quickReply.name === 'add_eform') {
        const self = this
        const { data } = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          list_id: quickReply.listId,
        })

        self.selectedSurvey = await this.getEFormById({ id: quickReply.eFormId })

        self.survey = new SurveyVue.Model(JSON.parse(JSON.stringify(this.selectedSurvey.survey)))

        self.keySurvey++
        self.openDialogSurvey = true

        self.survey.onComplete.add(async sender => {
          await self.onComplete(sender.data, self.room, quickReply.eFormId, data)

          self.openDialogSurvey = false
          self.survey = null
          self.selectedSurvey = null
        })

        const newContact = {
          answerData: [],
          type: 'Whatsapp',
          name: self.room.roomName ? self.room.roomName : self.room.phone_number,
          phoneNumber: self.room.phone_number,
          phone_number: self.room.phone_number,
          phone_number_show: self.room.phone_number_show,
          created_by: self.user.email,
          updated_by: self.user.email,
          created_at: Date.now(),
          updated_at: Date.now(),
          _id: self.room.phone_number,
          _uid: self.$nanoid(),
        }
        const contactData = data.contacts.concat(newContact)
        data.contacts = _uniqBy(contactData, 'phone_number')

        await this.$store.dispatch('global/insertOrUpdateContactList', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          ...res.data,
        })
      } else {
        this.sendMessage(quickReply)
      }
    },
    updateActiveUpOrDown(direction) {
      if (this.filteredEmojis.length) {
        this.activeUpOrDownEmojis = direction
      } else if (this.filteredUsersTag.length) {
        this.activeUpOrDownUsersTag = direction
      } else if (this.filteredTemplatesText.length) {
        this.activeUpOrDownTemplatesText = direction
      } else if (this.filteredQuickReplies.length) {
        this.activeUpOrDownQuickReplies = direction
      }
    },
    selectItem() {
      if (this.filteredEmojis.length) {
        // //console.log('1')
        this.selectEmojiItem = true
      } else if (this.filteredUsersTag.length) {
        // //console.log('2')
        this.selectUsersTagItem = true
      } else if (this.filteredTemplatesText.length) {
        // //console.log('3', this.selectTemplatesTextItem)
        this.selectTemplatesTextItem = true
      } else if (this.filteredQuickReplies.length) {
        // //console.log('4')
        this.selectQuickRepliesItem = true
      }
    },
    resetFooterList(tagChar = null) {
      if (!this.loadSendMessage) {
        if (tagChar === ':') {
          this.filteredEmojis = []
        } else if (tagChar === '@') {
          this.filteredUsersTag = []
        } else if (tagChar === '/') {
          this.filteredTemplatesText = []
        } else if (tagChar === 'quickReplies=') {
          this.filteredQuickReplies = []
        } else {
          this.filteredEmojis = []
          this.filteredUsersTag = []
          this.filteredTemplatesText = []
          this.filteredQuickReplies = []
        }

        this.textareaCursorPosition = null
      }
    },
    escapeTextarea() {
      if (this.filteredEmojis.length) this.filteredEmojis = []
      else if (this.filteredUsersTag.length) this.filteredUsersTag = []
      else if (this.filteredTemplatesText.length) this.filteredTemplatesText = []
      else if (this.filteredQuickReplies.length) this.filteredQuickReplies = []
      else this.resetMessage()
    },
    resetMessage(disableMobileFocus = false, initRoom = false) {
      // if (!initRoom) {
      //   this.$emit('typing-message', null)
      // }

      this.selectedUsersTag = []
      this.resetFooterList()
      this.resetTextareaSize()
      this.message = ''
      this.editedMessage = {}
      this.messageReply = null
      this.files = []
      this.emojiOpened = false
      this.preventKeyboardFromClosing()
      setTimeout(() => this.focusTextarea(disableMobileFocus))
    },
    resetTextareaSize() {
      if (this.getTextareaRef()) {
        this.getTextareaRef().style.height = '20px'
      }
    },
    focusTextarea(disableMobileFocus) {
      if (detectMobile() && disableMobileFocus) return
      if (!this.getTextareaRef()) return
      this.getTextareaRef().focus()

      if (this.cursorRangePosition) {
        setTimeout(() => {
          this.getTextareaRef().setSelectionRange(this.cursorRangePosition, this.cursorRangePosition)
          this.cursorRangePosition = null
        })
      }
    },
    preventKeyboardFromClosing() {
      if (this.keepKeyboardOpen) this.getTextareaRef().focus()
    },
    sendMessage(quickReply = null) {
      // //console.log(this.room, `INI ROOMNYA`)
      if (!this.room.msg_id) {
        let message = this.message.trim()

        if (quickReply) {
          if (quickReply.hasOwnProperty('text')) {
            message = quickReply.text.trim()
          } else {
            message = quickReply.name.trim()
          }
        }

        if (!this.files.length && !message) return

        if (this.isFileLoading) return

        this.selectedUsersTag.forEach(user => {
          message = message.replace(`@${user.username}`, `<usertag>${user._id}</usertag>`)
        })

        const files = this.files.length ? this.files : null

        if (this.editedMessage._id) {
          // //console.log('masuk sini', '------------')
          if (this.editedMessage.content !== message || this.editedMessage.files?.length || this.files.length) {
            this.$emit('edit-message', {
              messageId: this.editedMessage._id,
              newContent: message,
              files,
              replyMessage: this.messageReply,
              usersTag: this.selectedUsersTag,
            })
          }
        } else {
          // //console.log('masuk sini', files)
          this.$emit('send-message', {
            content: message,
            files,
            replyMessage: this.messageReply,
            usersTag: this.selectedUsersTag,
            quickReply,
          })
        }

        this.resetMessage(true)
      } else {
        // //console.log(this.message, this.room, `INI ROOM`)
        this.$emit('send-message', {
          content: this.message,
          room: this.room,
        })
        this.resetMessage(true)
      }
    },
    loadMoreMessages(infiniteState) {
      // //console.log('loadMoreMessages invoked')
      if (this.loadingMessages) {
        this.infiniteState = infiniteState

        return
      }

      setTimeout(
        () => {
          if (this.loadingMoreMessages) return

          if (this.messagesLoaded || !this.room.roomId) {
            return infiniteState.complete()
          }

          this.infiniteState = infiniteState
          this.$emit('fetch-messages')
          this.loadingMoreMessages = true
        },

        // prevent scroll bouncing issue on iOS devices
        iOSDevice() ? 500 : 0,
      )
    },
    messageActionHandler({ action, message }) {
      switch (action.name) {
        case 'replyMessage':
          return this.replyMessage(message)
        case 'editMessage':
          return this.editMessage(message)
        case 'deleteMessage':
          return this.$emit('delete-message', message)
        default:
          return this.$emit('message-action-handler', { action, message })
      }
    },
    sendMessageReaction(messageReaction) {
      this.$emit('send-message-reaction', messageReaction)
    },
    replyMessage(message) {
      this.editedMessage = {}
      this.messageReply = message
      this.focusTextarea()
    },
    editMessage(message) {
      this.resetMessage()

      this.editedMessage = { ...message }

      let messageContent = message.content
      const initialContent = messageContent

      const firstTag = '<usertag>'
      const secondTag = '</usertag>'

      const usertags = [...messageContent.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)

      usertags.forEach(index => {
        const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

        const user = this.room.users.find(user => user._id === userId)

        messageContent = messageContent.replace(
          `${firstTag}${userId}${secondTag}`,
          `@${this.getContactsNameRoom(this.room.phone_number, user?.username)}`,
        )

        this.selectUserTag(user, true)
      })

      this.message = messageContent

      if (message.files) {
        this.files = [...message.files]
      }

      setTimeout(() => this.resizeTextarea())
    },
    getBottomScroll(element) {
      const { scrollHeight, clientHeight, scrollTop } = element

      return scrollHeight - clientHeight - scrollTop
    },
    scrollToBottom() {
      setTimeout(() => {
        const element = this.$refs.scrollContainer

        if (element) {
          element.classList.add('vac-scroll-smooth')
          element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' })
          setTimeout(() => element.classList.remove('vac-scroll-smooth'))
        }
      }, 50)
    },
    onChangeInput: debounce(function () {
      if (this.getTextareaRef()?.value || this.getTextareaRef()?.value === '') {
        this.message = this.getTextareaRef()?.value
      }
      this.keepKeyboardOpen = true
      this.resizeTextarea()

      // this.$emit('typing-message', this.message)
    }, 100),
    resizeTextarea() {
      const el = this.getTextareaRef()

      if (!el) return

      const padding = window.getComputedStyle(el, null).getPropertyValue('padding-top').replace('px', '')

      el.style.height = 0
      el.style.height = `${el.scrollHeight - padding * 2}px`
    },
    addEmoji(emoji) {
      const el = this.getTextareaRef()

      if (!el) return

      const start = el.selectionStart
      const end = el.selectionEnd
      const text = el.value
      const before = text.substring(0, start)
      const after = text.substring(end, text.length)
      el.value = before + emoji.unicode + after
      el.selectionStart = el.selectionEnd = start + emoji.unicode.length

      this.message = el.value
      this.focusTextarea(true)
    },
    launchFilePicker(type) {
      // //console.log(type, `INI TYPENYA`)
      this.acceptedFiles = '*'

      if (type == 'doc') {
        this.acceptedFiles = `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*`
      } else if (type == 'media') {
        this.acceptedFiles = 'image/*, video/*'
      }

      this.$refs.file.accept = this.acceptedFiles
      this.$refs.file.value = ''
      this.$refs.file.click()
      // //console.log(this.acceptedFiles, this.$refs.file, `INI ACCEPTED FILES`)
    },
    onPasteImage(pasteEvent) {
      const items = pasteEvent.clipboardData?.items

      if (items) {
        Array.from(items).forEach(item => {
          if (item.type.includes('image')) {
            const blob = item.getAsFile()
            this.onFileChange([blob])
          }
        })
      }
    },
    async onFileChange(files) {
      this.fileDialog = true
      this.focusTextarea()

      Array.from(files).forEach(async file => {
        const fileURL = URL.createObjectURL(file)
        const typeIndex = file.name.lastIndexOf('.')

        this.files.push({
          loading: true,
          name: file.name.substring(0, typeIndex),
          size: file.size,
          type: file.type,
          extension: file.name.substring(typeIndex + 1),
          localUrl: fileURL,
        })

        const blobFile = await fetch(fileURL).then(res => res.blob())

        const loadedFile = this.files.find(file => file.localUrl === fileURL)

        if (loadedFile) {
          loadedFile.blob = blobFile
          loadedFile.loading = false
          delete loadedFile.loading
        }
      })

      setTimeout(() => (this.fileDialog = false), 500)
    },
    removeFile(index) {
      this.files.splice(index, 1)
      this.focusTextarea()
    },
    initRecorder() {
      this.isRecording = false

      return new Recorder({
        bitRate: this.audioBitRate,
        sampleRate: this.audioSampleRate,
        beforeRecording: null,
        afterRecording: null,
        pauseRecording: null,
        micFailed: this.micFailed,
      })
    },
    micFailed() {
      this.isRecording = false
      this.recorder = this.initRecorder()
    },
    toggleRecorder(recording) {
      if (this.isChannelOnline) {
        this.isRecording = recording

        if (!this.recorder.isRecording) {
          setTimeout(() => this.recorder.start(), 200)
        } else {
          try {
            this.recorder.stop()

            const record = this.recorder.records[0]

            this.files.push({
              blob: record.blob,
              name: `audio.${this.format}`,
              size: record.blob.size,
              duration: record.duration,
              type: record.blob.type,
              audio: true,
              localUrl: URL.createObjectURL(record.blob),
            })

            this.recorder = this.initRecorder()
            this.sendMessage(null)
          } catch {
            setTimeout(() => this.stopRecorder(), 100)
          }
        }
      }
    },
    stopRecorder() {
      if (this.recorder.isRecording) {
        try {
          this.recorder.stop()
          this.recorder = this.initRecorder()
        } catch {
          setTimeout(() => this.stopRecorder(), 100)
        }
      }
    },
    openFile({ message, file }) {
      this.$emit('open-file', { message, file })
    },
    openUserTag(user) {
      // //console.log(user, this.room, 'INI OPEN USER TAG')
      if (user) {
        const objUser = {
          roomId: `${this.room.instance_id}-${user.user ? user.user._id : user._id}`,
        }
        const isFromClickedNameTag = true
        this.$emit('open-user-tag', { room: objUser }, false, isFromClickedNameTag)
      }
    },
    textareaActionHandler() {
      // //console.log(666)
      this.$emit('textarea-action-handler', this.message)
    },
    selectMessagesActionHandler(action) {
      this.$emit('select-messages-action-handler', {
        action,
        messages: this.selectedMessages,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/src/styles/globalsScreen';

#fixing-room {
  @include screen('0px') {
    position: fixed !important;
    height: calc(100% - 118px);
    width: 100% !important;
  }
  @include screen('md') {
    position: absolute !important;
    height: calc(100% - 120px) !important;
    /* &.active-banner {
      height: calc(100% - 374px) !important;
    }*/
    width: 100% !important;
    top: 0;
    display: none;
  }
}

#fixing-room-2 {
  @include screen('0px') {
    width: 100% !important;
  }
  /* @include screen('md') {
    //width: 46.7% !important;
    width: 100% !important;
    !*
    &.active {
      width: 80% !important;
    }
    &.active-sidebar {
      width: 55% !important;
    }*!
  }*/
}

#fixing-footer {
  @include screen('0px') {
    position: fixed !important;
    width: 100% !important;
    bottom: 10px;
  }
  @include screen('md') {
    position: absolute !important;
    width: 100% !important;

    //width: 46.7% !important;
    bottom: 0;
    /*
    &.active {
      width: 80% !important;
    }
    &.active-sidebar {
      width: 55% !important;
    }*/
  }
}

#fixing-footer-2 {
  @include screen('0px') {
    position: fixed !important;
    width: 100% !important;
    // bottom: 10px;
  }
  @include screen('md') {
    position: absolute !important;
    width: 100% !important;

    //width: 46.7% !important;
    bottom: 0;
    /*
    &.active {
      width: 80% !important;
    }
    &.active-sidebar {
      width: 55% !important;
    }*/
  }
}
#vac-icon-scroll {
  position: absolute;
  bottom: 100px;
}
.vac-checkbox-footer {
  width: 100%;
  min-height: 20px;
  padding: 12px;
}
</style>
