<template>
  <div class="vac-message-location-container">
    <div>
      <div class="vac-location-container" @click.stop="openLocation">
        <div class="vac-svg-button">
          <v-icon color="info">
            {{ icons.mdiGoogleMaps }}
          </v-icon>
        </div>
      </div>
    </div>

    <format-message
      :content="message.content"
      :users="roomUsers"
      :text-formatting="textFormatting"
      :link-options="linkOptions"
      :all-contacts="allContacts"
      @open-user-tag="openUserTag"
      :from-message-location="true"
    >
      <template v-for="(i, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </format-message>
  </div>
</template>

<script>
import { mdiGoogleMaps } from '@mdi/js'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'

export default {
  name: 'MessageLocation',

  setup() {
    return {
      icons: {
        mdiGoogleMaps,
      },
    }
  },

  components: { FormatMessage },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    roomUsers: { type: Array, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    allContacts: { type: Array, default: () => [] },
  },

  emits: ['open-user-tag'],

  methods: {
    openLocation() {
      const gmapsUrl = `https://maps.google.com/?q=${this.message.data.lat},${this.message.data.lng}`
      window.open(gmapsUrl)
    },
    openUserTag(user) {
      this.$emit('open-user-tag', { user })
    },
  },
}
</script>
