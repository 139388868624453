<template>
  <transition name="vac-slide-up">
    <div
      v-if="messageReply"
      class="vac-reply-container"
      :style="{ bottom: `${$parent.$refs.roomFooter.clientHeight}px` }"
    >
      <div class="vac-reply-box">
        <div class="vac-reply-info">
          <div class="vac-reply-username">
            {{ getUsername(messageReply) }}
            <!-- {{ messageReply.username }} -->
          </div>
          <div class="vac-reply-content">
            <format-message
              :content="messageReply.content"
              :users="room.users"
              :all-contacts="allContacts"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :reply="true"
            >
              <template
                v-for="(i, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </format-message>
          </div>
        </div>

        <template v-if="isImage">
          <img
            :src="firstFile.url"
            class="vac-image-reply"
          />
        </template>

        <template v-else-if="isVideo">
          <template v-if="videoThumbnail">
            <img
              :src="videoThumbnail"
              class="vac-image-reply"
            />
          </template>
          <template v-else>
            <div
              class="vac-image-reply vac-file-container"
            >
              <div>
                <v-icon color="info">
                  {{ icons.mdiVideo }}
                </v-icon>
              </div>
            </div>
            <!-- <video
              ref="refVideo"
              controls
              class="vac-image-reply"
            >
              <source :src="firstFile.url" />
            </video>

            <canvas
              ref="refCanvas"
              class="d-none"
            ></canvas> -->
          </template>
        </template>

        <template v-else-if="isAudio">
          <audio-player
            :src="firstFile.url"
            class="vac-audio-reply"
          >
            <template
              v-for="(i, name) in $scopedSlots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </audio-player>
        </template>

        <template v-else-if="isOtherFile">
          <div
            class="vac-image-reply vac-file-container"
          >
            <div>
              <slot name="file-icon">
                <svg-icon name="file" />
              </slot>
            </div>
            <div class="vac-text-ellipsis">
              {{ firstFile.name }}
            </div>
            <div
              v-if="firstFile.extension"
              class="vac-text-ellipsis vac-text-extension"
            >
              {{ firstFile.extension }}
            </div>
          </div>
        </template>
      </div>

      <div class="vac-icon-reply">
        <div
          class="vac-svg-button"
          @click="$emit('reset-message')"
        >
          <slot name="reply-close-icon">
            <svg-icon name="close-outline" />
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mdiVideo } from '@mdi/js'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'

import AudioPlayer from '../../Message/AudioPlayer/AudioPlayer'

const { isAudioFile, isImageFile, isVideoFile } = require('../../../utils/media-file')

export default {
  name: 'RoomMessageReply',

  components: {
    SvgIcon,
    FormatMessage,
    AudioPlayer,
  },

  setup() {
    return {
      icons: {
        mdiVideo,
      },
    }
  },

  props: {
    room: { type: Object, required: true },
    messageReply: { type: Object, default: null },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    roomUsers: { type: Array, required: true, default: () => [] },
    allContacts: { type: Array, required: true, default: () => [] },
  },

  emits: ['reset-message'],

  data() {
    return {
      videoThumbnail: false,
    }
  },

  computed: {
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    firstFile() {
      return this.messageReply.files?.length ? this.messageReply.files[0] : {}
    },
    isImage() {
      return isImageFile(this.firstFile)
    },
    isVideo() {
      return isVideoFile(this.firstFile)
    },
    isAudio() {
      return isAudioFile(this.firstFile)
    },
    isOtherFile() {
      return this.messageReply.files?.length && !this.isAudio && !this.isVideo && !this.isImage
    },
  },

  mounted() {
    if (this.messageReply) {
      if (this.firstFile) {
        if (this.isVideo) {
          // this.getVideoThumbnail()
        }
      }
    }
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      return phoneNumber
    },
    getUsername(msg) {
      // //console.log(msg, this.roomUsers, `INI test`)

      // //console.log(this.roomUsers)
      const userData = this.allContactData.find(contact => contact.phone_number == msg.senderId)

      // if (phoneNumber == 6282161616156) {
      //   //console.log(userData, `INI USER DATA`)
      // }

      if (this.allContacts) {
        const foundContact = this.allContacts.find(x => x.phone_number === msg.senderId)

        // //console.log(foundContact, `INI KONTAK COUCH`)

        if (foundContact) {
          return foundContact.name
        }
      }

      if (userData) {
        if (userData.bailey_name) {
          return userData.bailey_name
        }

        if (userData.notify_name) {
          return userData.notify_name
        }

        if (userData.contact_list_name) {
          return userData.contact_list_name
        }

        // if (userData.data.username) {
        //   return userData.data.username
        // }
      }

      return this.formatPhoneNumber(msg.senderId)
    },
    getVideoThumbnail() {
      const self = this
      const _VIDEO = this.$refs.refVideo
      const _CANVAS = this.$refs.refCanvas

      // Video metadata is loaded
      _VIDEO.addEventListener(
        'loadedmetadata',
        function () {
          setTimeout(function () {
            _CANVAS.getContext('2d').drawImage(_VIDEO, 0, 0, _CANVAS.width, _CANVAS.height)

            try {
              if (_CANVAS.toDataURL()) {
                self.videoThumbnail = _CANVAS.toDataURL()
              }
            } catch (error) {
              //console.log('thumbnail unavailable')
            }
          }, 500)
        },
        false,
      )

      _VIDEO.currentTime = 0
      _VIDEO.load()
    },
  },
}
</script>
<style scoped lang="scss">
.overlay-video {
  position: absolute;
  top: 35%;
  left: 40%;
}
</style>
