<template>
  <div class="vac-room-header vac-app-border-b mystyle">
    <v-dialog
      v-model="nameEditField"
      persistent
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            Edit
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text class="heigth-dialog">
          <v-snackbar
            v-model="showSnackbarErr"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 5 !important"
          >
            {{ errMessage }}
            <template #action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="closeShowSnackbarErr()"
              >
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-text-field
            v-model="currentName"
            label="Name"
            class="mr-4 mt-4"
          ></v-text-field>
          <v-text-field
            v-model="nickname"
            label="Nickname"
            class="mr-4 mt-2"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#ffeeee"
            class=""
            @click="nameEditField = false"
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn
            color="success"
            @click="saveEdit"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <slot
      name="room-header"
      v-bind="{ room, typingUsers, userStatus }"
    >
      <div class="vac-room-wrapper fixing-room-header">
        <div
          v-if="!singleRoom"
          class="vac-svg-button vac-toggle-button"
          :class="{ 'vac-rotate-icon': !showRoomsList && !isMobile }"
          @click="$emit('toggle-rooms-list')"
        >
          <slot name="toggle-icon">
            <svg-icon name="toggle" />
          </slot>
        </div>
        <div
          class="vac-info-wrapper"
          :class="{ 'vac-item-clickable': roomInfoEnabled }"
          @click="$emit('room-info')"
        >
          <slot
            name="room-header-avatar"
            style="z-index:999999"
            v-bind="{ room }"
          >
            <div
              v-if="room.avatar"
              class="vac-avatar vac-item-clickable"
              style="z-index:999999"
              :style="{ 'background-image': `url('${room.avatar}')` }"
            />
          </slot>
          <slot
            name="room-header-info"
            style="z-index:999999"
            v-bind="{ room, typingUsers, userStatus }"
          >
            <div
              class="vac-text-ellipsis"
              style="z-index:999999"
            >
              <div
                class="vac-room-name vac-text-ellipsis"
                style="z-index:999999"
              >
                <span> {{ getContactsNameRoom(room.phone_number, room.roomName) }} </span>
                <v-icon
                  size="18"
                  @click.stop="editName"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </div>
              <pre style="display:none">{{ backupContactsNameRoom(room.phone_number, room.roomName) }}</pre>
              <div
                v-if="typingUsers"
                class="vac-room-info vac-text-ellipsis"
              >
                {{ typingUsers }}
              </div>
              <div
                v-else
                class="vac-room-info vac-text-ellipsis"
              >
                {{ userStatus }}
              </div>

              <div
                v-if="room.instance && !singleRoom"
                style="font-size: 12px"
                :style="{ color: room.instance ? room.instance.color : 'blue' }"
                class="vac-room-name"
              >
                Channel : {{ room.instance.label && room.instance.label.startsWith('-') || room.instance.label.includes('Default') || room.instance.label.includes('62') ? room.instance.label_server : room.instance.label }}, Click for details
              </div>
            </div>
            <div
              v-if="!singleRoom"
              class="show-condition"
            >
              <div class="pinned">
                <v-tooltip
                  v-if="room.unreadCount > 0"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="vac-text-ellipsis">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="markAsRead(room)"
                      >
                        <v-icon>
                          {{ icons.mdiEyeOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  Mark as Read
                </v-tooltip>
                <div class="vac-text-ellipsis">
                  <v-btn
                    v-if="!room.msg_id"
                    icon
                    @click.stop="$emit('change-pinned')"
                  >
                    <v-icon>
                      {{ room.pinned ? icons.mdiPin : icons.mdiPinOff }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </slot>
          <div
            v-if="!singleRoom"
            class="vac-text-ellipsis pinned hide-consition"
            :class="{ 'd-contents': !isMobile }"
          >
            <v-tooltip
              v-if="room.unreadCount > 0"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  @click.stop="markAsRead(room)"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              Mark as Read
            </v-tooltip>
            <!-- <v-btn
              icon
              @click.stop="editName"
            >
              <v-icon>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn> -->
            <v-btn
              v-if="!room.msg_id"
              icon
              @click.stop="$emit('change-pinned')"
            >
              <v-icon>
                {{ room.pinned ? icons.mdiPin : icons.mdiPinOff }}
              </v-icon>
            </v-btn>
            <!-- <v-select
              v-model="room.roomStatus"
              solo
              label="Status"
              :items="roomFilterList"
              dense
              persistent-hint
              hide-details
              @click.stop
              @change="updateRoom('roomStatus')"
            ></v-select> -->
          </div>
          <div
            v-if="!repliesHistory && !singleRoom &&( user.chat_mode === 'Full Access' || user.chat_mode === 'Write to assigned only')"
            class="vac-text-ellipsis p-3 hide-consition"
          >
            <v-select
              v-if="!isMobile"
              v-model="room.assign_to"
              solo
              label="Assign To"
              :items="assignToUserList"
              item-text="email"
              return-object
              persistent-hint
              :disabled="user.chat_mode === 'Write to assigned only'"
              dense
              hide-details
              @click.stop
              @change="$emit('change-operator',room.assign_to)"
            ></v-select>
          </div>
        </div>

        <slot
          v-if="room.roomId"
          name="room-options"
        >
          <div
            v-if="menuActions.length && !singleRoom"
            class="vac-svg-button vac-room-options"
            @click="menuOpened = !menuOpened"
          >
            <slot name="menu-icon">
              <svg-icon name="menu" />
            </slot>
          </div>
          <transition
            v-if="menuActions.length"
            name="vac-slide-left"
          >
            <div
              v-if="menuOpened"
              v-click-outside="closeMenu"
              class="vac-menu-options"
            >
              <div class="vac-menu-list">
                <div
                  v-for="action in menuActions"
                  :key="action.name"
                >
                  <div
                    class="vac-menu-item"
                    @click="menuActionHandler(action)"
                  >
                    {{ action.title }}
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </slot>
      </div>
    </slot>
    <div
      v-if="!repliesHistory"
      class="show-condition"
    >
      <slot
        name="room-header"
        v-bind="{ room, typingUsers, userStatus }"
        class="show-condition"
      >
        <v-row>
          <!-- <v-col cols="6">
            <v-select
              v-model="room.roomStatus"
              outlined
              label="Status"
              class="mb-2 ml-2 show-condition"
              :items="roomFilterList"
              dense
              persistent-hint
              hide-details
              @click.stop
              @change="updateRoom('roomStatus')"
            ></v-select>
          </v-col> -->
          <v-col cols="12">
            <v-select
              v-if="!isMobile"
              v-model="room.assign_to"
              outlined
              class="mb-2 mr-2 show-condition"
              label="Assign To"
              :items="assignToUserList"
              item-text="email"
              return-object
              persistent-hint
              dense
              hide-details
              @click.stop
              @change="$emit('change-operator',room.assign_to)"
            ></v-select>
            <!-- @change="updateRoom('assign_to')" -->
          </v-col>
        </v-row>
      </slot>
    </div>
  </div>
</template>

<script>
import firestoreDbMixin from '@/mixins/firestoreDbMixin'
import { mdiEyeOutline, mdiPin, mdiPinOff, mdiPencilOutline } from '@mdi/js'
import vClickOutside from 'v-click-outside'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import typingText from '../../../utils/typing-text'

export default {
  name: 'RoomHeader',
  components: {
    SvgIcon,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [firestoreDbMixin],

  props: {
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    singleRoom: { type: Boolean, required: true },
    showRoomsList: { type: Boolean, required: true },
    isMobile: { type: Boolean, required: true },
    roomInfoEnabled: { type: Boolean, required: true },
    menuActions: { type: Array, required: true },
    room: { type: Object, required: true },
    assignToUserList: { type: Array, default: () => [] },
    repliesHistory: { type: Boolean, required: false },
    filteredQuickReplies: { type: Boolean, required: true },
  },

  emits: ['toggle-rooms-list', 'change-operator', 'change-pinned', 'room-info', 'menu-action-handler', 'update-room'],

  data() {
    return {
      showSnackbarErr: false,
      errMessage: '',
      currentName: '',
      nickname: '',
      menuOpened: false,
      nameEditField: false,
      backupRoomName: '',
      roomFilterList: ['open', 'close'],
      icons: {
        mdiPin,
        mdiPinOff,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
  computed: {
    typingUsers() {
      return typingText(this.room, this.currentUserId, this.textMessages)
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    userStatus() {
      if (!this.room.users || this.room.users.length !== 2) return

      const user = this.room.users.find(u => u._id !== this.currentUserId)
      let text = ''

      if (user) {
        if (!user.status) return

        if (user.status.state === 'online') {
          text = this.textMessages.IS_ONLINE
        } else if (user.status.lastChanged) {
          text = this.textMessages.LAST_SEEN + user.status.lastChanged
        }
      }

      return text
    },
  },
  methods: {
    closeShowSnackbarErr() {
      this.showSnackbarErr = false
    },
    async saveEdit() {
      if (!this.currentName) {
        this.errMessage = 'Invalid Input Name'
        this.showSnackbarErr = true
      } else if (!this.nickname) {
        this.errMessage = 'Invalid Input Nickname'
        this.showSnackbarErr = true
      } else {
        // //console.log(this.currentName, this.room)
        // if (this.room.roomContactList && this.room.roomContactList.length !== 0) {
        const res = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
        })

        // const currentContactOnRoom = this.room.roomContactName.split(', ')

        let currentList = res.data.find(contact => contact.list_id === 'default')
        if (currentList) {
          const currentContact = currentList.contacts.find(contact => contact.phone_number === this.room.phone_number)
          if (currentContact) {
            currentContact.name = this.currentName
            currentContact.nickname = this.nickname

            for (let contact of currentList.contacts) {
              if (contact.phone_number === currentContact.phone_number) {
                currentContact.updated_at = Date.now()
                contact = currentContact
              }
            }

            await this.$store.dispatch('global/insertOrUpdateContactList', {
              sub_id: this.user.sub_id,
              token: this.user.token,
              ...currentList,
            })
          } else {
            const contact = JSON.parse(JSON.stringify(this.room))
            contact.has_wa = 'Yes'
            contact.created_at = Date.now()
            contact.updated_at = Date.now()
            contact.name = this.currentName
            contact.nickname = this.nickname
            contact.type = contact.type ? contact.type : 'Whatsapp'
            currentList.contacts = currentList.contacts.concat(contact)

            // //console.log(currentList.currentList.contacts, `INI KONTAK`)
            await this.$store.dispatch('global/insertOrUpdateContactList', {
              sub_id: this.user.sub_id,
              token: this.user.token,
              ...currentList,
            })
          }
        } else {
          const contact = JSON.parse(JSON.stringify(this.room))

          const settings = {
            _id: 'defaultcolumns',
            key: 'defaultcolumns',
            value: [
              {
                id: 2,
                name: 'name',
                align: 'left',
                label: 'Name',
                field: 'name',
                value: 'name',
                text: 'Name',
                sortable: true,
                type: 'text',
                show: true,
                showable: false,
                formatDate: '',
                deleteable: false,
                isRequired: true,
                defaultValue: '',
                editable: true,
                order_number: 3,
              },
              {
                id: 3,
                name: 'phone_number',
                align: 'left',
                label: 'Phone Number',
                field: 'phone_number',
                value: 'phone_number',
                text: 'Phone Number',
                sortable: true,
                type: 'text',
                show: false,
                showable: true,
                formatDate: '',
                deleteable: false,
                isRequired: true,
                defaultValue: '',
                editable: false,
                order_number: 2,
              },
              {
                id: 14,
                name: 'phone_number_show',
                align: 'center',
                label: 'Phone Number',
                field: 'phone_number_show',
                value: 'phone_number_show',
                text: 'Phone Number',
                sortable: true,
                type: 'text',
                show: true,
                showable: true,
                formatDate: '',
                deleteable: false,
                isRequired: true,
                defaultValue: '',
                editable: false,
                order_number: 2,
              },
            ],
          }

          currentList = {
            _id: 'default',
            list_id: 'default',
            list_name: 'default',
            contacts: [
              {
                name: this.currentName,
                nickname: this.nickname,
                type: contact.type ? contact.type : 'Whatsapp',
                _id: contact.phoneNumber,
                phone_number: contact.phoneNumber,
                phone_number_show: contact.phoneNumber,
                _uid: this.$nanoid(),
                ...contact,
              },
            ],
            columns: settings,

            // last_updated: new Date().getTime(),
          }

          await this.$store.dispatch('global/insertOrUpdateContactList', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            ...currentList,
          })
        }

        this.$emit('update-room')

        // //console.log(currentList, currentContact)
        // }
        this.nameEditField = false
        this.currentName = ''
        this.nickname = ''

        await this.$store.dispatch('global/findAllContactData', {
          sub_id: this.user.sub_id,
        })
      }
    },
    backupContactsNameRoom(a, b) {
      this.backupRoomName = a + b
    },
    editName() {
      // this.backupRoomName = this.getContactsNameRoom(this.room.phone_number, this.room.roomName)
      // if (this.room.roomContactName) {
      this.nameEditField = true
      this.currentName = this.room.roomName ? this.room.roomName : this.room.phone_number
      this.nickname = this.room.nickname ? this.room.nickname : this.room.name

      // }

      // this.$emit('room-info')
    },
    markAsRead(room) {
      let sourceIds = []
      if (room.lastMessage.original_message) {
        //console.log(room.lastMessage, `INI LAST MSG`)
        sourceIds = [room.lastMessage.original_message.key]
      }
      this.setReadMessages(sourceIds, room)
    },
    setReadMessages(sourceIds, room, senderId = null) {
      const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send-read`
      const body = {
        subId: room.subId,
        roomId: room.roomId,
        messageKey: sourceIds,
        server: 'wabailey',
      }

      if (senderId) {
        body.senderId = senderId
      }

      // //console.log(body, 'ini body')

      this.$axios
        .post(endpoint, body)
        .then(response => {
          //console.log(response, `INI RESPONSE`)

          // //console.log(body)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getContactsNameRoom(phoneNumber, roomName) {
      if (this.backupRoomName !== phoneNumber + roomName) {
        this.nameEditField = false
      }

      if (this.allContactsFirestore) {
        const foundContact = this.allContactsFirestore.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }

      if (this.allContacts) {
        const foundContact = this.allContacts.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }

      if (this.couchAllContact) {
        const foundContact = this.couchAllContact.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }

      return roomName
    },
    async changePinned2(room) {
      const currentRoom = JSON.parse(JSON.stringify(this.room))

      if (currentRoom) {
        if (!currentRoom.pinned) {
          currentRoom.pinned = 1
        } else {
          currentRoom.pinned = 0
        }

        const doc = {
          pinned: currentRoom.pinned,
        }

        this.$emit('update-room', { room: this.room, doc, field: 'pinned' })
      }
    },
    menuActionHandler(action) {
      this.closeMenu()
      if (!this.filteredQuickReplies) {
        this.$emit('openQuickReply', true)
      } else {
        this.$emit('openQuickReply')
      }

      this.$emit('menu-action-handler', action)
    },
    closeMenu() {
      this.menuOpened = false
    },
    updateRoom(field) {
      const doc = {}
      doc[field] = this.room[field]
      this.$emit('update-room', { room: this.room, doc, field })
      this.nameEditField = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/src/styles/globalsScreen';
#fixing-room-header {
  height: auto !important;

  @include screen('0px') {
    position: fixed !important;
    width: 100% !important;
  }
  @include screen('md') {
    position: absolute !important;
    width: 100% !important;
    //&.active-sidebar {
    //  width: 550% !important;
    //}
  }
}
.show-condition {
  display: none;
}
.pinned {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 968px) {
  .hide-consition {
    display: none;
  }
  .show-condition {
    display: block;
  }
  .mystyle {
    height: 100px !important;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 898px) {
  .mystyle {
    height: 60px !important;
    display: flex;
    flex-direction: column;
  }
}
</style>
