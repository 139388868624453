<template>
  <div>
    <v-dialog v-model="dialog.newMeeting" width="75%">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ 'NEW MEETING' }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="dialog.newMeeting = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="mr-3" @click="saveMeeting">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-row style="margin-top: 10px">
            <v-col cols="6" md="6">
              <v-menu
                ref="menuDate"
                v-model="menuDatePicker"
                :close-on-content-click="false"
                :return-value.sync="datePicker"
                transition="scale-transition"
                bottom
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-slide-y-transition>
                    <v-text-field
                      v-model="dateText"
                      transition="scroll-y-transition"
                      small
                      label="Date"
                      :prepend-icon="icons.mdiCalendarMonth"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </v-slide-y-transition>
                </template>
                <v-date-picker
                  v-model="datePicker"
                  no-title
                  scrollable
                  :min="minDatePicker"
                  :max="maxDatePicker"
                  :locale="locale"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#ffeeee" @click="menuDatePicker = false">
                    <span class="error--text">{{ $t('cancel') }}</span>
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menuDate.save(datePicker)"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="6">
              <v-menu
                ref="menuTime"
                v-model="menuTimePicker"
                :close-on-content-click="false"
                :return-value.sync="timePicker"
                transition="scale-transition"
                bottom
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-slide-y-transition>
                    <v-text-field
                      v-model="timeText"
                      transition="scroll-y-transition"
                      small
                      label="Time"
                      :prepend-icon="icons.mdiClockTimeEight"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </v-slide-y-transition>
                </template>
                <v-time-picker v-model="timePicker" format="24hr" :min="minTimePicker" :locale="locale">
                  <v-spacer></v-spacer>
                  <v-btn text color="#ffeeee" @click="menuTimePicker = false">
                    <span class="error--text">{{ $t('cancel') }}</span>
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menuTime.save(timePicker)"> OK </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row style="margin-top: 10px">
            <v-col cols="6" md="6">
              <v-text-field v-model="title" :label="'Meeting Title'" outlined class="mr-1 mb-1"></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-switch v-model="isReminder" hide-details class="mt-0 mx-2" label="Reminder"> </v-switch>
            </v-col>
            <v-col v-if="isReminder" cols="4" md="4">
              <v-text-field
                v-model="reminderMinutes"
                :label="'x minutes before meeting start'"
                outlined
                type="number"
                class="mr-1 mb-1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.meeting" width="75%">
      <v-card>
        <v-card-text>
          <v-btn tile class="mb-2" color="primary" @click="openNewMeeting()">
            <v-icon left>
              {{ icons.mdiPlus }}
            </v-icon>
            New Meeting
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="listMeeting"
            :items-per-page="10"
            :loading="loading"
            item-key="id"
            multi-sort
            class="text-no-wrap text-center"
            loading-text="Loading... Please wait"
            :footer-props="{
              'items-per-page-text': $t('rowsPerPageLabel'),
            }"
          >
            <template #[`item.option`]="{ item }">
              <v-btn small icon @click="sendMeeting(item)">
                <v-icon>{{ icons.mdiSend }}</v-icon>
              </v-btn>
            </template>
            <template #[`item.invitation`]="{ item }">
              {{ item.list_invitation.length }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiSend, mdiPlus, mdiCalendarMonth, mdiClockTimeEight } from '@mdi/js'
import PouchDb from '@/mixins/pouchDbMixin'

export default {
  name: 'MeetingListDialog',
  mixins: [PouchDb],

  data() {
    return {
      icons: {
        mdiSend,
        mdiPlus,
        mdiCalendarMonth,
        mdiClockTimeEight,
      },
      loading: false,
      dialog: {
        meeting: false,
        newMeeting: false,
      },
      menuDatePicker: false,
      menuTimePicker: false,
      minDatePicker: new Date().toISOString(),
      maxDatePicker: null,
      datePicker: '',
      timePicker: '',
      title: '',
      isReminder: false,
      reminderMinutes: 0,
    }
  },

  computed: {
    minTimePicker() {
      const dateNow = new Date()
      const dateChoose = this.datePicker !== '' ? new Date(this.datePicker) : dateNow
      if (dateNow.getTime() === dateChoose.getTime()) return `${new Date().getHours()}:${new Date().getMinutes()}`
      if (
        dateNow.getDate() === dateChoose.getDate() &&
        dateNow.getMonth() === dateChoose.getMonth() &&
        dateNow.getFullYear() === dateChoose.getFullYear()
      ) {
        return `${new Date().getHours()}:${new Date().getMinutes()}`
      }

      return null
    },
    dateText() {
      return this.datePicker
    },
    timeText() {
      return this.timePicker
    },
    locale() {
      return this.$i18n.locale
    },
    meetingList() {
      return []
    },
    headers() {
      return [
        { text: 'Action', value: 'option', align: 'center' },
        { text: 'Title', value: 'title', align: 'center' },
        { text: 'Date', value: 'date', align: 'center' },
        { text: 'Time', value: 'time', align: 'center' },
        { text: 'Invitation To', value: 'invitation', align: 'center' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    listMeeting() {
      return this.$store.getters['auth/getListMeeting']
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchMeeting()
    this.loading = false
  },
  methods: {
    async fetchMeeting() {
      await this.$store.dispatch('auth/getMeeting', {
        subId: this.user.sub_id,
        token: this.user.token,
      })
    },
    async saveMeeting() {
      await this.$store.dispatch('auth/insertNewMeeting', {
        subId: this.user.sub_id,
        token: this.user.token,
        title: this.title,
        date: this.datePicker,
        time: this.timePicker,
        reminderTime: this.isReminder ? this.reminderMinutes : 0,
      })
      await this.fetchMeeting()
    },
    openNewMeeting() {
      this.title = ''
      this.datePicker = ''
      this.timePicker = ''
      this.dialog.newMeeting = true
      this.isReminder = false
      this.reminderMinutes = 0
    },
    sendMeeting(item) {
      const payload = {
        title: item.title,
        date: item.date,
        time: item.time,
        meetingId: item._id,
        titleTrim: item.title.replace(/\s+/g, ''),
      }

      this.$emit('closeModal', payload)
      this.dialog.meeting = false
    },
  },
}
</script>

<style></style>
