<template>
  <div
    class="vac-card-window"
    :style="[{ height }, cssVars]"
  >
    <div
      :id="containerId"
      class="vac-chat-container"
    >
      <!-- <pre style="display:none">{{cssVars}}</pre> -->
      <rooms-list
        v-if="!singleRoom"
        :current-user-id="currentUserId"
        :emty-room-data="emtyRoomData"
        :rooms="orderedRooms"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :room="room"
        :rooms-origin="roomsOrigin"
        :rooms-grouped="roomsGrouped"
        :rooms-messages="roomsMessages"
        :rooms-name="roomsName"
        :room-actions="roomActions"
        :current-offset="currentOffset"
        :text-messages="t"
        :options-instances="optionsInstances"
        :show-search="showSearch"
        :show-add-room="showAddRoom"
        :show-filter-room="showFilterRoom"
        :show-rooms-list="showRoomsList"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        :is-mobile="isMobile"
        :all-contacts="allContacts"
        :all-contacts-firestore="allContactsFirestore"
        @fetch-room="fetchRoom"
        @fetch-rooms="fetchRooms"
        @fetch-more-rooms="fetchMoreRooms"
        @loading-more-rooms="loadingMoreRooms = $event"
        @add-room="addRoom"
        @filter-room="filterRoom"
        @room-action-handler="roomActionHandler"
        @change-rooms-loaded="changeRoomsLoaded"
        @filter-new-contacts="filterNewContacts"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </rooms-list>
      <pre style="display: none">{{ themeWindow }}</pre>
      <room
        :index-page-room="inboxPage"
        :current-user-id="currentUserId"
        :rooms="orderedRooms"
        :rooms-origin="roomsOrigin"
        :room-id="roomId ? roomId : ''"
        :load-first-room="loadFirstRoom"
        :emty-room-data="emtyRoomData"
        :rooms-grouped="roomsGrouped"
        :rooms-messages="roomsMessages"
        :messages="messages"
        :room-message="roomMessage"
        :messages-loaded="messagesLoaded"
        :menu-actions="menuActions"
        :message-actions="messageActions"
        :show-send-icon="showSendIcon"
        :show-files="showFiles"
        :show-audio="showAudio"
        :audio-bit-rate="audioBitRate"
        :audio-sample-rate="audioSampleRate"
        :show-emojis="showEmojis"
        :show-reaction-emojis="showReactionEmojis"
        :show-new-messages-divider="showNewMessagesDivider"
        :show-footer="showFooter"
        :text-messages="t"
        :single-room="singleRoom"
        :show-rooms-list="showRoomsList"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        :is-mobile="isMobile"
        :loading-rooms="loadingRooms"
        :room-info-enabled="roomInfoEnabled"
        :textarea-action-enabled="textareaActionEnabled"
        :accepted-files="acceptedFiles"
        :templates-text="templatesText"
        :quick-replies="quickReplies"
        :status-instances="statusInstances"
        :current-action="currentAction"
        :is-uploading-attachment="isUploadingAttachment"
        :show-checkbox="showCheckbox"
        :hide-messages-started="hideMessagesStarted"
        :assign-to-user-list="assignToUserList"
        :replies-history="repliesHistory"
        :load-send-message="loadSendMessage"
        :all-contacts="allContacts"
        :all-contacts-firestore="allContactsFirestore"
        @toggle-rooms-list="toggleRoomsList"
        @room-info="roomInfo"
        @fetch-messages="fetchMessages"
        @send-message="sendMessage"
        @send-message-eshop="sendMessageEshop"
        @send-message-meeting="sendMessageMeeting"
        @edit-message="editMessage"
        @delete-message="deleteMessage"
        @open-file="openFile"
        @open-user-tag="fetchRoom"
        @change-pinned="$emit('change-pinned')"
        @change-operator="$emit('change-operator', $event)"
        @menu-action-handler="menuActionHandler"
        @message-action-handler="messageActionHandler"
        @send-message-reaction="sendMessageReaction"
        @typing-message="typingMessage"
        @textarea-action-handler="textareaActionHandler"
        @select-messages-action-handler="selectMessagesActionHandler"
        @update-room="updateRoom"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </room>
    </div>
    <transition
      name="vac-fade-preview"
      appear
    >
      <media-preview
        v-if="showMediaPreview"
        :file="previewFile"
        @close-media-preview="showMediaPreview = false"
      />
    </transition>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import RoomsList from './RoomsList/RoomsList'
import Room from './Room/Room'
import MediaPreview from './MediaPreview/MediaPreview'
import locales from '../locales'
import { defaultThemeStyles, cssThemeVars } from '../themes'

const { roomsValidation, partcipantsValidation } = require('../utils/data-validation')

export default {
  name: 'ChatContainer',
  components: {
    RoomsList,
    Room,
    MediaPreview,
  },
  props: {
    emtyRoomData: { type: Array, default: () => [] },
    height: { type: String, default: '600px' },
    theme: { type: String, default: 'light' },
    styles: { type: Object, default: () => ({}) },
    responsiveBreakpoint: { type: Number, default: 900 },
    singleRoom: { type: Boolean, default: false },
    roomsListOpened: { type: Boolean, default: true },
    textMessages: { type: Object, default: null },
    currentUserId: { type: [String, Number], default: '' },
    rooms: { type: Array, default: () => [] },
    optionsInstances: { type: Array, default: () => [] },
    roomsOrigin: { type: Array, default: () => [] },
    roomsGrouped: { type: Array, default: () => [] },
    roomsMessages: { type: Array, default: () => [] },
    roomsName: { type: Array, default: () => [] },
    roomsOrder: { type: String, default: 'desc' },
    currentOffset: { type: Number, default: 1 },
    loadingRooms: { type: Boolean, default: false },
    roomsLoaded: { type: Boolean, default: false },
    roomId: { type: [String, Number], default: null },
    loadFirstRoom: { type: Boolean, default: true },
    messages: { type: Array, default: () => [] },
    messagesLoaded: { type: Boolean, default: false },
    roomActions: { type: Array, default: () => [] },
    menuActions: { type: Array, default: () => [] },
    messageActions: {
      type: Array,
      default: () => [
        { name: 'replyMessage', title: 'Reply' },
        { name: 'editMessage', title: 'Edit message', onlyMe: true },
        { name: 'deleteMessage', title: 'Delete message', onlyMe: true },
      ],
    },
    showSearch: { type: Boolean, default: true },
    showAddRoom: { type: Boolean, default: true },
    showFilterRoom: { type: Boolean, default: true },
    showSendIcon: { type: Boolean, default: true },
    showFiles: { type: Boolean, default: true },
    showAudio: { type: Boolean, default: true },
    audioBitRate: { type: Number, default: 128 },
    audioSampleRate: { type: Number, default: 44100 },
    showEmojis: { type: Boolean, default: true },
    showReactionEmojis: { type: Boolean, default: true },
    showNewMessagesDivider: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    textFormatting: { type: Boolean, default: true },
    linkOptions: {
      type: Object,
      default: () => ({ disabled: false, target: '_blank', rel: null }),
    },
    roomInfoEnabled: { type: Boolean, default: false },
    textareaActionEnabled: { type: Boolean, default: false },
    roomMessage: { type: String, default: '' },
    acceptedFiles: { type: String, default: '*' },
    templatesText: { type: Array, default: null },
    mediaPreviewEnabled: { type: Boolean, default: true },
    quickReplies: { type: Array, default: null },
    statusInstances: { type: Array, default: () => [] },
    currentAction: { type: String, default: null },
    isUploadingAttachment: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: false },
    inboxPage: { type: Boolean, default: false },
    hideMessagesStarted: { type: Boolean, default: false },
    assignToUserList: { type: Array, default: () => [] },
    repliesHistory: { type: Boolean, default: false },
    loadSendMessage: { type: Boolean, default: false },
    allContacts: { type: Array, default: () => [] },
    allContactsFirestore: { type: Array, default: () => [] },
  },

  emits: [
    'toggle-rooms-list',
    'room-info',
    'fetch-messages',
    'fetch-rooms',
    'send-message',
    'send-message-eshop',
    'send-message-meeting',
    'edit-message',
    'change-pinned',
    'change-operator',
    'delete-message',
    'open-file',
    'open-user-tag',
    'menu-action-handler',
    'message-action-handler',
    'send-message-reaction',
    'typing-message',
    'textarea-action-handler',
    'fetch-more-rooms',
    'add-room',
    'room-action-handler',
    'select-messages-action-handler',
    'update-room',
    'filter-new-contacts',
    'change-rooms-loaded',
    'open-room-notif',
  ],

  data() {
    return {
      room: {},
      loadingMoreRooms: false,
      showRoomsList: true,
      isMobile: false,
      showMediaPreview: false,
      previewFile: {},
      sort: 'newest',
    }
  },

  computed: {
    themeWindow() {
      return this.$vuetify.theme.isDark
    },
    getLabelFilter() {
      return this.$store.getters['inbox/getLabelFilter']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    t() {
      return {
        ...locales,
        ...this.textMessages,
      }
    },
    cssVars() {
      if (this.themeWindow) {
        this.theme = 'dark'
      } else {
        this.theme = 'light'
      }
      const defaultStyles = defaultThemeStyles[this.theme]
      const customStyles = {}

      Object.keys(defaultStyles).map(key => {
        customStyles[key] = {
          ...defaultStyles[key],
          ...(this.styles[key] || {}),
        }
      })

      return cssThemeVars(customStyles)
    },
    orderedRooms() {
      const sorting = this.sort === 'newest' ? 'desc' : 'asc'
      const orderedRooms = _orderBy(this.rooms.slice(), ['pinned', 'index'], ['desc', sorting])
      //  const orderedRoomsMessages = _orderBy(this.roomsMessages.slice(), ['pinned', 'index'], ['desc', sorting])
      //   const orderedRoomsGrouped = _orderBy(this.roomsGrouped.slice(), ['pinned', 'index'], ['desc', sorting])
      return orderedRooms

      // return this.rooms.slice().sort((a, b) => {
      //   const aVal = a.index || 0
      //   const bVal = b.index || 0

      //   if (this.roomsOrder === 'asc') {
      //     return aVal < bVal ? -1 : bVal < aVal ? 1 : 0
      //   }

      //   return aVal > bVal ? -1 : bVal > aVal ? 1 : 0
      // })
    },
    getSidebarLeft() {
      return this.$store.getters['inbox/getSidebarLeft']
    },
    getBannerAlert() {
      return this.$store.getters['inbox/getBannerAlert']
    },
    getSidebarRight() {
      return this.$store.getters['inbox/getSidebarRight']
    },
    containerId() {
      if (this.inboxPage && !this.singleRoom) {
        return 'fixing-room-chat-windows'
      }

      if (this.inboxPage && this.singleRoom) {
        return 'fixing-single-room-chat-windows'
      }

      return ''
    },
    allRooms() {
      //console.log('computed filtered rooms is invoked')

      return this.$store.state.rooms.allRooms
    },
  },

  watch: {
    rooms: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // //console.log("roomsFromPg:3",newVal)
        const doc = document.getElementById(this.containerId)
        if (Object.keys(this.room).length > 0 && doc) {
          doc.classList.add('active')
        }
        if (!newVal[0] || !newVal.find(room => room.roomId === this.room.roomId)) {
          this.showRoomsList = true
        }

        // //console.log(
        //   '🚀 ~ file: ChatWindow.vue ~ line 371 ~ handler ~ !this.loadingMoreRooms && newVal[0] && (!oldVal || newVal.length !== oldVal.length)',
        //   newVal[0] && (!oldVal || newVal.length !== oldVal.length),
        // )
        if (newVal[0] && (!oldVal || newVal.length !== oldVal.length)) {
          if (this.roomId) {
            const room = newVal.find(r => r.roomId === this.roomId)
            const roomsMessages = this.roomsMessages.find(roomMsg => roomMsg.roomId === this.roomId)
            const roomsGrouped = this.roomsGrouped.find(roomMsg => roomMsg.roomId === this.roomId)

            if (roomsMessages) {
              this.fetchRoom({ roomsMessages }, true)
            }
            if (room) {
              this.fetchRoom({ room }, false)
            }
            if (roomsGrouped) {
              this.fetchRoom({ roomsGrouped }, false, false, true)
            }
          } else if (!this.isMobile || this.singleRoom) {
            // //console.log(this.orderedRooms, `INI ORDEREDROOMS`)
            // this.fetchRoom({ room: this.orderedRooms[0] }, false)
          } else {
            this.showRoomsList = true
          }
        }
      },
    },
    loadingRooms(val) {
      // if (val) this.room = {}
    },
    roomId: {
      immediate: true,
      handler(newVal, oldVal) {
        // if (newVal && !this.loadingRooms && this.rooms.length) {
        //   const room = this.rooms.find(r => r.roomId === newVal)
        //   const roomsMessages = this.roomsMessages.find(roomMsg => roomMsg.roomId === newVal)
        //   if (roomsMessages) {
        //     this.fetchRoom({ roomsMessages }, true)
        //   } else {
        //     this.fetchRoom({ room }, false)
        //   }
        //   // //console.log(true, `MASUK ROOM ID`)
        //   // this.fetchRoom({ room }, false)
        // } else if (oldVal && !newVal) {
        //   this.room = {}
        // }
      },
    },
    room(val) {
      if (!val || Object.entries(val).length === 0) return

      // //console.log('ini val', val)
      // roomsValidation(val)
      // val.users.forEach(user => {
      //   partcipantsValidation(user)
      // })
    },

    roomsListOpened(val) {
      this.showRoomsList = val
    },

    showRoomsList(val) {
      // //console.log(`INI VAL`, val)
      this.$store.commit('inbox/setShowRoomsList', val)
    },

    //
    getSidebarLeft(data) {
      const doc = document.getElementById(this.containerId)

      if (data) {
        doc.classList.add('sidebar-left')
      } else {
        doc.classList.remove('sidebar-left')
      }
    },
    getBannerAlert(data) {
      const doc = document.getElementById(this.containerId)

      // if (data === true) {
      //   doc.classList.add('active-banner')
      // }
    },
    getSidebarRight(data) {
      const doc = document.getElementById(this.containerId)

      if (data) {
        doc.classList.add('active')
      } else {
        doc.classList.remove('active')
      }
    },
  },

  mounted() {
    const doc = document.getElementById(this.containerId)
    const data = this.$store.getters['inbox/getSidebarLeft']
    const data2 = this.$store.getters['inbox/getBannerAlert']
    const data3 = this.$store.getters['inbox/getSidebarRight']
    if (doc) {
      if (data) {
        doc.classList.add('sidebar-left')
      } else {
        doc.classList.remove('sidebar-left')
      }

      // if (data2 === true) {
      //   doc.classList.add('active-banner')
      // }
      if (data3) {
        doc.classList.add('active')
      } else {
        doc.classList.remove('active')
      }
    }
    //console.log(this.rooms, 'data');
  },

  created() {
    this.updateResponsive()
    window.addEventListener('resize', ev => {
      if (ev.isTrusted) this.updateResponsive()
    })
  },

  methods: {
    getContactsName(phoneNumber) {
      const foundContact = this.allContacts.find(x => x.phone_number === phoneNumber)

      // //console.log(foundContact, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

      if (foundContact) {
        return foundContact.name
      }

      return phoneNumber
    },
    fetchRooms({ currentFilter, currentSearch, labelFilter, filterChat, getValueChannel }) {
      this.sort = currentFilter.sort || 'newest'
      this.$emit('fetch-rooms', {
        currentFilter,
        currentSearch,
        labelFilter,
        filterChat,
        getValueChannel,
      })
    },
    updateResponsive() {
      this.isMobile = window.innerWidth < this.responsiveBreakpoint
    },
    toggleRoomsList() {
      this.showRoomsList = !this.showRoomsList

      if (this.isMobile) {
        this.room = {}

        history.replaceState({}, null, '/inbox')
        history.pushState(null, document.title, location.href)

        window.addEventListener('popstate', function (event) {
          history.pushState(null, document.title, location.href)
        })
      }

      this.$emit('toggle-rooms-list', { opened: this.showRoomsList })
    },
    async fetchRoom({ room }, fromMsg, isFromClickedNameTag = false, fromContact = false) {
      //console.log(
      //   '🚀 ~ file: ChatWindow.vue ~ line 544 ~ fetchRoom ~ { room }, fromMsg, isFromClickedNameTag = false, fromContact = false',
      //   { room },
      //   fromMsg,
      //   isFromClickedNameTag,
      //   fromContact,
      // )


      if (room !== undefined) {
        if (isFromClickedNameTag) {
          const clickedFilteredRooms = this.rooms.filter(ele => {
            // //console.log(e, room, e.phone_number == room._id)
            return ele.roomId == room.roomId
          })
          if(clickedFilteredRooms.length === 0) {
            const oneRoomFromPg = await this.$store.dispatch('rooms/getOneRoomsPostgres', {
              sub_id: this.user.sub_id,
              roomId: room.roomId
            })
            //console.log(oneRoomFromPg, 'ini room1')
            if(!oneRoomFromPg.data || oneRoomFromPg.data.lastMessage === null) {
              //console.log(room.roomId, 'INI ROOM CUK')
              let instanceId = room.roomId.split('-')[0]
              let phoneNumber = room.roomId.split('-')[1]
              this.$store.commit('global/setMentionChatPayload', {
                status:true,
                phone: phoneNumber,
                instance_id: instanceId,
              })
              } else {
              this.room = oneRoomFromPg.data
              this.fetchMessages({ reset: true, fromMsg })
              //console.log(this.room, 'INI DIA ROOM BRO')
            }
          } else {
            this.room = clickedFilteredRooms[0]
            //console.log(this.room, 'INI DIA ROOMH')
            this.fetchMessages({ reset: true, fromMsg })
            // if (this.isMobile) this.showRoomsList = false
          }

          return
        }
        if (fromContact) {
          this.rooms.push(room)
        }

        if (room) {
          this.room = room
          //console.log(room ,this.room , "DARI CHAT WINDOW")
          this.fetchMessages({ reset: true, fromMsg })
          if (this.isMobile) this.showRoomsList = false
        } 
      }
      // //console.log(fromMsg)
    },
    formatPhoneNumber(phoneNumber, group = false) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    fetchMoreRooms() {
      this.$emit('fetch-more-rooms')
    },
    changeRoomsLoaded(val) {
      this.$emit('change-rooms-loaded', val)
    },
    filterNewContacts(val) {
      this.$emit('filter-new-contacts', val)
    },
    roomInfo() {
      this.$emit('room-info', this.room)
    },
    addRoom() {
      this.$emit('add-room')
    },
    filterRoom() {
      this.$emit('filter-room')
    },
    fetchMessages(options) {
      this.$emit('fetch-messages', { room: this.room, options })
    },
    fetchMessagesSearch(room, options, newest) {
      this.$emit('fetch-messages-search', room, options, newest)
    },
    sendMessage(message) {
      this.$emit('send-message', { ...message, roomId: this.room.roomId })
    },
    sendMessageEshop(message) {
      this.$emit('send-message-eshop', message)
    },
    sendMessageMeeting(message) {
      this.$emit('send-message-meeting', message)
    },
    editMessage(message) {
      this.$emit('edit-message', { ...message, roomId: this.room.roomId })
    },
    deleteMessage(message) {
      this.$emit('delete-message', { message, roomId: this.room.roomId })
    },
    openFile({ message, file }) {
      if (this.mediaPreviewEnabled && file.action === 'preview') {
        this.previewFile = file.file
        this.showMediaPreview = true
      } else {
        this.$emit('open-file', { message, file })
      }
    },
    openUserTag({ user }) {
      this.$emit('open-user-tag', { user })
    },
    menuActionHandler(ev) {
      this.$emit('menu-action-handler', {
        action: ev,
        roomId: this.room.roomId,
      })
    },
    roomActionHandler({ action, roomId }) {
      this.$emit('room-action-handler', {
        action,
        roomId,
      })
    },
    messageActionHandler(ev) {
      this.$emit('message-action-handler', {
        ...ev,
        roomId: this.room.roomId,
      })
    },
    sendMessageReaction(messageReaction) {
      this.$emit('send-message-reaction', {
        ...messageReaction,
        roomId: this.room.roomId,
      })
    },
    typingMessage(message) {
      // //console.log(message, `NI MESSAGE`)
      this.$emit(
        'typing-message',
        message,

        // {
        //   message,
        //   roomId: this.room.roomId,
        // }
      )
    },
    textareaActionHandler(message) {
      this.$emit('textarea-action-handler', {
        message,
        roomId: this.room.roomId,
      })
    },
    selectMessagesActionHandler(ev) {
      this.$emit('select-messages-action-handler', {
        ...ev,
        roomId: this.room.roomId,
      })
    },
    updateRoom(ev) {
      this.$emit('update-room', ev)
    },
    openRoomNotif(ev) {
      this.$emit('open-room-notif', ev)
    },
  },
}
</script>

<style lang="scss">
@import '../styles/index.scss';
</style>
<style lang="scss" scoped>
#fixing-room-chat-windows {
  position: fixed !important;

  @include screen('0px') {
    width: 100% !important;
  }
  @include screen('md') {
    position: fixed !important;
    //height: 100% !important;
    //min-height: 500px !important;
    height: calc(100% - 78px) !important;
    width: calc(100%) !important;
    //bottom: 0;
    &.active-banner {
      height: calc(100% - 133px) !important;
    }
    &.active {
      //width: 80% !important;
      //height: 100% !important;
      height: calc(100vh - 78px) !important;

      width: 100% !important;
      position: absolute !important;
      &.active-banner {
        //height: calc(100% - 55px) !important;
        height: calc(100vh - 133px) !important;
      }
      &.sidebar-left {
        width: calc(100%) !important;
      }
    }
    &.sidebar-left {
      width: calc(100% - 65px) !important;
    }
  }
  @include screen('xl') {
    position: fixed !important;
    //height: 100% !important;
    //min-height: 500px !important;
    height: calc(100% - 78px) !important;
    width: calc(100% - 260px) !important;
    //bottom: 0;
    &.active-banner {
      height: calc(100% - 133px) !important;
    }
    &.active {
      //width: 80% !important;
      //height: 100% !important;
      height: calc(100vh - 78px) !important;

      width: 100% !important;
      position: absolute !important;
      &.active-banner {
        //height: calc(100% - 55px) !important;
        height: calc(100vh - 133px) !important;
      }
      &.sidebar-left {
        width: calc(100%) !important;
      }
    }
    &.sidebar-left {
      width: calc(100% - 65px) !important;
    }
  }
}

#fixing-single-room-chat-windows {
  position: fixed !important;

  @include screen('0px') {
    width: 100% !important;
  }
  @include screen('md') {
    position: fixed !important;
    //height: 100% !important;
    //min-height: 500px !important;
    height: calc(100% - 78px) !important;
    width: calc(100%) !important;
    //bottom: 0;
    &.active-banner {
      height: calc(100% - 133px) !important;
    }
    &.active {
      //width: 80% !important;
      //height: 100% !important;
      height: calc(100vh - 78px) !important;

      width: 100% !important;
      position: absolute !important;
      &.active-banner {
        //height: calc(100% - 55px) !important;
        height: calc(100vh - 133px) !important;
      }
      &.sidebar-left {
        width: calc(100%) !important;
      }
    }
    &.sidebar-left {
      width: calc(100% - 65px) !important;
    }
  }
  @include screen('xl') {
    position: fixed !important;
    //height: 100% !important;
    //min-height: 500px !important;
    height: calc(100% - 42px) !important;
    width: calc(100% - 23px) !important;
    //bottom: 0;
    &.active-banner {
      height: calc(100% - 133px) !important;
    }
    &.active {
      //width: 80% !important;
      //height: 100% !important;
      height: calc(100vh - 42px) !important;

      width: 100% !important;
      position: absolute !important;
      &.active-banner {
        //height: calc(100% - 55px) !important;
        height: calc(100vh - 133px) !important;
      }
      &.sidebar-left {
        width: calc(100%) !important;
      }
    }
    &.sidebar-left {
      width: calc(100% - 65px) !important;
    }
  }
}
</style>
