import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  endAt,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore'
import { firestoreDb } from './index'

// const USERS_PATH = 'users-hh156c'
// const ROOMS_PATH = 'chatRooms-hh156c'
const MESSAGES_PATH = 'messages'
const MESSAGE_PATH = (roomId, ROOMS_PATH = 'chatRooms') => {
  return `${ROOMS_PATH}/${roomId}/${MESSAGES_PATH}`
}

const TIMESTAMP_FIELD = 'timestamp'
const LAST_UPDATED_FIELD = 'lastUpdated'
const TYPING_USERS_FIELD = 'typingUsers'
const MESSAGE_REACTIONS_FIELD = 'reactions'
const ROOM_USERS_FIELD = 'users'
const ASSIGN_TO_FIELD = 'assign_to'
const PINNED_FIELD = 'pinned'

export const firestoreListener = onSnapshot
export const deleteDbField = deleteField()

const getDocuments = query => {
  return getDocs(query)
}

const getDocument = ref => {
  return getDoc(ref)
}

const addDocument = (ref, data) => {
  return addDoc(ref, data)
}

const setDocument = (path, docId, data) => {
  return setDoc(doc(firestoreDb, path, docId), data)
}

const updateDocument = (ref, data) => {
  return updateDoc(ref, data)
}

const deleteDocument = (ref, docId) => {
  return deleteDoc(doc(firestoreDb, ref, docId))
}

// USERS
// const usersRef = collection(firestoreDb, USERS_PATH)

const userRef = (userId, USERS_PATH = 'users') => {
  return doc(firestoreDb, USERS_PATH, userId)
}

export const getAllUsers = (USERS_PATH = 'users') => {
  const usersRef = collection(firestoreDb, USERS_PATH)

  return getDocuments(query(usersRef))
}

export const getUser = (userId, USERS_PATH = 'users') => {
  return getDocument(userRef(userId, USERS_PATH))
}

export const addUser = (data, USERS_PATH = 'users') => {
  const usersRef = collection(firestoreDb, USERS_PATH)

  return addDocument(usersRef, data)
}

export const addIdentifiedUser = (userId, data, USERS_PATH = 'users') => {
  return setDocument(USERS_PATH, userId, data)
}

export const updateUser = (userId, data, USERS_PATH = 'users') => {
  return updateDocument(userRef(userId, USERS_PATH), data)
}

export const deleteUser = (userId, USERS_PATH = 'users') => {
  return deleteDocument(USERS_PATH, userId)
}

// ROOMS
// const roomsRef = collection(firestoreDb, ROOMS_PATH)

const roomRef = (roomId, ROOMS_PATH = 'chatRooms') => {
  return doc(firestoreDb, ROOMS_PATH, roomId)
}

export const roomsQuery = (currentUserId, roomsPerPage, lastRoom, ROOMS_PATH = 'chatRooms', filter = 'all') => {
  const roomsRef = collection(firestoreDb, ROOMS_PATH)

  switch (currentUserId) {
    case 'all':
      if (lastRoom) {
        if (filter == 'assigned') {
          return query(
            roomsRef,
            where('assign_to', '!=', null),
            where('archived', '==', false),
            orderBy(ASSIGN_TO_FIELD, 'asc'),

            // orderBy(PINNED_FIELD, 'desc'),
            orderBy(LAST_UPDATED_FIELD, 'desc'),
            limit(roomsPerPage),
            startAfter(lastRoom),
          )
        }

        if (filter == 'unreplied') {
          return query(
            roomsRef,
            where('unreplied', '==', true),
            where('archived', '==', false),

            // orderBy(PINNED_FIELD, 'desc'),
            orderBy(LAST_UPDATED_FIELD, 'desc'),
            limit(roomsPerPage),
            startAfter(lastRoom),
          )
        }

        if (filter == 'archived') {
          return query(
            roomsRef,
            where('archived', '==', true),

            // orderBy(PINNED_FIELD, 'desc'),
            orderBy(LAST_UPDATED_FIELD, 'desc'),
            limit(roomsPerPage),
            startAfter(lastRoom),
          )
        }

        return query(
          roomsRef,

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
          startAfter(lastRoom),
        )
      }

      if (filter == 'assigned') {
        //console.log(filter)
        //console.log('assigner amr')

        return query(
          roomsRef,
          where('assign_to', '!=', null),
          where('archived', '==', false),
          orderBy(ASSIGN_TO_FIELD, 'desc'),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
        )
      }

      if (filter == 'unreplied') {
        return query(
          roomsRef,
          where('unreplied', '==', true),
          where('archived', '==', false),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
        )
      }

      if (filter == 'archived') {
        return query(
          roomsRef,
          where('archived', '==', true),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
        )
      }

      return query(
        roomsRef,

        // orderBy(PINNED_FIELD, 'desc'),
        orderBy(LAST_UPDATED_FIELD, 'desc'),
        limit(roomsPerPage),
      )

    default:
      if (lastRoom) {
        if (filter == 'assigned') {
          return query(
            roomsRef,
            where('instanceId', '==', currentUserId),
            where('assign_to', '!=', null),
            where('archived', '==', false),
            orderBy(ASSIGN_TO_FIELD, 'asc'),

            // orderBy(PINNED_FIELD, 'desc'),
            orderBy(LAST_UPDATED_FIELD, 'desc'),
            limit(roomsPerPage),
            startAfter(lastRoom),
          )
        }

        if (filter == 'unreplied') {
          return query(
            roomsRef,
            where('instanceId', '==', currentUserId),
            where('unreplied', '==', true),
            where('archived', '==', false),

            // orderBy(PINNED_FIELD, 'desc'),
            orderBy(LAST_UPDATED_FIELD, 'desc'),
            limit(roomsPerPage),
            startAfter(lastRoom),
          )
        }

        if (filter == 'archived') {
          return query(
            roomsRef,
            where('instanceId', '==', currentUserId),
            where('archived', '==', true),

            // orderBy(PINNED_FIELD, 'desc'),
            orderBy(LAST_UPDATED_FIELD, 'desc'),
            limit(roomsPerPage),
            startAfter(lastRoom),
          )
        }

        return query(
          roomsRef,
          where('instanceId', '==', currentUserId),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
          startAfter(lastRoom),
        )
      }

      if (filter == 'assigned') {
        return query(
          roomsRef,
          where('instanceId', '==', currentUserId),
          where('assign_to', '!=', null),
          where('archived', '==', false),
          orderBy(ASSIGN_TO_FIELD, 'asc'),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
        )
      }

      if (filter == 'unreplied') {
        return query(
          roomsRef,
          where('instanceId', '==', currentUserId),
          where('unreplied', '==', true),
          where('archived', '==', false),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
        )
      }

      if (filter == 'archived') {
        return query(
          roomsRef,
          where('instanceId', '==', currentUserId),
          where('archived', '==', true),

          // orderBy(PINNED_FIELD, 'desc'),
          orderBy(LAST_UPDATED_FIELD, 'desc'),
          limit(roomsPerPage),
        )
      }

      return query(
        roomsRef,
        where('instanceId', '==', currentUserId),

        // orderBy(PINNED_FIELD, 'desc'),
        orderBy(LAST_UPDATED_FIELD, 'desc'),
        limit(roomsPerPage),
      )
  }
}

export const getAllRooms = (ROOMS_PATH = 'chatRooms') => {
  const roomsRef = collection(firestoreDb, ROOMS_PATH)

  return getDocuments(query(roomsRef))
}

export const getRooms = query => {
  return getDocuments(query)
}

export const addRoom = (data, ROOMS_PATH = 'chatRooms') => {
  const roomsRef = collection(firestoreDb, ROOMS_PATH)

  return addDocument(roomsRef, data)
}

export const updateRoom = (roomId, data, ROOMS_PATH = 'chatRooms') => {
  return updateDocument(roomRef(roomId, ROOMS_PATH), data)
}

export const deleteRoom = (roomId, ROOMS_PATH = 'chatRooms') => {
  return deleteDocument(ROOMS_PATH, roomId)
}

export const getUserRooms = (currentUserId, userId, ROOMS_PATH = 'chatRooms') => {
  const roomsRef = collection(firestoreDb, ROOMS_PATH)

  return getDocuments(query(roomsRef, where(ROOM_USERS_FIELD, '==', [currentUserId, userId])))
}

export const addRoomUser = (roomId, userId, ROOMS_PATH = 'chatRooms') => {
  return updateRoom(
    roomId,
    {
      [ROOM_USERS_FIELD]: arrayUnion(userId),
    },
    ROOMS_PATH,
  )
}

export const removeRoomUser = (roomId, userId, ROOMS_PATH = 'chatRooms') => {
  return updateRoom(
    roomId,
    {
      [ROOM_USERS_FIELD]: arrayRemove(userId),
    },
    ROOMS_PATH,
  )
}

export const updateRoomTypingUsers = (roomId, currentUserId, action, ROOMS_PATH = 'chatRooms') => {
  const arrayUpdate = action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId)

  return updateRoom(roomId, { [TYPING_USERS_FIELD]: arrayUpdate }, ROOMS_PATH)
}

// MESSAGES
const messagesRef = (roomId, ROOMS_PATH = 'chatRooms') => {
  return collection(firestoreDb, MESSAGE_PATH(roomId, ROOMS_PATH))
}

const messageRef = (roomId, messageId, ROOMS_PATH = 'chatRooms') => {
  return doc(firestoreDb, MESSAGE_PATH(roomId, ROOMS_PATH), messageId)
}

export const getMessages = (roomId, messagesPerPage, lastLoadedMessage, ROOMS_PATH = 'chatRooms') => {
  if (lastLoadedMessage) {
    return getDocuments(
      query(
        messagesRef(roomId, ROOMS_PATH),
        orderBy(TIMESTAMP_FIELD, 'desc'),
        limit(messagesPerPage),
        startAfter(lastLoadedMessage),
      ),
    )
  }

  if (messagesPerPage) {
    return getDocuments(
      query(messagesRef(roomId, ROOMS_PATH), orderBy(TIMESTAMP_FIELD, 'desc'), limit(messagesPerPage)),
    )
  }

  return getDocuments(messagesRef(roomId, ROOMS_PATH))
}

export const getMessage = (roomId, messageId, ROOMS_PATH = 'chatRooms') => {
  return getDocument(messageRef(roomId, messageId, ROOMS_PATH))
}

export const addMessage = (roomId, data, ROOMS_PATH = 'chatRooms') => {
  return addDocument(messagesRef(roomId, ROOMS_PATH), data)
}

export const updateMessage = (roomId, messageId, data, ROOMS_PATH = 'chatRooms') => {
  return updateDocument(messageRef(roomId, messageId, ROOMS_PATH), data)
}

export const deleteMessage = (roomId, messageId, ROOMS_PATH = 'chatRooms') => {
  return deleteDocument(MESSAGE_PATH(roomId, ROOMS_PATH), messageId)
}

export const paginatedMessagesQuery = (
  roomId,
  lastLoadedMessage,
  previousLastLoadedMessage,
  ROOMS_PATH = 'chatRooms',
) => {
  if (lastLoadedMessage && previousLastLoadedMessage) {
    return query(
      messagesRef(roomId, ROOMS_PATH),
      orderBy(TIMESTAMP_FIELD),
      startAt(lastLoadedMessage),
      endAt(previousLastLoadedMessage),
    )
  }

  if (lastLoadedMessage) {
    return query(messagesRef(roomId, ROOMS_PATH), orderBy(TIMESTAMP_FIELD), startAt(lastLoadedMessage))
  }

  if (previousLastLoadedMessage) {
    return query(messagesRef(roomId, ROOMS_PATH), orderBy(TIMESTAMP_FIELD), endAt(previousLastLoadedMessage))
  }
  //console.log('test log fetch message query(messagesRef(roomId, ROOMS_PATH), orderBy(TIMESTAMP_FIELD), limit(1))')

  return query(messagesRef(roomId, ROOMS_PATH), orderBy(TIMESTAMP_FIELD, 'desc'), limit(1))
}

export const lastMessageQuery = (roomId, ROOMS_PATH = 'chatRooms') => {
  return query(messagesRef(roomId, ROOMS_PATH), orderBy(TIMESTAMP_FIELD, 'desc'), limit(1))
}

export const updateMessageReactions = (
  roomId,
  messageId,
  currentUserId,
  reactionUnicode,
  action,
  ROOMS_PATH = 'chatRooms',
) => {
  const arrayUpdate = action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId)

  return updateMessage(
    roomId,
    messageId,
    {
      [`${MESSAGE_REACTIONS_FIELD}.${reactionUnicode}`]: arrayUpdate,
    },
    ROOMS_PATH,
  )
}

export const getChannels = subId => {
  const channelsRef = collection(firestoreDb, `channels/${subId}/listChannel`)

  return getDocs(query(channelsRef))
}

export const getTest = () => {
  const channelsRef = collection(firestoreDb, 'chatRooms')

  return getDocs(query(channelsRef))
}
