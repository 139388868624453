<template>
  <v-dialog
    v-model="dialog.eshop"
    width="960px"
  >
    <v-card>
      <v-card-text>
        <v-select
          v-model="selectShop"
          item-text="data.name"
          :items="allShop"
          label="Select Shop"
          return-object
          outlined
          @change="showShopTable"
        ></v-select>
        <v-data-table
          v-if="filteredSheetData.length !== 0 && selectShop !== null"
          :headers="headers"
          :items="filteredSheetData"
          :items-per-page="5"
          :loading="loading"
          item-key="id"
          multi-sort
          class="text-no-wrap text-center"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-text': $t('rowsPerPageLabel'),
          }"
        >
          <template #[`item.option`]="{ item }">
            <v-btn
              small
              icon
              @click="sendProduct(item)"
            >
              <v-icon>{{ icons.mdiSend }}</v-icon>
            </v-btn>
          </template>
          <template #[`item.Image`]="{ item }">
            <img
              style="width: 50px; height: 50px"
              :src="item.Image"
            />
          </template>
          <template #[`item.Photo`]="{ item }">
            <img
              style="width: 50px; height: 50px"
              :src="item.Photo"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PouchDb from '@/mixins/pouchDbMixin'
import { mdiSend } from '@mdi/js'

export default {
  name: 'EshopListDialog',
  mixins: [PouchDb],

  data() {
    return {
      icons: {
        mdiSend,
      },
      listShopItem: [],
      selectShop: null,
      allShop: [],
      loading: false,
      dialog: {
        eshop: false,
      },
    }
  },

  computed: {
    filteredSheetData() {
      return this.sheetData.length !== 0
        ? JSON.parse(
            JSON.stringify(
              this.sheetData.value?.products.filter(el => {
                return el['Variant Of'] === null
              }),
            ),
          )
        : []
    },
    sheetData() {
      return this.$store.getters['eOrdering/getSheetData']
    },
    headers() {
      return [
        { text: 'Action', value: 'option', align: 'center' },
        { text: 'Name', value: 'Name', align: 'center' },

        { text: 'Description', value: 'Description', align: 'center' },
        { text: 'Photo', value: 'Photo', align: 'center' },

        { text: 'Base Price', value: 'Base Price', align: 'center' },

        { text: 'Out Of Stock', value: 'Out Of Stock', align: 'center' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  async mounted() {
    // this.allShop = await this.loadEordering(this.user)
  },
  methods: {
    async showShopTable() {
      this.loading = true
      this.listShopItem = await this.$store.dispatch('eOrdering/getSheetData', {
        sub_id: this.user.sub_id,
        excelId: this.selectShop.data.excelId,
      })
      this.loading = false
    },
    sendProduct(item) {
      const text = `*${item['Variant Of'] === null ? item.Name : `${item['Variant Of']} ${item.Name}`}*
Harga : Rp. ${item['Base Price'].toLocaleString('id')},00 -
Deskripsi : ${item.Description}`
      //console.log(item)
      const payload = {
        text,
        image: { url: item.Photo, type: 'image/jpeg', filename: 'images' },
      }

      this.$emit('closeModal', payload)
      this.dialog.eshop = false
      this.$store.commit('eOrdering/setSheetData', [])
      this.selectShop = null
    },
  },
}
</script>

<style>
</style>
