<template>
  <div
    class="vac-format-message-wrapper"
    :class="{ 'vac-text-ellipsis': singleLine }"
  >
    <div
      v-if="textFormatting"
      :class="{ 'vac-text-ellipsis': singleLine }"
    >
      <div v-if="linkifiedMessage.length == 0 && payloadMessage">
        <template
          v-if="
            payloadMessage && payloadMessage.data && payloadMessage.data.type && payloadMessage.data.type === 'image'
          "
        >
          <v-icon size="15px">
            {{ icons.mdiCamera }}
          </v-icon>
          {{ 'Image' }}
        </template>
        <template
          v-else-if="
            payloadMessage && payloadMessage.data && payloadMessage.data.type && payloadMessage.data.type === 'video'
          "
        >
          <v-icon size="15px">
            {{ icons.mdiVideo }}
          </v-icon>
          {{ 'Video' }}
        </template>
        <template
          v-else-if="
            payloadMessage && payloadMessage.data && payloadMessage.data.type && payloadMessage.data.type === 'document'
          "
        >
          <v-icon size="15px">
            {{ icons.mdiFileDocument }}
          </v-icon>
          {{ 'Document' }}
        </template>
        <template
          v-else-if="
            payloadMessage && payloadMessage.data && payloadMessage.data.type && payloadMessage.data.type === 'location'
          "
        >
          <v-icon size="15px">
            {{ icons.mdiMapMarker }}
          </v-icon>
          {{ 'Location' }}
        </template>
        <template
          v-else-if="
            payloadMessage && payloadMessage.data && payloadMessage.data.type && payloadMessage.data.type === 'vcard'
          "
        >
          <v-icon size="15px">
            {{ icons.mdiAccount }}
          </v-icon>
          {{ 'Contact' }}
        </template>
        <template
          v-else-if="
            payloadMessage && payloadMessage.data && payloadMessage.data.type && payloadMessage.data.type === 'sticker'
          "
        >
          {{ 'Sticker' }}
        </template>
        <template v-else>
          {{ payloadMessage.content }}
        </template>
      </div>
      <div v-else>
        <div
          v-for="(message, i) in linkifiedMessage"
          :key="i"
          class="vac-format-container"
        >
          <component
            :is="message.url ? 'a' : 'span'"
            :class="{
              'vac-text-ellipsis': singleLine,
              'vac-text-bold': message.bold,
              'vac-text-italic': deleted || message.italic,
              'vac-text-strike': message.strike,
              'vac-text-underline': message.underline,
              'vac-text-inline-code': !singleLine && message.inline,
              'vac-text-multiline-code': !singleLine && message.multiline,
              'vac-text-tag': !singleLine && !reply && message.tag,
            }"
            :href="message.href"
            :target="message.href ? linkOptions.target : null"
            :rel="message.href ? linkOptions.rel : null"
            @click="openTag(message)"
          >
            <slot
              name="deleted-icon"
              v-bind="{ deleted }"
            >
              <svg-icon
                v-if="deleted"
                name="deleted"
                class="vac-icon-deleted"
              />
            </slot>
            <template v-if="message.url && message.image">
              <!-- {{ 'INI URL ATAU IMAGE' }} -->
              <div class="vac-image-link-container">
                <div
                  class="vac-image-link"
                  :style="{
                    'background-image': `url('${message.value}')`,
                    height: message.height,
                  }"
                />
              </div>
              <div class="vac-image-link-message">
                <span :class="markUnrepliedBold ? 'vac-text-bold text--black' : ''">{{ message.value }}</span>
              </div>
            </template>
            <template v-else>
              <span :class="markUnrepliedBold ? 'vac-text-bold text--black' : ''">
                <template v-if="reply">
                  {{
                    message.value
                      ? message.value.length > 200
                        ? message.value.substring(0, 200) + '...'
                        : message.value
                      : ''
                  }}
                </template>
                <template v-else>
                  <template
                    v-if="
                      payloadMessage &&
                        payloadMessage.data &&
                        payloadMessage.data.type &&
                        payloadMessage.data.type === 'image'
                    "
                  >
                    <v-icon size="15px">{{ icons.mdiCamera }}</v-icon> {{ message.value }}
                  </template>
                  <template
                    v-else-if="
                      payloadMessage &&
                        payloadMessage.data &&
                        payloadMessage.data.type &&
                        payloadMessage.data.type === 'video'
                    "
                  >
                    <v-icon size="15px">{{ icons.mdiVideo }}</v-icon> {{ message.value }}
                  </template>
                  <template
                    v-else-if="
                      payloadMessage &&
                        payloadMessage.data &&
                        payloadMessage.data.type &&
                        payloadMessage.data.type === 'document'
                    "
                  >
                    <v-icon size="15px">{{ icons.mdiFileDocument }}</v-icon> {{ message.value }}
                  </template>
                  <template
                    v-else-if="
                      payloadMessage &&
                        payloadMessage.data &&
                        payloadMessage.data.type &&
                        payloadMessage.data.type === 'location'
                    "
                  >
                    <v-icon size="15px">{{ icons.mdiMapMarker }}</v-icon> {{ message.value }}
                  </template>
                  <template
                    v-else-if="
                      payloadMessage &&
                        payloadMessage.data &&
                        payloadMessage.data.type &&
                        payloadMessage.data.type === 'vcard'
                    "
                  >
                    <v-icon size="15px">{{ icons.mdiAccount }}</v-icon> {{ message.value }}
                  </template>
                  <template
                    v-else-if="
                      payloadMessage &&
                        payloadMessage.data &&
                        payloadMessage.data.type &&
                        payloadMessage.data.type === 'sticker'"
                  >
                    {{ 'Sticker' }}
                  </template>
                  <template v-else>
                    {{ message.value }}
                  </template>
                </template>
              </span>
            </template>
          </component>
        </div>
      </div>
    </div>
    <div v-else>
      {{ formattedContent }}
    </div>
  </div>
</template>

<script>
import utilsMixin from '@/mixins/utilsMixin'
import { mdiAccount, mdiCamera, mdiFileDocument, mdiMapMarker, mdiVideo } from '@mdi/js'
import { IMAGE_TYPES } from '../../utils/constants'
import formatString from '../../utils/format-string'
import SvgIcon from '../SvgIcon/SvgIcon'

export default {
  name: 'FormatMessage',

  components: { SvgIcon },

  mixins: [utilsMixin],

  setup() {
    return {
      icons: {
        mdiCamera,
        mdiVideo,
        mdiFileDocument,
        mdiMapMarker,
        mdiAccount,
      },
    }
  },

  props: {
    content: { type: [String, Number], required: true },
    deleted: { type: Boolean, default: false },
    users: { type: Array, default: () => [] },
    allContacts: { type: Array, default: () => [] },
    linkify: { type: Boolean, default: true },
    singleLine: { type: Boolean, default: false },
    reply: { type: Boolean, default: false },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    unreplied: { type: Boolean, default: false },
    payloadMessage: { type: Object, default: null },
  },

  emits: ['open-user-tag'],

  data() {
    return {
      userTampungan: [],
      userTampungan2: [],
    }
  },
  computed: {
    currentRoomContact() {
      return this.$store.getters['global/getCurrentRoom']
    },
    markUnrepliedBold() {
      // //console.log(this.unreplied, `INI UNREPLIED NYA`)

      return this.unreplied // && this.$store.getters['global/getBoldUnreplied']
    },
    linkifiedMessage() {
      const message = formatString(
        this.formatTags(this.content),
        this.linkify && !this.linkOptions.disabled,
        this.linkOptions,
      )

      message.forEach(m => {
        m.url = this.checkType(m, 'url')
        m.bold = this.checkType(m, 'bold')
        m.italic = this.checkType(m, 'italic')
        m.strike = this.checkType(m, 'strike')
        m.underline = this.checkType(m, 'underline')
        m.inline = this.checkType(m, 'inline-code')
        m.multiline = this.checkType(m, 'multiline-code')
        m.tag = this.checkType(m, 'tag')
        m.image = this.checkImageType(m)
      })

      return message
    },
    formattedContent() {
      return this.formatTags(this.content)
    },
  },
  methods: {
    checkType(message, type) {
      return message.types.indexOf(type) !== -1
    },
    checkImageType(message) {
      let index = message.value.lastIndexOf('.')
      const slashIndex = message.value.lastIndexOf('/')
      if (slashIndex > index) index = -1

      const type = message.value.substring(index + 1, message.value.length)

      const isMedia = index > 0 && IMAGE_TYPES.some(t => type.toLowerCase().includes(t))

      if (isMedia) this.setImageSize(message)

      return isMedia
    },
    setImageSize(message) {
      const image = new Image()
      image.src = message.value

      image.addEventListener('load', onLoad)

      function onLoad(img) {
        const ratio = img.path[0].width / 150
        message.height = `${Math.round(img.path[0].height / ratio)}px`
        image.removeEventListener('load', onLoad)
      }
    },
    formatTags(content) {
      if (!content) {
        content = ''
      }

      const firstTag = '<usertag>'
      const secondTag = '</usertag>'

      const usertags = [...content.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)

      const initialContent = content
      usertags.forEach(index => {
        const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

        // //console.log(userId, `INI USERTAGS`)

        const userData = this.users.find(userInfo => userInfo._id === userId)
        const user = this.$store.getters['global/getAllContactData'].find(
          user => user.phone_number == userId && user.notify_name !== userId,
        )
        const userContact = this.allContacts.find(contact => contact.phone_number === userId)

        // if (user._id.includes('5130')) {
        //   //console.log(true, this.currentRoomContact, user, user.username.replace(/[+ -]/g, ''), `INI NAMA KONTAK`)
        // }

        // //console.log(userId, userContact, `INI USER CONTACT`)

        // //console.log(userId, this.allContacts, `INI USER ID`)
        // //console.log(user, `INI USER`)
        // if (userId == '6282260567687') {
        //   //console.log(
        //     '🚀 ~ file: FormatMessage.vue ~ line 346 ~ formatTags ~ userContact',
        //     userContact,
        //     userContact.name == userId,
        //     user,
        //   )
        // }
        this.userTampungan.push(userData)
        if (userContact) {
          if (userContact.name == userId) {
            if (user) {
              if (user.bailey_name) {
                userContact.name = user.bailey_name
              } else if (user.notify_name) {
                userContact.name = user.notify_name
              } else if (user.contact_list_name) {
                userContact.name = user.contact_list_name
              } else {
                userContact.name = this.formatPhoneNumber(userId, false)
              }
            } else {
              userContact.name = this.formatPhoneNumber(userId, false)
            }

            // userContact.username = this.formatPhoneNumber(userId, false)
          } else if (userContact.name !== userId) {
            userContact.username = userContact.name
          }
          this.userTampungan2.push(userContact)

          content = content.replaceAll(userId, `@${userContact.name ? userContact.name : userId}`)
        } else if (user && user.bailey_name) {
          if (user._id == user.bailey_name) {
            user.bailey_name = this.formatPhoneNumber(user._id, false)
          }
          // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
          //   if(el.bailey_name) {
          //     el.bailey_name === user.bailey_name
          //   }
          //   }) < 0 )) {
          //   this.userTampungan.push(user)
          // }

          content = content.replaceAll(userId, `@${user.bailey_name ? user.bailey_name : user._id}`)
        } else if (user && user.notify_name) {
          if (user._id == user.notify_name) {
            user.notify_name = this.formatPhoneNumber(user._id, false)
          }
          // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
          //   if(el.notify_name) {
          //     el.notify_name === user.notify_name
          //   }
          //   }) < 0 )) {
          //   this.userTampungan.push(user)
          // }
          let test = 0
          if(this.userTampungan.length !== 0) {
            test = this.userTampungan.findIndex(el=> {
              if(el && el.notify_name) return el.notify_name === user.notify_name
            })
          }
          if(this.userTampungan.length === 0 || test < 0) {
            this.userTampungan.push(user)
          }
          content = content.replaceAll(userId, `@${user.notify_name ? user.notify_name : user._id}`)
        } else if (!user) {
          if (userData) {
            if (userData._id == userData.username.replace(/[+ -]/g, '')) {
              userData.username = this.formatPhoneNumber(userData._id, false)
            }
            content = content.replaceAll(userId, `@${userData.username ? userData.username : userData._id}`)
          }
        }
      })

      // //console.log(content, `INI KONTEN`)

      return content
    },
    openTag(message) {
      if (!this.singleLine && this.checkType(message, 'tag')) {
        const user = this.users.find(u => message.value.indexOf(u.username) !== -1)
        if(user) {
          //console.log(user, 'ini user opens')
          this.$emit('open-user-tag', user)
        } else {
          let phone
          phone = this.userTampungan2.find(user => {
            if(user && user.username) return user.username === message.value.slice(1)
          })
          if (!phone) {
            phone = this.userTampungan.find(el => {
              if(el && el.notify_name) return el.notify_name === message.value.slice(1)
            })
          }
          // this.userTampungan = []
          // //console.log(phone, 'PRODUK AKHIR')
          // let phone = this.userTampungan.find((el) => {
          //   if(el) {
          //     if(el.notify_name) {
          //       el.notify_name === message.value.slice(1)
          //     } else if (el.bailey_name) {
          //       el.bailey_name === message.value.slice(1)
          //     } else if(el.username) {
          //       el.username === message.value.slice(1)
          //     }
          //   }
          //     return el
          //   })
          let obj = {
            _id: phone.phone_number || phone._id
          }
          //console.log(obj, 'ini obj opens')
          this.$emit('open-user-tag', obj)
        }
      }
    },
  },
}
</script>
