/* eslint-disable */
const lokiAdapter = require('lokijs/src/loki-indexed-adapter.js')
const loki = require('lokijs')

var fsAdapter = new lokiAdapter('superwa')
const lokidb = new loki('superwa.db', {
  adapter: fsAdapter,
  autoload: true,
  autosave: true,
})

export default {
  data() {
    return {
      chatsLokiDb: null,
      contactsLokiDb: null,
    }
  },
  methods: {
    initializeDb() {
      this.contactsLokiDb = lokidb.getCollection('contacts')
      this.chatsLokiDb = lokidb.getCollection('chats')

      if (this.contactsLokiDb === null) {
        this.contactsLokiDb = lokidb.addCollection('contacts')
      }
      if (this.chatsLokiDb === null) {
        this.chatsLokiDb = lokidb.addCollection('chats')
      }
    },
    getContactsLoki() {
      if (this.contactsLokiDb == null) this.initializeDb()
      return this.contactsLokiDb.data
    },
    getChatsLoki() {
      if (this.chatsLokiDb == null) this.initializeDb()
      return this.chatsLokiDb.data
    },
    insertContactsLoki(remoteContacts) {
      if (this.contactsLokiDb == null) this.initializeDb()
      this.contactsLokiDb.insert(remoteContacts)
    },
    insertChatsLoki(remoteChats) {
      if (this.chatsLokiDb == null) this.initializeDb()
      this.chatsLokiDb.insert(remoteChats)
    },
    removeContactsLoki() {
      if (this.contactsLokiDb == null) this.initializeDb()
      this.contactsLokiDb.remove(this.contactsLokiDb.data)
    },
    removeChatsLoki() {
      if (this.chatsLokiDb == null) this.initializeDb()
      this.chatsLokiDb.remove(this.chatsLokiDb.data)
    },
    updateOrInsertContactsById(obj) {
      if (this.contactsLokiDb == null) this.initializeDb()
      const data = this.contactsLokiDb
        .chain()
        .find({ _id: { $eq: obj._id } })
        .data()
      if (data.length > 0) {
        this.contactsLokiDb.remove(data)
        this.contactsLokiDb.insert(obj)
      } else {
        this.contactsLokiDb.insert(obj)
      }
    },
    updateOrInsertChatsById(obj) {
      const idx = this.allChatsCouch.findIndex(x => x._id === obj._id)
      if (idx > -1) {
        this.allChatsCouch.splice(idx, 1)
      }
      this.allChatsCouch.push(obj)

      if (this.chatsLokiDb == null) this.initializeDb()
      const data = this.chatsLokiDb
        .chain()
        .find({ _id: { $eq: obj._id } })
        .data()
      if (data.length > 0) {
        this.chatsLokiDb.remove(data)
      }
      this.chatsLokiDb.insert(obj)
    },
  },
}
