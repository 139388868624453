<template>
  <div
    :class="{
      'vac-box-search': showSearchBar,
      'vac-box-empty': !showSearchBar,
    }"
  >
    <template v-if="showSearch">
      <div
        v-if="!loadingRooms"
        class="vac-icon-search"
      >
        <slot name="search-icon">
          <svg-icon name="search" />
        </slot>
      </div>
      <input
        v-if="!loadingRooms"
        type="search"
        :value="getSearch"
        :placeholder="textMessages.SEARCH"
        autocomplete="off"
        class="vac-input"
        @input="$emit('search-room', $event)"
      />
    </template>
    <div
      v-if="showSearch && !loadingRooms"
      class="vac-svg-button vac-filter-icon"
      @click="openDialogFilter"
    >
      <v-dialog
        v-model="dialogFilter"
        width="600px"
        persistent
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="itemFilter.filter"
                  :items="itemsFilter"
                  :label="$t('filter')"
                  dense
                  outlined
                ></v-select>
                <v-select
                  v-model="itemFilter.sort"
                  :items="itemsSort"
                  :label="$t('sort')"
                  dense
                  outlined
                ></v-select>
                <!-- <v-select
                  v-model="itemFilter.channel"
                  outlined
                  :items="optionsInstances"
                  item-text="label"
                  label="Channels"
                  dense
                  return-object
                >
                  <template
                    slot="item"
                    slot-scope="{ item }"
                  >
                    <div
                      v-if="item.value.color"
                      class="state-circle"
                      :style="{ 'background-color': item.value.color }"
                    />
                    {{ item.label && item.label.startsWith('-') ? item.value.label_server : item.label }}
                  </template>
                </v-select> -->

                <!-- LABEL FILTER -->
                <v-select
                  v-model="itemFilter.label"
                  outlined
                  :items="optionsLabel"
                  item-text="label"
                  item-value="id"
                  return-object
                  label="Filter"
                  :clearable="true"
                  dense
                  :disabled="optionsLabel.length === 0"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < maxDisplay"
                      class="me-1"
                    >
                      <v-icon
                        v-if="item.color"
                        dark
                        :style="{ color: item.color }"
                      >
                        {{ icons.mdiLabel }}
                      </v-icon>
                      <v-icon
                        v-if="item.label_color"
                        dark
                        :style="{ color: item.label_color }"
                        class="me-1"
                      >
                        {{ icons.mdiLabelVariant }}
                      </v-icon>
                      {{ $vuetify.breakpoint.xs ? '' : item.label }}
                    </span>
                    <span
                      v-if="index === maxDisplay"
                      class="grey--text caption"
                    >(+{{ itemFilter.label.length - maxDisplay }})</span>
                  </template>
                  <template
                    slot="item"
                    slot-scope="{ item }"
                  >
                    <v-icon
                      v-if="item.color"
                      dark
                      :style="{ color: item.color }"
                      class="me-1"
                    >
                      {{ icons.mdiLabel }}
                    </v-icon>
                    <v-icon
                      v-if="item.label_color"
                      dark
                      :style="{ color: item.label_color }"
                      class="me-1"
                    >
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                    {{ item.label }}
                  </template>
                </v-select>
                <v-select
                  v-model="notifAllOrAssigned"
                  outlined
                  :items="dataNotifAllOrAssigned"
                  item-text="name"
                  item-value="data"
                  :label="$t('notifUnreplied')"
                  return-object
                  class="mt-2"
                  dense
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-switch
                  v-if="user.role === 'Admin'"
                  v-model="boldUnreplied"
                  class="mt-1 v-input--reverse"
                >
                  <template v-slot:label>
                    {{ $t('channels.boldUnreplied') }}
                    <v-progress-circular
                      v-if="switchBold"
                      :indeterminate="switchBold"
                      :value="0"
                      size="24"
                    ></v-progress-circular>
                  </template>
                </v-switch>
                <v-switch
                  v-if="user.role === 'Admin'"
                  v-model="autoAssignChatOnReply"
                  class="mt-2 v-input--reverse"
                >
                  <template v-slot:label>
                    {{ $t('inbox.autoAssignChatOnReply') }}
                    <v-progress-circular
                      v-if="switchAutoAssignChatOnReply"
                      :indeterminate="switchAutoAssignChatOnReply"
                      :value="0"
                      size="24"
                    ></v-progress-circular>
                  </template>
                </v-switch>
                <!-- <v-switch
                  v-if="
                    (user.package == 'business' && user.role === 'Admin') ||
                      (user.package == 'business_plus' && user.role === 'Admin')
                  "
                  v-model="autoAssignNewChat"
                  class="mt-2 v-input--reverse"
                >
                  <template v-slot:label>
                    {{ $t('inbox.autoAssignNewChat') }}
                    <v-progress-circular
                      v-if="switchAutoAssignNewChat"
                      :indeterminate="switchAutoAssignNewChat"
                      :value="0"
                      size="24"
                    ></v-progress-circular>
                  </template>
                </v-switch> -->
                <!-- <v-switch
                  v-if="
                    (user.package == 'business' && user.role !== 'Admin') ||
                    (user.package == 'business_plus' && user.role !== 'Admin')
                  "
                  v-model="statusOperator"
                  class="mt-2 v-input--reverse"
                >
                  <template v-slot:label> on Work </template>
                </v-switch> -->

                <!-- <v-select
                  v-if="user.role === 'Admin'"
                  v-model="selectedNotifUnreplied"
                  class="mt-5"
                  outlined
                  :items="notifUnreplied"
                  item-text="name"
                  :label="$t('notifUnreplied')"
                  return-object
                  dense
                ></v-select> -->
              </v-col>
            </v-row>

            <v-btn
              color="#ffeeee"
              block
              class="mb-3"
              @click="cancelFilter"
            >
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn
              color="success"
              block
              @click="filterRoom"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <slot name="filter-icon">
        <v-icon :color="$vuetify.theme.isDark ? 'white' : 'primary'">
          {{ icons.mdiCog }}
        </v-icon>
        <!-- <svg-icon name="filter" /> -->
      </slot>
    </div>
    <div
      v-if="showAddRoom && showSearch && !loadingRooms"
      class="vac-svg-button vac-add-icon"
      @click="$emit('add-room')"
    >
      <slot name="add-icon">
        <svg-icon name="add" />
      </slot>
    </div>
  </div>
</template>

<script>
import pouchDbMixin from '@/mixins/pouchDbMixin'
import { mdiCog, mdiLabel, mdiLabelVariant } from '@mdi/js'
import _uniqBy from 'lodash/uniqBy'
// eslint-disable-next-line import/extensions
import SvgIcon from '../../../components/SvgIcon/SvgIcon'

export default {
  name: 'RoomsSearch',
  components: { SvgIcon },
  mixins: [pouchDbMixin],

  props: {
    textMessages: { type: Object, required: true },
    showSearch: { type: Boolean, required: true },
    showAddRoom: { type: Boolean, required: true },
    showFilterRoom: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    loadingRooms: { type: Boolean, required: true },
    optionsInstances: { type: Array, required: true },
  },

  data() {
    return {
      statusOperator: null,
      labelValue: [],
      dialogFilter: false,
      itemsFilter: ['all', 'assigned', 'unread'],
      itemsSort: ['newest', 'oldest'],
      itemFilter: {
        filter: 'all',
        sort: 'newest',
        channel: {
          label: 'All Channels',
          value: {
            all_sender: true,
            phone_number: null,
            color: '#3fc19f',
          },
        },
        label: [],
      },

      boldUnrepliedSetting: null,
      boldUnreplied: false,
      switchBold: false,

      autoAssignChatOnReply: true,
      switchAutoAssignChatOnReply: false,

      autoAssignNewChatSetting: null,
      autoAssignNewChat: true,
      switchAutoAssignNewChat: false,

      notifAllOrAssignedSetting: null,
      notifAllOrAssigned: { name: this.$t('notifFromChannel'), data: 2 },
      switchNotifAllOrAssigned: false,
      selectedNotifUnreplied: null,
      notifUnreplied: [
        { name: this.$t('never'), time: 0 },
        { name: `${this.$t('every')} 1 ${this.$t('minute')}`, time: 60000 },
        { name: `${this.$t('every')} 5 ${this.$t('minute')}`, time: 300000 },
        { name: `${this.$t('every')} 10 ${this.$t('minute')}`, time: 600000 },
      ],
      dataNotifAllOrAssigned: [
        { name: this.$t('notifFromAll'), data: 1 },
        { name: this.$t('notifFromChannel'), data: 2 },
        { name: this.$t('notifFromAssigned'), data: 3 },
        { name: this.$t('none'), data: null },
      ],
      labelData: [],
      contactList: [],
      maxDisplay: 1,
      icons: {
        mdiLabel,
        mdiLabelVariant,
        mdiCog,
      },
    }
  },

  emits: ['search-room', 'add-room', 'filter-room'],
  computed: {
    getLabelvalue() {
      return this.$store.getters['inbox/getLabelValue']
    },
    getLabelFilter() {
      return this.$store.getters['inbox/getLabelFilter']
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    showSearchBar() {
      return this.showSearch || this.showAddRoom || this.showFilterRoom
    },
    getSearch() {
      const search = this.$store.getters['global/getSearch']
      if (search && search.target && search.target.value) {
        return search.target.value
      }

      return ''
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    filterChat() {
      return this.$store.getters['global/getFilterChat']
    },

    // labelValue() {
    //   return this.$store.getters['inbox/getLabelValue']
    // },
    optionsLabel() {
      const listLabel = []
      const filtered = this.labelValue.filter(x => x.contact && !x.chat)
      // eslint-disable-next-line no-restricted-syntax
      for (const data of filtered) {
        // eslint-disable-next-line eqeqeq
        const label = this.labelData.find(x => x.label == data.label.id)

        if (label) {
          listLabel.push(label)
        } else {
          listLabel.push(data.label)
        }
      }

      return _uniqBy([...listLabel, ...this.contactList], 'id')
    },
  },
  watch: {
    filterChat(newVar) {
      this.itemFilter.filter = newVar
    },
  },
  methods: {
    async openDialogFilter() {
      // await this.connectSettings(false, false)
      // await this.loadSettings()
      // this.$store.commit('inbox/setLabelFilter', [])
      // const getTime = await this.$store.dispatch('inboxNavbar/getNotifyTime', this.user.sub_id)

      // // //console.log(getTime, '--------------------------')
      // const notifData = this.notifUnreplied.find(el => {
      //   return el.time === getTime.data.data.time.time
      // })
      // this.selectedNotifUnreplied = notifData

      // const labelValue = this.allSettings.find(el => el.key === 'label').value

      // const boldUnrepliedSetting = this.allSettings.find(el => el.key === 'bold_unreplied')
      // if (boldUnrepliedSetting) {
      //   this.boldUnrepliedSetting = boldUnrepliedSetting
      //   this.boldUnreplied = this.boldUnrepliedSetting.value
      //   this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      // } else {
      //   await this.updateSettings(
      //     { _id: 'bold_unreplied', key: 'bold_unreplied', value: this.boldUnreplied },
      //     async x => {
      //       this.boldUnrepliedSetting = await this.loadSettingsById(x.id)
      //       this.boldUnreplied = this.boldUnrepliedSetting.value
      //       this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      //     },
      //   )
      // }
      const boldUnrepliedPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'bold_unreplied',
      })

      if (boldUnrepliedPg.status && boldUnrepliedPg.data) {
        this.boldUnreplied = boldUnrepliedPg.data.value
      } else {
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'bold_unreplied',
          value: this.boldUnreplied,
        })
      }
      this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)

      const autoAssignPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign',
      })

      if (autoAssignPg.status && autoAssignPg.data) {
        this.autoAssignChatOnReply = autoAssignPg.data.value
      } else {
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'auto_assign',
          value: this.autoAssignChatOnReply,
        })
      }
      this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)

      // const autoAssignSetting = this.allSettings.find(el => el.key === 'auto_assign')
      // if (autoAssignSetting) {
      //   this.autoAssignSetting = autoAssignSetting
      //   this.autoAssignChatOnReply = this.autoAssignSetting.value
      //   this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
      // } else {
      //   await this.updateSettings(
      //     { _id: 'auto_assign', key: 'auto_assign', value: this.autoAssignChatOnReply },
      //     async x => {
      //       this.autoAssignSetting = await this.loadSettingsById(x.id)
      //       this.autoAssignChatOnReply = this.autoAssignSetting.value
      //       this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
      //     },
      //   )
      // }

      const autoAssignNewChatPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign_new_chat',
      })

      if (autoAssignNewChatPg.status && autoAssignNewChatPg.data) {
        this.autoAssignNewChat = autoAssignNewChatPg.data.value
      } else {
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'auto_assign_new_chat',
          value: this.autoAssignNewChat,
        })
      }
      this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)

      // const autoAssignNewChatSetting = this.allSettings.find(el => el.key === 'auto_assign_new_chat')
      // if (autoAssignNewChatSetting) {
      //   this.autoAssignNewChatSetting = autoAssignNewChatSetting
      //   this.autoAssignNewChat = this.autoAssignNewChatSetting.value
      //   this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
      // } else {
      //   await this.updateSettings(
      //     { _id: 'auto_assign', key: 'auto_assign', value: this.autoAssignNewChat },
      //     async x => {
      //       this.autoAssignNewChatSetting = await this.loadSettingsById(x.id)
      //       this.autoAssignNewChat = this.autoAssignNewChatSetting.value
      //       this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
      //     },
      //   )
      // }

      const notifSettingPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'notif_all_or_assigned',
      })

      if (notifSettingPg.status && notifSettingPg.data) {
        this.notifAllOrAssigned = notifSettingPg.data.value
      } else {
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'notif_all_or_assigned',
          value: this.notifAllOrAssigned,
        })
      }
      this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
      this.statusOperator = this.$store.getters['rooms/getstatusOperator']

      // const notifAllOrAssignedSetting = this.allSettings.find(el => el.key === 'notif_all_or_assigned')
      // if (notifAllOrAssignedSetting) {
      //   this.notifAllOrAssignedSetting = notifAllOrAssignedSetting
      //   if (this.notifAllOrAssignedSetting && this.notifAllOrAssignedSetting.value) {
      //     this.notifAllOrAssigned = this.notifAllOrAssignedSetting.value
      //   }
      //   this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
      // } else {
      //   await this.updateSettings(
      //     { _id: 'notif_all_or_assigned', key: 'notif_all_or_assigned', value: this.notifAllOrAssigned },
      //     async x => {
      //       this.notifAllOrAssignedSetting = await this.loadSettingsById(x.id)
      //       this.notifAllOrAssigned = this.notifAllOrAssignedSetting.value
      //       this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
      //     },
      //   )
      // }

      // eslint-disable-next-line no-underscore-dangle
      // const colSetting = this.allSettings.find(x => x._id === 'columns')
      // if (colSetting) {
      //   const labelColumn = colSetting.value.find(x => x.value === 'label')
      //   if (labelColumn) {
      //     // this.labelData = labelColumn.listValue
      //   }
      // }

      const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label',
      })

      if (labelsPg.status) {
        this.labelData = labelsPg.data.value
      }
      const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label_value',
      })

      if (labelsValuePg.status) {
        this.labelValue = labelsValuePg.data.value
      }

      const contactListPg = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })

      if (contactListPg.status) {
        this.contactList = contactListPg.data.filter(list => {
          if (list.list_id !== 'default') {
            list.id = list.list_id
            list.label = list.list_name

            return true
          }

          return false
        })

        // //console.log(this.contactsPouch, this.contactList, `INI KONTAK`)
      }

      if (this.getLabelFilter && this.getLabelFilter.length !== 0) {
        this.itemFilter.label = this.getLabelFilter
      } else {
        this.itemFilter.label = []
      }

      if (this.labelValue.length !== 0) {
        this.$store.commit('inbox/setLabelValue', this.labelValue)
      }

      if (this.getGlobalChannel) {
        this.itemFilter.channel = this.getGlobalChannel
      }
      this.dialogFilter = true
    },
    cancelFilter() {
      this.dialogFilter = false

      this.itemFilter = {
        filter: 'all',
        sort: 'newest',
        channel: {
          label: 'All Channels',
          value: {
            all_sender: true,
            phone_number: null,
            color: '#3fc19f',
          },
        },
        label: [],
      }

      this.boldUnrepliedSetting = null
      this.boldUnreplied = false
      this.switchBold = false

      this.autoAssignChatOnReply = true
      this.switchAutoAssignChatOnReply = false
      this.autoAssignNewChatSetting = null
      this.autoAssignNewChat = true
      this.switchAutoAssignNewChat = false

      this.notifAllOrAssignedSetting = null
      this.notifAllOrAssigned = { name: this.$t('notifFromChannel'), data: 2 }
      this.switchNotifAllOrAssigned = false
      this.selectedNotifUnreplied = null
      this.labelData = []
    },
    async changeNotifTime() {
      await this.$store.dispatch('inboxNavbar/setNotifyTime', {
        sub_id: this.user.sub_id,
        time: this.selectedNotifUnreplied,
      })
    },
    async changeNotifAllOrAssigned() {
      // //console.log(this.notifAllOrAssigned)
      this.switchNotifAllOrAssigned = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'notif_all_or_assigned',
        value: this.notifAllOrAssigned,
      })
      this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
      this.switchNotifAllOrAssigned = false

      // this.notifAllOrAssignedSetting.value = this.notifAllOrAssigned.data
      // await this.updateSettings(this.notifAllOrAssignedSetting, async x => {
      //   if (x === false) {
      //     this.notifAllOrAssigned = { name: this.$t('notifFromChannel'), data: 2 }
      //   }
      //   this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned.data)
      //   this.switchNotifAllOrAssigned = false
      // })

      // this.switchNotifAllOrAssigned = false
    },
    async changeAutoAssignNewChat() {
      this.switchAutoAssignNewChat = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign_new_chat',
        value: this.autoAssignNewChat,
      })
      this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
      this.switchAutoAssignNewChat = false

      // this.autoAssignNewChatSetting.value = this.autoAssignNewChat
      // await this.updateSettings(this.autoAssignNewChatSetting, async x => {
      //   if (x === false) {
      //     this.autoAssignNewChat = !this.autoAssignNewChat
      //   }
      //   this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
      //   this.switchAutoAssignNewChat = false
      // })

      // this.switchAutoAssignChatOnReply = false
    },
    async changeAutoAssignChatOnReply() {
      this.switchAutoAssignChatOnReply = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign',
        value: this.autoAssignChatOnReply,
      })
      this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
      this.switchAutoAssignChatOnReply = false

      // this.autoAssignSetting.value = this.autoAssignChatOnReply
      // await this.updateSettings(this.autoAssignSetting, async x => {
      //   if (x === false) {
      //     this.autoAssignChatOnReply = !this.autoAssignChatOnReply
      //   }
      //   this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
      //   this.switchAutoAssignChatOnReply = false
      // })

      // this.switchAutoAssignChatOnReply = false
    },
    async changeValueBold() {
      this.switchBold = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'bold_unreplied',
        value: this.boldUnreplied,
      })
      this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      this.switchBold = false

      // this.boldUnrepliedSetting.value = this.boldUnreplied
      // await this.updateSettings(this.boldUnrepliedSetting, async x => {
      //   if (x === false) {
      //     this.boldUnreplied = !this.boldUnreplied
      //   }
      //   this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      //   this.switchBold = false
      // })
    },
    async filterRoom() {
      this.$store.commit('rooms/setstatusOperator', this.statusOperator)
      await this.$store.dispatch('rooms/pingOperator', {
        status: this.statusOperator,
        email: this.user.email,
        subId: this.user.sub_id,
      })

      // this.$store.commit('inboxNavbar/setValueChannel', this.selectedInstanceVal)
      // this.$store.commit('global/setGlobalChannel', this.selectedInstanceVal)
      // this.$store.commit('inbox/setLabelFilter', this.selectedLabel)
      //! Switch bold
      this.switchBold = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'bold_unreplied',
        value: this.boldUnreplied,
      })
      this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      this.switchBold = false

      //! Change auto assign
      this.switchAutoAssignChatOnReply = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign',
        value: this.autoAssignChatOnReply,
      })
      this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
      this.switchAutoAssignChatOnReply = false

      //! Change auto assign new chat
      this.switchAutoAssignNewChat = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign_new_chat',
        value: this.autoAssignNewChat,
      })
      this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
      this.switchAutoAssignNewChat = false

      //! Change notif all or assigned
      this.switchNotifAllOrAssigned = true
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'notif_all_or_assigned',
        value: this.notifAllOrAssigned,
      })
      this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
      this.switchNotifAllOrAssigned = false

      //! Change notif setting
      // await this.$store.dispatch('inboxNavbar/setNotifyTime', {
      //   sub_id: this.user.sub_id,
      //   time: this.selectedNotifUnreplied,
      // })

      //! Dispatch other filters
      this.$store.commit('global/setFilterRoom', this.itemFilter)
      this.$store.commit('inboxNavbar/setValueContact', this.itemFilter.filter)
      this.$store.commit('global/setFilterChat', this.itemFilter.filter)

      // this.$store.commit('inboxNavbar/setValueChannel', this.itemFilter.channel)
      // this.$store.commit('global/setGlobalChannel', this.itemFilter.channel)

      // //console.log(this.itemFilter, `INI FILTER`)
      this.$store.commit('inbox/setLabelFilter', this.itemFilter.label)

      this.dialogFilter = false
      this.$emit('filter-room', this.itemFilter)
      await this.loadSettings()
    },
  },
}
</script>
