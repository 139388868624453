<template>
  <transition name="vac-slide-up">
    <div style="overflow: auto; max-height: 200px">
      <div
        v-if="filteredQuickReplies.length"
        :style="{ bottom: `${$parent.$refs.roomFooter.clientHeight}px` }"
        class="vac-quick-replies-container vac-app-box-shadow"
      >
        <div class="vac-quick-replies-box" style="border-bottom: 1px solid #e1e4e8">
          <v-col cols="11" class="vac-quick-replies-header cursor-auto" style="padding: 15px 20px">
            {{ $t('inbox.quickReplies') }}
          </v-col>
          <v-col
            cols="1"
            class="vac-quick-replies-header-icon condition-style"
            style="padding: 15px 1px"
            @click="showDialogShownSavedReplies"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-col>
        </div>
        <!-- <pre>{{filteredQuickReplies}}</pre> -->
        <div
          v-for="(reply, index) in filteredQuickReplies"
          :key="index"
          :class="{ 'vac-quick-replies-active': index === activeItem }"
          class="vac-quick-replies-box"
          style=""
          @click="onClickQuickReplayMenu(reply)"
          @mouseover="activeItem = index"
        >
          <div class="vac-quick-replies-info">
            <div v-if="reply.tag" class="vac-quick-replies-tag">
              {{ reply.tag }}
            </div>
            <div class="vac-quick-reply" v-if="reply.isEform">
              {{ reply.showText }}
            </div>
            <div class="vac-quick-reply" v-else>
              {{ reply.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- <v-dialog v-model="openDialogSurvey">
      <v-card>
        <v-card-title>
          <span class="text-h5 font-weight-bold">{{ currentSurvey ? currentSurvey.survey.title : '' }}</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <survey
            :key="keySurvey"
            :survey="survey"
          ></survey>
        </v-card-text>
      </v-card>
    </v-dialog> -->

</template>

<script>
import { mdiMenuRight, mdiMessageStar, mdiPencil } from '@mdi/js'
import eFormMixin from '@/mixins/eFormMixin'
import { async } from '@firebase/util'


export default {
  name: 'RoomQuickReplies',
  mixins: [eFormMixin],
  props: {
    filteredQuickReplies: { type: Array, required: true },
    selectItem: { type: Boolean, default: null },
    activeUpOrDown: { type: Number, default: null },
    room: { type: Object, default: null },
  },

  emits: ['select-quick-reply', 'activate-item'],

  data() {
    return {
      activeItem: null,
      icons: {
        mdiMessageStar,
        mdiMenuRight,
        mdiPencil,
      },
      openDialogSurvey: false,
      keySurvey: 0,
      currentSurvey: {},
      survey: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    filteredQuickReplies(val, oldVal) {
      if (!oldVal.length || val.length !== oldVal.length) {
        this.activeItem = 0
      }
    },
    selectItem(val) {
      if (val) {
        this.$emit('select-quick-reply', this.filteredQuickReplies[this.activeItem])
      }
    },
    activeUpOrDown() {
      if (this.activeUpOrDown > 0 && this.activeItem < this.filteredQuickReplies.length - 1) {
        this.activeItem++
      } else if (this.activeUpOrDown < 0 && this.activeItem > 0) {
        this.activeItem--
      }
      this.$emit('activate-item')
    },
  },

  methods: {
    showDialogShownSavedReplies() {
      this.$eventBus.$emit('showDialogShownSavedReplies')
      this.$emit('openQuickReply')
    },
    async onClickQuickReplayMenu(reply) {
      this.$emit('select-quick-reply', reply);
    }
  },
}
</script>
<style lang="scss">
.condition-style {
  padding: 15px 15px;
}
.design-quick-reply {
  position: absolute;
  bottom: 80px;
  width: 190px;
  height: 180px;
  right: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0.5px rgba(0, 0, 0, 0.22);

  .overflow-q {
    overflow: hidden auto;
    height: 140px;
  }

  .floating-reply {
    position: relative;
    padding: 5px;
    overflow: hidden;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
  }

  .font-size-q {
    font-size: 12px;
    font-weight: 800;
  }

  .button-q {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    text-align: left;
    padding: 5px 2px 5px 2px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1523px) {
}
</style>
