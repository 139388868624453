<template>
  <div class="vac-message-files-container">
    <div
      v-for="(file, idx) in imageVideoFiles"
      :key="idx + 'iv'"
    >
      <message-file
        :file="file"
        :current-user-id="currentUserId"
        :message="message"
        :index="idx"
        @open-file="$emit('open-file', $event)"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </message-file>
    </div>

    <div
      v-for="(file, idx) in otherFiles"
      :key="idx + 'a'"
    >
      <progress-bar
        v-if="file.progress >= 0 && file.progress < 100"
        :progress="file.progress"
        :style="{ top: '54px' }"
      />
      <div
        class="vac-file-container"
        :class="{ 'vac-file-container-progress': file.progress >= 0 && file.progress < 100 }"
        @click.stop="openFile(file, 'download')"
      >
        <div class="vac-svg-button">
          <slot name="document-icon">
            <v-icon
              v-if="file.type == 'contact'"
              color="info"
            >
              {{ icons.mdiAccount }}
            </v-icon>
            <v-icon
              v-else
              color="info"
            >
              {{ icons.mdiDownload }}
            </v-icon>
          </slot>
        </div>
        <div class="vac-text-ellipsis">
          {{ file.name }}
        </div>
        <div
          v-if="file.extension"
          class="vac-text-ellipsis vac-text-extension"
        >
          {{ file.extension }}
        </div>
      </div>
    </div>

    <format-message
      :content="message.content"
      :users="roomUsers"
      :all-contacts="allContacts"
      :text-formatting="textFormatting"
      :link-options="linkOptions"
      @open-user-tag="openUserTag"
    >
      <template
        v-for="(i, name) in $scopedSlots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </format-message>
  </div>
</template>

<script>
import { mdiDownload, mdiAccount } from '@mdi/js'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'
import ProgressBar from '../../../components/ProgressBar/ProgressBar'
import MessageFile from '../MessageFile/MessageFile'

const { isImageVideoFile } = require('../../../utils/media-file')

export default {
  name: 'MessageFiles',

  setup() {
    return {
      icons: {
        mdiDownload,
        mdiAccount,
      },
    }
  },

  components: { SvgIcon, FormatMessage, ProgressBar, MessageFile },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    roomUsers: { type: Array, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    allContacts: { type: Array, default: () => [] },
  },

  emits: ['open-file', 'open-user-tag'],

  computed: {
    imageVideoFiles() {
      return this.message.files.filter(file => isImageVideoFile(file))
    },
    otherFiles() {
      return this.message.files.filter(file => !isImageVideoFile(file))
    },
  },

  methods: {
    openFile(file, action) {
      this.$emit('open-file', { file, action })
    },
    openUserTag(user) {
      this.$emit('open-user-tag', { user })
    },
  },
}
</script>
