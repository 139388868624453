<template>
  <div class="vac-reply-message">
    <div class="vac-reply-username">
      {{ replyUsername }}
    </div>

    <template v-if="isImage">
      <div class="vac-image-reply-container mb-2">
        <div
          class="vac-message-image vac-message-image-reply"
          :style="{
            'background-image': `url('${firstFile.url}')`,
          }"
        />
      </div>
    </template>

    <template v-else-if="isVideo">
      <template v-if="videoThumbnail">
        <div class="vac-image-reply-container mb-2">
          <div
            class="vac-message-image vac-message-image-reply"
            :style="{
              'background-image': `url('${videoThumbnail}')`,
            }"
          >
            <div class="overlay-video">
              <v-icon color="primary">
                {{ icons.mdiVideo }}
              </v-icon>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="vac-location-container mb-2">
          <div
            class="vac-svg-button"
            style="cursor: auto !important"
          >
            <v-icon color="info">
              {{ icons.mdiVideo }}
            </v-icon>
          </div>
        </div>

        <!-- <div class="vac-video-reply-container">
          <video
            ref="refVideo"
            width="100%"
            height="100%"
            controls
          >
            <source :src="firstFile.url" />
          </video>

          <canvas
            ref="refCanvas"
            class="d-none"
          ></canvas>
        </div> -->
      </template>
    </template>

    <template v-else-if="isLocation">
      <div class="vac-location-container">
        <div
          class="vac-svg-button"
          style="cursor: auto !important"
        >
          <v-icon color="info">
            {{ icons.mdiGoogleMaps }}
          </v-icon>
        </div>
      </div>
    </template>

    <template v-else-if="isAudio">
      <audio-player
        :src="firstFile.url"
        @update-progress-time="progressTime = $event"
        @hover-audio-progress="hoverAudioProgress = $event"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </audio-player>
    </template>

    <template v-else-if="isOtherFile">
      <div class="vac-file-container mb-2">
        <div>
          <slot name="file-icon">
            <v-icon
              v-if="firstFile.type == 'contact'"
              color="info"
            >
              {{ icons.mdiAccount }}
            </v-icon>
            <v-icon
              v-else
              color="info"
            >
              {{ icons.mdiTextBox }}
            </v-icon>
          </slot>
        </div>
        <div class="vac-text-ellipsis">
          {{ firstFile.name }}
        </div>
        <div
          v-if="firstFile.extension"
          class="vac-text-ellipsis vac-text-extension"
        >
          {{ firstFile.extension }}
        </div>
      </div>
    </template>

    <div class="vac-reply-content">
      <format-message
        :content="message.replyMessage.content"
        :users="roomUsers"
        :all-contacts="allContacts"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        :reply="true"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </format-message>
    </div>
  </div>
</template>

<script>
import { mdiGoogleMaps, mdiVideo, mdiAccount, mdiTextBox } from '@mdi/js'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'

import AudioPlayer from '../AudioPlayer/AudioPlayer'

const { isAudioFile, isImageFile, isVideoFile } = require('../../../utils/media-file')

export default {
  name: 'MessageReply',

  setup() {
    return {
      icons: {
        mdiGoogleMaps,
        mdiVideo,
        mdiAccount,
        mdiTextBox,
      },
    }
  },

  components: { AudioPlayer, SvgIcon, FormatMessage },

  props: {
    message: { type: Object, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    roomUsers: { type: Array, required: true, default: () => [] },
    allContacts: { type: Array, required: true, default: () => [] },
  },

  data() {
    return {
      videoThumbnail: false,
    }
  },

  computed: {
    replyUsername() {
      const { senderId } = this.message.replyMessage
      const userData = this.$store.getters['global/getAllContactData'].find(contact => contact.phone_number == senderId)

      const userContact = this.roomUsers.find(user => user._id == senderId)

      // //console.log(userData, `INI USER DATA`)

      if (this.allContacts) {
        const foundContact = this.allContacts.find(x => x.phone_number === senderId)

        // //console.log(foundContact, `INI KONTAK COUCH`)

        if (foundContact) {
          return foundContact.name
        }
      }

      if (userData) {
        if (userData.bailey_name) {
          return userData.bailey_name
        }

        if (userData.notify_name) {
          return userData.notify_name
        }

        if (userData.contact_list_name) {
          return userData.contact_list_name
        }

        // if (userData.data.username) {
        //   return userData.data.username
        // }
      }

      if (userContact) {
        if (userContact.username !== userContact._id) {
          return userContact.username
        }
      }

      return this.formatPhoneNumber(senderId)
    },
    firstFile() {
      return this.message.replyMessage.files?.length ? this.message.replyMessage.files[0] : {}
    },
    isAudio() {
      return isAudioFile(this.firstFile)
    },
    isImage() {
      return isImageFile(this.firstFile)
    },
    isVideo() {
      return isVideoFile(this.firstFile)
    },
    isLocation() {
      if (this.message) {
        if (this.message.quotedData) {
          if (this.message.quotedData.quotedMessage) {
            if (this.message.quotedData.quotedMessage.locationMessage) {
              return true
            }
          }
        }
      }

      return false
    },
    isOtherFile() {
      return this.message.replyMessage.files?.length && !this.isAudio && !this.isVideo && !this.isImage
    },
  },

  mounted() {
    if (this.message) {
      if (this.message.replyMessage) {
        if (this.firstFile) {
          if (this.isVideo) {
            // this.getVideoThumbnail()
          }
        }
      }
    }
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      return phoneNumber
    },
    getVideoThumbnail() {
      const self = this
      const _VIDEO = this.$refs.refVideo
      const _CANVAS = this.$refs.refCanvas

      // Video metadata is loaded
      _VIDEO.addEventListener(
        'loadedmetadata',
        function () {
          setTimeout(function () {
            _CANVAS.getContext('2d').drawImage(_VIDEO, 0, 0, _CANVAS.width, _CANVAS.height)

            try {
              if (_CANVAS.toDataURL()) {
                self.videoThumbnail = _CANVAS.toDataURL()
              }
            } catch (error) {
              //console.log('thumbnail unavailable')
            }
          }, 500)
        },
        false,
      )

      _VIDEO.currentTime = 0
      _VIDEO.load()
    },
  },
}
</script>
<style scoped lang="scss">
.overlay-video {
  position: absolute;
  top: 33%;
  left: 35%;
}
</style>
