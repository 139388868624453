<template>
  <div class="vac-room-file-container">
    <loader :show="file.loading">
      <template
        v-for="(idx, name) in $scopedSlots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </loader>

    <div
      class="vac-svg-button vac-icon-remove"
      @click="$emit('remove-file', index)"
    >
      <slot name="image-close-icon">
        <svg-icon
          name="close"
          param="image"
        />
      </slot>
    </div>

    <template v-if="isImage">
      <div
        class="vac-message-image"
        :class="{ 'vac-blur-loading': file.loading }"
        :style="{
          'background-image': `url('${file.localUrl || file.url}')`
        }"
      />
    </template>

    <template v-else-if="isVideo">
      <template v-if="videoThumbnail">
        <div
          class="vac-message-image"
          :style="{
            'background-image': `url('${videoThumbnail}')`
          }"
        >
          <div class="overlay-video">
            <v-icon color="primary">
              {{ icons.mdiVideo }}
            </v-icon>
          </div>
        </div>
      </template>
      <template v-else>
        <video
          ref="refVideo"
          controls
          :class="{ 'vac-blur-loading': file.loading }"
        >
          <source :src="file.localUrl || file.url" />
        </video>

        <canvas
          ref="refCanvas"
          class="d-none"
        ></canvas>
      </template>
    </template>

    <template v-else>
      <div
        class="vac-file-container"
        :class="{ 'vac-blur-loading': file.loading }"
      >
        <div>
          <slot name="file-icon">
            <svg-icon name="file" />
          </slot>
        </div>
        <div class="vac-text-ellipsis">
          {{ file.name }}
        </div>
        <div
          v-if="file.extension"
          class="vac-text-ellipsis vac-text-extension"
        >
          {{ file.extension }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mdiVideo } from '@mdi/js'
import Loader from '../../../components/Loader/Loader'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'

const { isImageFile, isVideoFile } = require('../../../utils/media-file')

export default {
  name: 'RoomFiles',

  components: {
    SvgIcon,
    Loader,
  },

  setup() {
    return {
      icons: {
        mdiVideo,
      },
    }
  },

  props: {
    file: { type: Object, required: true },
    index: { type: Number, required: true },
  },

  emits: ['remove-file'],

  data() {
    return {
      videoThumbnail: null,
    }
  },

  computed: {
    isImage() {
      return isImageFile(this.file)
    },
    isVideo() {
      return isVideoFile(this.file)
    },
  },

  mounted() {
    if (this.isVideo) {
      this.getVideoThumbnail()
    }
  },

  methods: {
    getVideoThumbnail() {
      const self = this
      const _VIDEO = this.$refs.refVideo
      const _CANVAS = this.$refs.refCanvas

      // Video metadata is loaded
      _VIDEO.addEventListener(
        'loadedmetadata',
        function () {
          setTimeout(function () {
            _CANVAS.getContext('2d').drawImage(_VIDEO, 0, 0, _CANVAS.width, _CANVAS.height)

            self.videoThumbnail = _CANVAS.toDataURL()
          }, 500)
        },
        false,
      )

      _VIDEO.currentTime = 0
      _VIDEO.load()
    },
  },
}
</script>
<style scoped lang="scss">
.overlay-video {
  position: absolute;
  top: 35%;
  left: 40%;
}
</style>
