<template>
  <div>
    <v-overlay :value="overlayGlobal">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogSharedRoom" width="600px">
      <v-card>
        <v-card-text>
          <template v-if="!sharedRoomUrl">
            <v-row class="mb-4">
              <v-col cols="6">
                <v-select
                  v-model="sharedRoomExpiryTime"
                  outlined
                  :items="sharedRoomExpiryTimeItems"
                  item-text="label"
                  :label="$t('inbox.sharedRoomExpiryTime')"
                  return-object
                  hide-details
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="sharedRoomType"
                  outlined
                  :items="sharedRoomTypeItems"
                  item-text="label"
                  :label="$t('inbox.sharedRoomType')"
                  return-object
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-btn color="primary" block @click="shareRoom">
              {{ $t('share') }}
            </v-btn>
          </template>
          <v-text-field v-if="sharedRoomUrl" v-model="sharedRoomUrl" :placeholder="$t('inbox.sharedRoomUrl')" readonly>
            <template v-slot:append-outer>
              <v-btn text @click="doCopy(sharedRoomUrl)">
                {{ $t('EOrdering.copyLink') }}
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogWarningNotConnect" persistent width="500">
      <v-card>
        <v-card-title color="error">
          {{ 'Warning' }}
        </v-card-title>

        <v-card-text>
          {{ 'No Channel Connected, Please Contact Your Admin and Refresh Page.' }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <alert-dialog :dialog="alertDialog" :title="alertTitle" :text="alertText" @close="alertDialog = false" />
    <v-snackbar
      v-model="statusInstancesAlert"
      :timeout="-1"
      top
      :right="$vuetify.breakpoint.mdAndUp"
      :dark="isDark ? false : true"
      text
      color="warning"
      style="z-index: 5 !important"
    >
      <v-row>
        <v-col align-self="center" cols="auto mr-auto">
          {{ labelStatusInstanceAlert }}
        </v-col>
        <v-col cols="auto">
          <v-btn color="warning" depressed @click="checkStatusInstances(false)">
            {{ $t('refresh') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-dialog v-model="dialogSavedReplies" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ isNewModeSavedReplies ? $t('add') : $t('edit') }} Quick Reply</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-3 hide-buton"
            color="error"
            @click="dialogSavedReplies = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveSavedReplies"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field v-model="savedRepliesTag" dense outlined :label="$t('tag')"></v-text-field>
          <v-textarea v-model="savedRepliesName" dense outlined rows="3" :label="$t('text')"></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="error" @click="dialogSavedReplies = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="" @click="saveSavedReplies">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSavedRepliesTemplates" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('select') }} {{ $t('messageTemplates.fromTemplates') }}</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-3 hide-buton"
            color="error"
            @click="dialogSavedRepliesTemplates = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveSavedRepliesTemplates"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-select
            v-model="savedRepliesTemplate"
            :hint="savedRepliesTemplate ? savedRepliesTemplate.message : ''"
            :items="savedRepliesTemplates"
            item-text="template_name"
            label="Message Templates"
            persistent-hint
            return-object
          ></v-select>
          <v-btn color="primary" @click="showHideMessageTemplate()">
            <v-icon dark left>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ $t('create') }} {{ $t('new') }} {{ $t('messageTemplatesRead') }}
          </v-btn>
        </v-card-text>
        <messageTemplate ref="message-template" @close-dialog="looading.table = true" />

        <v-divider />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="error" @click="dialogSavedRepliesTemplates = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" @click="saveSavedRepliesTemplates">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogShownSavedReplies" width="600px">
      <v-card>
        <v-card-actions>
          <div class="flex">
            <h3 class="font-weight-bold">
              {{ $t('inbox.quickReplies') }}
            </h3>
            <p>
              {{ $t('inbox.quickRepliesNote') }}
            </p>
          </div>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialogShownSavedReplies = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <div class="">
            <v-btn color="primary" class="" @click="openDialogSavedReplies('new')">
              <!-- <v-icon left>
                {{ icons.mdiPlus }}
              </v-icon> -->
              {{ $t('add') }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="dialogSavedRepliesTemplates = true">
              <!-- <v-icon left>
                {{ icons.mdiPlus }}
              </v-icon> -->
              {{ $t('messageTemplates.fromTemplates') }}
            </v-btn>
          </div>
          <!-- <v-row class="bottom-action">
            <v-col cols="12">
              <v-btn
                color="primary"
                class="block-condition"
                @click="openDialogSavedReplies('new')"
              >
                <v-icon left>
                  {{ icons.mdiPlus }}
                </v-icon>
                {{ $t('add') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="block-condition"
                color="primary"
                @click="dialogSavedRepliesTemplates = true"
              >
                <v-icon left>
                  {{ icons.mdiPlus }}
                </v-icon>
                {{ $t('messageTemplates.fromTemplates') }}
              </v-btn>
            </v-col>
          </v-row> -->

          <v-list v-if="savedReplies && savedReplies.value && savedReplies.value.length > 0">
            <v-list-item v-for="replies in savedReplies.value" :key="replies.id">
              <v-list-item-content>
                <v-list-item-title v-if="replies.message_templates">
                  <span class="font-weight-bold">/{{ replies.message_templates.template_name }}</span>
                  <span>&nbsp;{{ replies.message_templates.template_name }}</span>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span v-if="replies.tag" class="font-weight-bold">/{{ replies.tag }}</span>
                  <span>&nbsp;{{ replies.name }}</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-btn v-if="!replies.message_templates" icon @click.stop="openDialogSavedReplies('edit', replies)">
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
                <v-btn icon @click.stop="deleteSavedReplies(replies.id, replies.message_templates)">
                  <v-icon>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNotes" width="600px" min-width="300px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ isNewModeNotes ? $t('add') : $t('edit') }} {{ $t('note') }}</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-3 hide-buton"
            color="error"
            @click="dialogNotes = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveNotes"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-textarea v-model="notesName" dense outlined rows="9"></v-textarea>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-btn class="mr-3" color="error" @click="dialogNotes = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="" @click="saveNotes">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLabels" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('select') }} Label</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-3 hide-buton"
            color="error"
            @click="dialogLabels = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveLabels"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <!-- <v-card-text class="bottom-action">
          <v-row>
            <v-col cols="12">
              <v-btn
                color="success"
                class="block-condition"
                @click="saveLabels"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="block-condition"
                color="error"
                @click="dialogLabels = false"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text> -->
        <v-divider />
        <v-card-text class="height-dialog">
          <v-list :key="keyLabels" flat>
            <v-list-item v-for="label in labels" :key="label.id">
              <v-list-item-action>
                <v-checkbox v-model="selectedLabels" :value="label"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :color="label.color">
                    {{ icons.mdiLabel }}
                  </v-icon>
                  {{ label.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <!-- <h3 class="font-weight-bold">
            {{ $t('select') }} Label
          </h3> -->
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="error" @click="dialogLabels = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="" @click="saveLabels">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogContactList" width="600">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('edit') }} {{ $t('Contact List') }}</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-3 hide-buton"
            color="error"
            @click="dialogContactList = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveContactList"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field v-model="contactListName" label="Name"></v-text-field>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="error" @click="dialogContactList = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="" @click="saveContactList">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddRoom" width="900">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('inbox.newChat') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn class="hide-buton" icon @click="dialogAddRoom = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-select
            v-model="selectedInstanceRoom"
            outlined
            :items="optionsInstancesRoom"
            item-text="label"
            label="Channels"
            dense
            return-object
            hide-details
            :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
            class="mb-4"
          >
            <template slot="item" slot-scope="{ item }">
              <div v-if="item.value.color" class="state-circle" :style="{ 'background-color': item.value.color }" />
              {{ item.label }}
            </template>
          </v-select>
          <v-expansion-panels v-model="panelContactsRoom" accordion focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('inbox.enterPhoneNumber') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form @submit.prevent="actionNewChat">
                  <v-text-field
                    v-model="phoneNumberContactRoom"
                    :placeholder="$t('phoneNumberInput')"
                    :append-icon="icons.mdiSendOutline"
                    :loading="loadingPhoneNumberContactRoom"
                    hide-details
                    type="text"
                    autofocus
                    @click:append="actionNewChat"
                  >
                    <template v-slot:prepend>
                      <vue-country-code
                        :default-country="'id'"
                        :disabled-fetching-country="true"
                        @onSelect="onSelectCountryCode"
                      ></vue-country-code>
                    </template>
                  </v-text-field>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('inbox.selectFromExistingContact') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select
                  v-model="contactListRoom"
                  outlined
                  :items="optionsContactListsRoom"
                  item-text="list_name"
                  label="Contact List"
                  dense
                  return-object
                  hide-details
                  :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
                  class="mb-4"
                  @change="changeContactListRoom"
                ></v-select>
                <v-data-table
                  v-model="selectedContactRoom"
                  :headers="headerContactsRoom"
                  :items="tableContactsRoom"
                  :single-select="true"
                  show-select
                  :items-per-page="5"
                  :footer-props="{
                    'items-per-page-text': $t('rowsPerPageLabel'),
                    'items-per-page-options': [5, 10, 30, 50, 100],
                  }"
                  :search="searchContactRoom"
                  class="table-contacts"
                  @item-selected="actionNewChat"
                >
                  <template v-slot:top>
                    <v-row class="justify-end mb-2">
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="searchContactRoom"
                          :label="$t('search')"
                          outlined
                          dense
                          single-line
                          hide-details
                          :append-icon="icons.mdiMagnify"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                  <template slot="no-data">
                    {{ $t('noDataLabel') }}
                  </template>
                  <template #[`item.name`]="{ item }">
                    <div class="display-lg align-center">
                      <v-avatar v-if="item ? (item.profile_picture ? true : false) : false" size="40">
                        <v-img :src="item.profile_picture || ''" height="40" width="40"> </v-img>
                      </v-avatar>
                      <div class="display-lg flex-column ms-3 display-mt-lg">
                        <span class="d-block font-weight-semibold text-truncate text--primary sm-text">{{
                          item.name
                        }}</span>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div id="chat">
      <v-row no-gutters>
        <v-col cols="12" style="position: relative; z-index: 1">
          <v-overlay :absolute="true" :z-index="11" :value="overlayDownload">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-overlay :z-index="11" :value="isUploadingAttachment">
            <div style="width: 30vw">
              <span class="primary--text">{{ $t('inbox.uploading') }}</span>
              <v-progress-linear :value="uploadingAttachmentProgress" height="25" color="primary">
                <strong>{{ Math.ceil(uploadingAttachmentProgress) }}%</strong>
              </v-progress-linear>
            </div>
          </v-overlay>
          <chat-window
            :key="keyAdvancedChat"
            ref="advancedChat"
            :show-footer="showFooter"
            :single-room="true"
            :reply_history="true"
            :current-action="currentAction"
            :current-user-id="currentUserId"
            height="100%"
            :load-first-room="false"
            :loading-rooms="loadingRooms"
            :menu-actions="menuActions"
            :message-actions="messageActions"
            :messages="messages"
            :messages-loaded="messagesLoaded"
            :quick-replies="quickReplies"
            :room-info-enabled="true"
            :rooms="loadedRooms"
            :rooms-grouped="roomsNewContactsFiltered"
            :rooms-origin="roomsOrigin"
            :room-id="roomId"
            :selected-room="selectedRoomObject"
            :rooms-loaded="roomsLoaded"
            :show-add-room="showAddRoom"
            :show-filter-room="showFilterRoom"
            :show-new-messages-divider="false"
            :show-reaction-emojis="false"
            :status-instances="statusInstancesList"
            :theme="theme"
            :is-uploading-attachment="isUploadingAttachment"
            :text-formatting="true"
            :show-checkbox="showCheckbox"
            :inbox-page="true"
            :assign-to-user-list="assignToUserList"
            :load-send-message="loadSendMessage"
            :all-contacts="contactListTable"
            :templates-text="templatesText"
            @change-rooms-loaded="changeRoomsLoaded"
            @filter-new-contacts="filterNewContacts"
            @fetch-messages="fetchMessages"
            @send-message="sendMessage"
            @open-file="openFile"
            @menu-action-handler="menuActionHandler"
            @message-action-handler="messageActionHandler"
            @select-messages-action-handler="selectMessagesActionHandler"
            @add-room="addRoomDialog"
            @fetch-more-rooms="fetchMoreRooms"
            @update-room="updateRoom"
            @open-user-tag="openUserTag"
          >
            <template #messages-empty>
              <div class="vac-text-started">
                {{ $t('inbox.chatEmpty') }}
              </div>
            </template>
            <template #rooms-empty>
              <div class="vac-rooms-empty">
                {{ $t('inbox.contactEmpty') }}
              </div>
            </template>
            <template #no-room-selected>
              <div class="vac-container-center vac-room-empty">
                <div>{{ $t('inbox.noChatSelected') }}</div>
              </div>
            </template>
          </chat-window>
          <div :class="`menu-quick-reply ${isDark === true ? 'dark-bg' : 'light-bg'}`">
            <div
              v-if="isDark === true"
              style="
                text-align: left;
                background-color: #323232;
                cursor: pointer;
                padding: 8px;
                border: 0.5px solid #323232;
                font-size: 12px;
                font-weight: 600;
                color: white;
              "
            >
              <v-icon color="primary">
                {{ icons.mdiMessagePlus }}
              </v-icon>
              Quick Message
            </div>
            <div
              v-else
              style="
                text-align: left;
                background-color: white;
                cursor: pointer;
                padding: 8px;
                border: 0.5px solid #efefef;
                font-size: 12px;
                font-weight: 600;
                color: black;
              "
            >
              <v-icon color="primary">
                {{ icons.mdiMessagePlus }}
              </v-icon>
              Quick Message
            </div>
            <div v-for="replies in savedReplies.value" :key="replies.id">
              <button
                v-if="isDark === true"
                small
                style="
                  text-align: left;
                  background-color: #323232;
                  cursor: pointer;
                  padding: 8px;
                  border: 0.5px solid #323232;
                  font-size: 12px;
                  font-weight: 600;
                  width: 100%;
                "
                @click="putSavedReplies(replies)"
              >
                {{ replies.message_templates ? '(Template) ' + replies.message_templates.template_name : replies.name }}
              </button>
              <button
                v-else
                small
                style="
                  text-align: left;
                  background-color: white;
                  cursor: pointer;
                  padding: 8px;
                  border: 0.5px solid #efefef;
                  font-size: 12px;
                  font-weight: 600;
                  width: 100%;
                "
                @click="putSavedReplies(replies)"
              >
                {{ replies.message_templates ? '(Template) ' + replies.message_templates.template_name : replies.name }}
              </button>
            </div>
          </div>
        </v-col>
        <v-col :class="`${isShownSidebar === true ? 'bg-visible' : 'bg-visible hide'}`" class="sidebar">
          <div v-show="isShownSidebar" id="background-sidebar" :class="classSidebar">
            <v-row>
              <v-col cols="auto mr-auto"></v-col>
              <v-col cols="auto">
                <v-btn color="primary" icon small @click="toggleSidebar">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <v-card color="text-center">
                  <v-card-text class="d-flex flex-column justify-center align-center py-0">
                    <v-avatar color="primary" class="v-avatar-light-bg primary--text mt-4" icon size="70">
                      <v-img :src="contactDetail.profile_picture"></v-img>
                    </v-avatar>
                    <h6 class="text-xl mt-3 font-weight-medium">
                      {{ contactDetail.name }}
                    </h6>
                    <span class="text-lg">
                      {{ contactDetail.phone_number_show }}
                    </span>
                  </v-card-text>
                  <v-card-text>
                    <v-btn icon @click="changePinned">
                      <v-icon v-if="contactDetail.pinned">
                        {{ icons.mdiPin }}
                      </v-icon>
                      <v-icon v-else>
                        {{ icons.mdiPinOff }}
                      </v-icon>
                    </v-btn>
                    <v-btn color="primary" icon @click="datepickerDownloadMessageDialog = true">
                      <v-icon color="primary">
                        {{ icons.mdiDownload }}
                      </v-icon>
                    </v-btn>
                    <v-dialog v-model="datepickerDownloadMessageDialog" width="290px">
                      <v-card>
                        <v-card-text>
                          <v-dialog v-model="datepickerFromDownloadMessageDialog" width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fromDownloadMessages"
                                label="From"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fromDownloadMessages"
                              color="primary"
                              @input="inputFromDownloadMessage"
                            ></v-date-picker>
                          </v-dialog>
                          <v-dialog v-model="datepickerToDownloadMessageDialog" width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="toDownloadMessages"
                                label="To"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="toDownloadMessages"
                              color="primary"
                              :min="fromDownloadMessages"
                              @input="datepickerToDownloadMessageDialog = false"
                            ></v-date-picker>
                          </v-dialog>
                          <v-btn
                            v-if="fromDownloadMessages && toDownloadMessages"
                            color="primary"
                            block
                            @click="downloadMessages"
                          >
                            {{ $t('downloadMessages') }}
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <v-card v-if="contactDetail && contactDetail.last_interaction" :dark="isDark" class="mb-5">
                  <v-card-actions class="d-flex">
                    <span class="ms-3">{{ $t('inbox.assignedTo') }}{{ contactDetail.last_interaction.name }}</span>

                    <v-avatar size="34" class="ms-3">
                      <v-img :src="contactDetail.last_interaction.prof_pic"></v-img>
                    </v-avatar>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <v-card v-if="selectedRoom && contactDetail" :dark="isDark">
                  <v-card-title>{{ $t('notes') }}</v-card-title>
                  <v-card-text>
                    <v-btn color="primary" @click="openDialogNotes('new')">
                      <v-icon left>
                        {{ icons.mdiPlus }}
                      </v-icon>
                      {{ $t('add') }}
                    </v-btn>

                    <div v-for="note in contactDetail.notes" :key="note.id" class="mt-3">
                      <div class="card-note">
                        <div style="white-space: pre-wrap; word-break: break-all">
                          <span v-html="urlify(note.name)"></span>
                        </div>
                        <div class="mt-3 justify-end d-flex">
                          <div v-if="note.updated_at && note.updated_by">
                            <v-tooltip :open-on-click="true" :open-on-hover="false" bottom class="me-3">
                              <template #activator="{ on, attrs }">
                                <v-icon v-bind="attrs" class="me-3" @click="on.click">
                                  {{ icons.mdiInformation }}
                                </v-icon>
                              </template>
                              <span
                                >Updated at {{ $moment.unix(note.updated_at).format('DD/MM/YYYY') }} by
                                {{ note.updated_by }}</span
                              >
                            </v-tooltip>
                          </div>
                          <div>
                            <v-icon class="me-3" @click.stop="openDialogNotes('edit', note)">
                              {{ icons.mdiPencil }}
                            </v-icon>
                          </div>
                          <div>
                            <v-icon @click.stop="deleteNotes(note.id)">
                              {{ icons.mdiDelete }}
                            </v-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="getContactList(selectedRoom).length > 0" class="mt-0">
              <v-col cols="12">
                <v-card v-if="selectedRoom && contactDetail" class="mt-2" :dark="isDark">
                  <v-card-title>{{ 'List ' + $t('Contact') }}</v-card-title>
                  <v-card-text>
                    <div v-for="list in getContactList(selectedRoom)" :key="list.list_id" class="mt-3">
                      <v-btn color="primary" @click="openContactList(list.list_id)">
                        <v-icon left>
                          {{ icons.mdiEyeOutline }}
                        </v-icon>
                        {{ list.list_name }}
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingDialogText }} {{ loadingIndeterminate ? '' : `(${Math.ceil(percentageLoading)}%)` }}
          <v-progress-linear v-model="percentageLoading" height="5" color="white" :indeterminate="loadingIndeterminate">
          </v-progress-linear>
        </v-card-text>
        <v-card-text>
          <v-btn
            v-if="showSkip"
            rounded
            small
            right
            light
            class="offset-lg-8 offset-md-8 offset-sm-8 offset-xs-6 offset-xl-8 offset-8"
            @click="skipDownload"
          >
            Skip
            <v-icon small right>
              {{ icons.mdiSkipForward }}
            </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiArrowLeft,
  mdiClose,
  mdiDelete,
  mdiDeleteOutline,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiLabel,
  mdiMenu,
  mdiMessagePlus,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
  mdiEyeOutline,
  mdiReply,
  mdiStar,
  mdiStarOutline,
  mdiInformation,
  mdiMagnify,
  mdiSendOutline,
  mdiSkipForward,
  mdiDownload,
  mdiPin,
  mdiPinOff,
} from '@mdi/js'
import * as firestoreService from '@demo/database/firestore.multiDBv2.js'
import * as firebaseService from '@demo/database/firebase'
import * as storageService from '@demo/database/storage'
import { parseTimestamp, formatTimestamp } from '@demo/utils/dates'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import '@/plugins/vue-advanced-chat/styles/vue-advanced-chat.css'
import _isEmpty from 'lodash/isEmpty'
import _uniq from 'lodash/uniq'

// import _cloneDeep from 'lodash/cloneDeep'
import useAppConfig from '@core/@app-config/useAppConfig'
import Vue from 'vue'
import VueCountryCode from 'vue-country-code'
import ChatWindow from '@/plugins/vue-advanced-chat/lib/ChatWindow'
import inboxMixin from '@/mixins/inboxMixin'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import firestoreDbMixin from '@/mixins/firestoreDbMixin'
import lokijsMixin from '@/mixins/lokijsMixin'
import eOrderingMixin from '@/mixins/e-orderingMixin'
import utilsMixin from '@/mixins/utilsMixin'
import AlertDialog from '@/components/dialog/AlertDialog.vue'
import messageTemplate from '@/components/dialog/message_template/message_template.vue'

Vue.use(VueCountryCode)

const sound = require('@/assets/sound/ting.wav')

export default {
  name: 'AdvancedChat',
  setup() {
    const { isDark } = useAppConfig()
    let theme = ''

    if (isDark.value) {
      theme = 'dark'
    } else {
      theme = 'light'
    }

    return {
      isDark,
      theme,

      icons: {
        mdiStar,
        mdiStarOutline,
        mdiPin,
        mdiPinOff,
        mdiClose,
        mdiMenu,
        mdiPlus,
        mdiEyeOutline,
        mdiPencil,
        mdiDelete,
        mdiDotsHorizontal,
        mdiArrowLeft,
        mdiDotsVertical,
        mdiReply,
        mdiLabel,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiMessagePlus,
        mdiInformation,
        mdiMagnify,
        mdiSendOutline,
        mdiSkipForward,
        mdiDownload,
      },
    }
  },
  components: {
    ChatWindow,
    AlertDialog,
    messageTemplate,
  },

  mixins: [pouchDbMixin, inboxMixin, eOrderingMixin, firestoreDbMixin, lokijsMixin, utilsMixin],
  data() {
    return {
      currentRoomContainer: [],
      lastMessages: null,
      isLoadFirestore: false,
      isFetchMessages: false,

      overlayGlobal: false,
      loadSendMessage: false,

      totalContact: 0,
      contactListTable: [],
      roomSpliced: [],
      roomsNewContacts: [],
      roomsNewContactsFiltered: [],

      allChatsCouch: [],
      mountedTime: null,
      allContactInbox: [],
      notifications: [],
      lastNotif: null,
      sound,
      overlayDownload: false,
      dialogWarningNotConnect: false,

      // alert
      alertDialog: false,
      alertTitle: null,
      alertText: null,

      // screen
      screen: {
        height: '1vh',
        width: 0,
      },

      // side bar
      isShownSidebar: false,
      fabSavedReplies: false,
      menu: null,

      // loading
      loadingEdit: false,
      errorAlerh: false,

      // vac
      keyAdvancedChat: 0,
      currentUserId: 'all',
      currentRoomId: null,
      currentRoom: null,
      currentPerson: null,
      currentContact: null,
      rooms: [],
      roomsOrigin: [],
      roomsFiltered: [],
      roomsPage: 1,

      // roomsPerPage: 10,
      roomsTotalPages: 0,

      // roomsLoaded: false,
      // loadingRooms: false,
      // messages: [],
      // messagesLoaded: false,
      firstMessage: null,
      messageFile: [],
      dataFile: [],
      attachments: [],
      currentMessage: null,
      currentMessages: [],
      currentAction: null,
      limitChatList: 25,
      dynamicLimit: 25,
      isUploadingAttachment: false,
      uploadingAttachmentProgress: 30,
      showAddRoom: false,
      showFilterRoom: false,
      showCheckbox: false,
      showFooter: false,

      /// from container
      roomsPerPage: 15,

      // rooms: [],
      roomId: '',
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoom: null,
      selectedRoomObj: {},
      messagesPerPage: 1,
      messages: [],
      messagesLoaded: false,
      roomMessage: '',
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      roomActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
      styles: { container: { borderRadius: '4px' } },

      // add room
      dialogAddRoom: false,
      panelContactsRoom: 0,
      headerContactsRoom: [],
      selectedContactRoom: [],
      selectedInstanceRoom: null,
      contactListRoom: null,
      contactListsRoom: null,
      optionsContactListsRoom: [],
      contactsRoom: null,
      tableContactsRoom: [],
      searchContactRoom: null,
      phoneNumberContactRoom: null,
      loadingPhoneNumberContactRoom: false,
      countryCodeContactRoom: '62',

      // settings
      settingsPouch: [],

      // contact
      contacts: [],
      contactsPouch: [],
      columns: [],
      settingFilter: [],
      selectedSettingFilter: null,

      // saved replies
      savedReplies: [],
      messageTemplates: [],
      dialogShownSavedReplies: false,
      dialogSavedReplies: false,
      dialogSavedRepliesTemplates: false,
      activeSavedReplies: null,
      isNewModeSavedReplies: false,
      savedRepliesTag: null,
      savedRepliesName: null,
      savedRepliesTemplate: null,

      // notes
      dialogNotes: false,
      dialogShowNotes: false,
      isNewModeNotes: false,
      notesName: null,
      activeNotes: null,

      // instances
      instances: [],
      optionsInstances: [],
      allSenderInstance: true,
      selectedInstance: {
        phone_number: null,
      },
      selectedInstanceVal: null,
      statusInstances: null,
      statusInstancesInterval: null,
      statusInstancesAlert: false,
      statusInstancesList: [],

      // label
      label: null,
      labels: [],
      labelsOrigin: [],
      dialogLabels: false,
      selectedLabels: [],
      keyLabels: 0,

      //  contact list
      contactList: null,
      activeContactList: null,
      contactListName: null,
      dialogContactList: false,

      // Loading
      loadingDialog: false,
      loadingDialogText: '',
      loadingIndeterminate: true,
      percentageLoading: 0,
      lastDiffCount: 0,
      retryCount: 0,
      loadingInterval: null,
      waitingReplicate: false,
      showSkip: false,

      // export messages
      datepickerDownloadMessageDialog: false,
      datepickerFromDownloadMessageDialog: false,
      datepickerToDownloadMessageDialog: false,
      fromToDownloadMessages: null,
      fromDownloadMessages: null,
      toDownloadMessages: null,

      // shared rooms
      dialogSharedRoom: false,
      sharedRoomExpiryTime: {
        label: `1 ${this.$t('week')}`,
        unit: 'week',
        val: 1,
      },
      sharedRoomExpiryTimeItems: [
        {
          label: `1 ${this.$t('day')}`,
          unit: 'day',
          val: 1,
        },
        {
          label: `1 ${this.$t('week')}`,
          unit: 'week',
          val: 1,
        },
        {
          label: `1 ${this.$t('month')}`,
          unit: 'month',
          val: 1,
        },
        {
          label: `${this.$t('never')}`,
          unit: 'never',
          val: 0,
        },
      ],
      sharedRoomType: 'view_only',
      sharedRoomTypeItems: [
        {
          label: 'View Only',
          value: 'view_only',
        },
        {
          label: 'Can Reply',
          value: 'can_reply',
        },
      ],
      sharedRoomUrl: null,
      sharedRoomActive: null,

      // msgTemplate
      dialog: {
        addMessageTEmplate: false,
        category: false,
        categoryDelete: false,
      },
      status_message_template: 'add',
      dataIndex: 0,
      formAddTemplate: {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: '',
        last_update: '',
        attachments: [],
        id: '',
      },
      propsUppyUploader: null,
      categoryName: '',
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.advancedChat.isMobile) {
      if (_isEmpty(this.advancedChat.room)) {
        next()
      } else {
        this.advancedChat.toggleRoomsList()
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    /// ////////////// START COMPUTED VAC
    listInstances() {
      const inst = this.$store.getters['auth/getListInstance']
      if (inst) {
        return inst
      }

      return []
    },
    subId() {
      return this.$store.getters['auth/getUser'].sub_id
    },
    labelFilter() {
      return this.$store.getters['inbox/getLabelFilter']
    },
    debugRoom() {
      // const foundRoom = this.rooms.find(item => item._id == 'zx37jt9h-6285222221135')

      // if (foundRoom) {
      //   return foundRoom
      // }

      return null
    },
    loadedRooms() {
      let filteredRooms = JSON.parse(JSON.stringify(this.rooms))

      filteredRooms = filteredRooms.filter(room => room.archived == false)

      // filter channel
      if (this.getValueChannel && this.getValueChannel.value) {
        if (!this.getValueChannel.value.all_sender) {
          filteredRooms = this.rooms.filter(room => room.instance_id == this.getValueChannel.value._id)
        }
      }

      // filter room
      if (this.filterChat == 'assigned') {
        filteredRooms = this.rooms.filter(
          room =>
            room.assign_to != null &&
            room.assign_to != false &&
            room.assign_to.email == this.user.email &&
            room.archived == false,
        )
      } else if (this.filterChat == 'unreplied') {
        filteredRooms = this.rooms.filter(room => room.unreplied == true && room.archived == false)
      } else if (this.filterChat == 'unread') {
        filteredRooms = this.rooms.filter(room => room.unread_count > 0 && room.archived == false)
      }

      if (this.labelFilter.length !== 0) {
        let filteredLabelRooms = []

        filteredRooms.forEach(room => {
          this.labelFilter.forEach(lf => {
            if (room.labels.length !== 0) {
              room.labels.forEach(lr => {
                if (lr.labelName === lf) {
                  filteredLabelRooms.push(room)
                }
              })
            }
          })
        })

        filteredLabelRooms = _orderBy(filteredLabelRooms, ['pinned', 'index'], ['desc', 'desc'])

        return _uniq(filteredLabelRooms)
      }

      filteredRooms = _orderBy(filteredRooms, ['pinned', 'index'], ['desc', 'desc'])

      return filteredRooms
    },
    roomsGrouped() {
      let roomsGrouped = {}

      // group by key unreplied
      roomsGrouped = _groupBy(this.loadedRooms, room => room.unreplied)

      if (roomsGrouped.true) {
        delete Object.assign(roomsGrouped, { Unreplied: roomsGrouped.true }).true
      }

      if (roomsGrouped.false) {
        delete Object.assign(roomsGrouped, { Replied: roomsGrouped.false }).false
      }

      return roomsGrouped
    },
    screenHeight() {
      return this.isDevice ? `${window.innerHeight}px` : 'calc(100vh - 80px)'
    },
    usersPath() {
      if (this.pathId === '') return 'users'

      return `users-${this.pathId}`
    },
    roomsPath() {
      if (this.subId) {
        return `chatRooms/${this.subId}/listRooms`
      }

      return `chatRooms`
    },
    selectedRoomObject() {
      if (this.selectedRoom) {
        const room = this.roomsOrigin.find(item => item.roomId == this.selectedRoom)

        if (room) {
          return room
        }
      }

      return null
    },

    /// ////////////// END COMPUTED VAC
    messageActions() {
      return [
        {
          name: 'replyMessage',
          title: this.$t('reply'),
        },
        {
          name: 'labelMessage',
          title: `Label ${this.$t('message')}`,
        },
        {
          name: 'noteMessage',
          title: `${this.$t('inbox.noteMessage')}`,
        },
      ]
    },
    optionsInstancesRoom() {
      if (this.optionsInstances && this.optionsInstances.length > 0) {
        return this.optionsInstances.filter(item => item.value.phone_number != null)
      }

      return []
    },
    menuActions() {
      const menuActions = [
        {
          name: 'selectMessages',
          title: `${this.$t('inbox.selectMessages')}`,
        },
        {
          name: 'addLabel',
          title: `${this.$t('add')} label`,
        },
        {
          name: 'quickReply',
          title: `${this.$t('inbox.quickRepliesAction')}`,
        },
        {
          name: 'archiveContact',
          title: `${this.$t('inbox.archiveContact')}`,
        },
      ]

      const indexArchiveContact = menuActions.findIndex(action => action.name == 'archiveContact')

      if (indexArchiveContact >= 0) {
        menuActions[indexArchiveContact].title = this.$t('inbox.archiveContact')

        if (this.contactDetail) {
          if (this.contactDetail.archived) {
            if (this.contactDetail.archived == 1) {
              menuActions[indexArchiveContact].title = this.$t('inbox.unarchiveContact')
            }
          }
        }
      }

      // check package
      if (this.user.package == 'business' || this.user.package == 'business_plus') {
        menuActions.push({
          name: 'shareRoom',
          title: `${this.$t('inbox.shareRoom')}`,
        })
      }

      return menuActions
    },
    onGoingReplicateContact() {
      return this.$store.getters[`global/getReplicateStatus`].contacts
    },
    filterChat() {
      return this.$store.getters['global/getFilterChat']
    },
    autoCloseChat() {
      return this.$store.getters['global/getAutoCloseChat']
    },
    stealthMode() {
      return this.$store.getters['global/getBoldUnreplied']
    },
    autoAssignChatOnReply() {
      return this.$store.getters['global/getAutoAssignChatOnReply']
    },
    roomsVuex() {
      return this.allRoomsGlobal
    },
    contactsVuex() {
      return this.allContactsGlobal
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    advancedChat() {
      if (this.$refs.advancedChat) {
        return this.$refs.advancedChat
      }

      return null
    },
    contactDetail() {
      if (this.selectedRoom) {
        const room = this.rooms.find(item => item.roomId == this.selectedRoom)

        if (room) {
          // map phone number show group
          if (room.phone_number_show) {
            if (room.phone_number_show.includes('-') || room.phone_number_show.length > 16) {
              room.phone_number_show = 'Group'
            }
          }

          return room
        }
      }

      return {}
    },
    shownContactDetail() {
      const detail = {}
      const columnsEditable = []

      this.columnsEditable.forEach(col => {
        if (col.field != 'name') {
          columnsEditable.push(col.field)
        }
      })

      for (const key in this.contactDetail) {
        if (Object.hasOwnProperty.call(this.contactDetail, key)) {
          const value = this.contactDetail[key]

          if (columnsEditable.includes(key)) {
            detail[key] = value
          } else if (key == 'phone_number') {
            if (!value.includes('-')) {
              detail[key] = value
            }
          }
        }
      }

      return detail
    },
    phoneNumberContactsPouch() {
      return this.contactsPouch.reduce((acc, item) => acc.set(item.phone_number, item), new Map())
    },
    columnsEditable() {
      return this.columns.filter(
        col => col.editable && col.name !== 'phone_number' && col.field !== 'status' && col.field !== 'label',
      )
    },
    notesContact() {
      let phoneNumber = null
      let notesContact = null

      if (this.currentPerson) {
        phoneNumber = this.currentPerson.substr(0, this.currentPerson.indexOf('@'))
        notesContact = this.phoneNumberContactsPouch.get(phoneNumber)
      }

      return notesContact || []
    },
    colSidebar() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 12
      }

      if (this.isShownSidebar) {
        return 9
      }

      return 12
    },
    heightChat() {
      return this.screen.height
    },
    classSidebar() {
      return {
        'background-sidebar dark-bg': this.isDark === true,
        'background-sidebar light-bg': this.isDark === false,
      }
    },
    labelStatusInstanceAlert() {
      const labels = []

      this.statusInstancesList.forEach(instance => {
        if ((instance.status == 0 || instance.status == 3) && instance.phone_number) {
          labels.push(instance.label)
        }
      })

      if (labels.length > 0) {
        return `Channel ${labels.join(', ')} is not connected, please check your device connection`
      }

      return ''
    },
    quickReplies() {
      const quickReplies = []

      if (this.savedReplies && this.savedReplies.value) {
        const savedReplies = JSON.parse(JSON.stringify(this.savedReplies.value))

        savedReplies.forEach(item => {
          if (item.tag === undefined) {
            item.tag = ''
          }

          if (item.name === undefined) {
            item.name = item.text
            delete item.text
          }

          if (item.message_templates) {
            item.tag = item.message_templates.template_name
          }

          quickReplies.push(item)
        })
      }

      return quickReplies
    },
    templatesText() {
      const templatesText = []

      if (this.savedReplies && this.savedReplies.value) {
        const savedReplies = JSON.parse(JSON.stringify(this.savedReplies.value))

        savedReplies.forEach(item => {
          if (item.tag === undefined) {
            item.tag = ''
          }

          if (item.text === undefined) {
            item.text = item.name
            delete item.name
          }

          if (item.message_templates) {
            item.tag = item.message_templates.template_name
          }

          templatesText.push(item)
        })
      }

      return templatesText
    },
    savedRepliesTemplates() {
      if (this.messageTemplates) {
        if (this.messageTemplates.value) {
          return this.messageTemplates.value.filter(item => item.quick_reply === true)
        }
      }

      return []
    },
    setStoreChannel() {
      return this.optionsInstances
    },
    setStoreContact() {
      return this.settingFilter
    },
    getValueChannel() {
      return this.$store.state.inboxNavbar.valueChannel
    },
    getValueContact() {
      return this.$store.state.inboxNavbar.valueContact
    },
    contactListItems() {
      const contactListItems = []

      if (this.contactList && this.contactList.value && this.contactList.value.length > 0) {
        this.contactList.value.forEach(list => {
          if (this.currentRoom) {
            // get contact by list
            const contactId = list.id + this.currentRoom.contact.phone_number
            const contact = this.contactsPouch.find(item => item._id == contactId)

            if (contact) {
              // get column by list
              const fields = {}
              const columns = []
              const columnId = `${list.id}columns`
              const columnsPouch = this.settingsPouch.find(item => item.key == columnId)

              if (columnsPouch && columnsPouch.value && columnsPouch.value.length > 0) {
                columnsPouch.value.forEach(col => {
                  if (col.field != 'name' && col.field != 'phone_number' && col.field != 'phone_number_show') {
                    columns.push(col.field)
                  }
                })

                for (const key in contact) {
                  if (Object.hasOwnProperty.call(contact, key)) {
                    const value = contact[key]

                    if (columns.includes(key)) {
                      fields[key] = value
                    }
                  }
                }
              }

              const obj = {
                ...list,
                fields,
              }

              contactListItems.push(obj)
            }
          }
        })
      }

      return contactListItems
    },
    assignToUserList() {
      const userList = this.$store.getters['auth/getListUsers']
      const operators = []

      userList.forEach(user => {
        const operator = {}

        operator.email = user.email
        operator.phone_number = ''
        operator.image = ''
        operator.prof_pic = ''
        operator.name = ''

        operators.push(operator)
      })

      return operators
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
  },
  watch: {
    isShownSidebar(varnew) {
      this.$store.commit('inbox/setSidebarRight', varnew)
    },
    contactDetail(varnew) {
      // open right bar if name desktop
      // if (this.$vuetify.breakpoint.smAndDown === false) {
      //   if (Object.keys(varnew).length !== 0) {
      //     this.isShownSidebar = false
      //     this.toggleSidebar('desktop')
      //   }
      // }
    },
    setStoreChannel(varnew) {
      this.$store.commit('inboxNavbar/setChannel', varnew)
    },
    setStoreContact(varnew) {
      this.$store.commit('inboxNavbar/setContact', varnew)
    },
    getValueChannel(varnew) {
      this.changeInstance(varnew)
    },
    getValueContact(varnew) {
      // this.selectedSettingFilter = varnew
      // this.changeSettingFilter(varnew)
    },
    filterChat(varnew) {
      this.changeSettingFilter(varnew)
    },
    async labelFilter(label) {
      this.selectedRoom = null
      this.currentUserId = 'all'
      this.roomId = null
      this.resetMessages()
    },
  },
  beforeDestroy() {
    const self = this
    clearInterval(self.statusInstancesInterval)
    this.$store.dispatch('global/setSyncFromServer', false)
    this.loadingDialog = false
    if (this.loadingInterval) clearInterval(this.loadingInterval)

    // Off event bus
    this.$eventBus.$off('mobile-check-header')
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)

    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CHAT)
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CONTACTS)
    this.$eventBus.$off('showDialogShownSavedReplies')
    this.$eventBus.$off('loadingMoreRooms')
  },
  async mounted() {
    // check shared room id
    const currentHost = `${window.location.protocol}//${window.location.host}`

    if (this.$route.params && this.$route.params.id) {
      const { id } = this.$route.params
      const endpointSharedRoom = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/rooms/get-shared-room-by-id`
      const bodySharedRoom = {
        id,
      }

      await this.$axiosLimit
        .post(endpointSharedRoom, bodySharedRoom)
        .then(async response => {
          if (response.data.status) {
            this.sharedRoomActive = response.data.data

            if (this.sharedRoomActive.expiryTime) {
              const createdAt = this.$moment.unix(this.sharedRoomActive.created_at).format('YYYY-MM-DD HH:mm:ss')
              const expiredAt = this.$moment(createdAt)
                .add(this.sharedRoomActive.expiryTime.val, this.sharedRoomActive.expiryTime.unit)
                .format('YYYY-MM-DD HH:mm:ss')
              const now = this.$moment().format('YYYY-MM-DD HH:mm:ss')

              if (this.$moment(now).isBetween(createdAt, expiredAt, undefined, '[]')) {
                //console.log('shared room active')
              } else {
                window.location.href = currentHost
              }
            }
          } else {
            window.location.href = currentHost
          }
        })
        .catch(error => {
          console.log(error)

          window.location.href = currentHost
        })
    } else {
      window.location.href = currentHost
    }

    this.loadingRooms = true
    this.loadingDialogText = 'Sync Server'
    this.loadingDialog = true

    await this.connectSettings()
    await this.getSettings()
    await this.connectChats(true, true)
    await this.connectContacts(true, true)

    this.mountedTime = this.$moment().unix()

    const replicateContacts = false
    const replicateChats = false

    // this.contactListTable = this.getContactsLoki()
    // const itemRoom = await this.loadContacts(false)
    // this.contactListTable = itemRoom

    // this.allChatsCouch = this.getChatsLoki()

    // if (this.contactListTable.length > 0) {
    //   await this.fetchRooms()
    // }

    // this.loadContacts(true, false, item => {
    //   replicateContacts = true

    // let allChats = []
    // item.forEach(itemLoki => {
    //   delete itemLoki.$loki

    // if (itemLoki.message_container) {
    //   allChats = allChats.concat(itemLoki.message_container)
    // }
    // })
    // this.contactListTable = item

    // this.removeContactsLoki()
    // this.insertContactsLoki(item)
    //   this.fetchRooms()
    // })

    // this.loadChats(true, item => {
    //   replicateChats = true
    //   this.allChatsCouch = item
    //   this.removeChatsLoki()
    //   this.insertChatsLoki(item)
    //   if (replicateContacts && replicateChats) {
    //     this.fetchRooms()
    //   }
    // })

    this.$eventBus.$on('showDialogShownSavedReplies', async payload => {
      this.dialogShownSavedReplies = true
    })

    const lastProcess = new Date().getTime()
    this.fdbGetAllContactInbox(this.user.sub_id)

    // await this.fdbGetAllContactFromRoom(this.user.sub_id)
    // //console.log(new Date().getTime() - lastProcess, ' == room')
    // lastProcess = new Date().getTime()

    if (this.contactListTable.length === 0) {
      this.loadingDialogText = 'Load Rooms'
      const itemRoom = await this.loadContacts(true)
      this.contactListTable = itemRoom
      this.fetchRooms()
    }

    // if (this.allChatsCouch.length === 0) {
    //   this.loadingDialogText = 'Load Chats'
    //   const itemChats = await this.loadChats(true)
    //   if (!replicateChats) this.allChatsCouch = itemChats
    // }

    window.ononline = function () {
      window.location.reload()
    }

    // style
    const data = this.$store.getters['inbox/getBannerAlert']
    const doc = document.getElementById('background-sidebar')

    if (data) {
      doc.classList.add('active-setbar')
    } else {
      doc.classList.remove('active-setbar')
    }

    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_CHAT, async payload => {
    //   // //console.log(payload.doc, ' == CHAT')
    //   if (!payload.doc.from_sync && !payload.doc._deleted) {
    //     if (payload.doc.$loki) {
    //       delete payload.doc.$loki
    //     }
    //     this.updateOrInsertChatsById(payload.doc)
    //     if (this.userGetNotifSound() && !payload.doc.fromMe) {
    //       this.play()
    //       this.lastNotif = new Date().getTime() / 1000
    //     }
    //     const idx = this.rooms.findIndex(x => x.roomId === payload.doc.roomId)
    //     if (idx > -1) {
    //       const lastMessage = this.formatLastMessage(payload.doc, this.rooms[idx], true)

    //       if (lastMessage) {
    //         this.rooms[idx].index = lastMessage.seconds
    //         this.rooms[idx].formattedTimestamp = lastMessage.formattedTimestamp
    //         this.rooms[idx].lastMessage = lastMessage
    //         this.rooms[idx].avatar = this.rooms[idx].profile_picture

    //         const roomsOrdered = _orderBy(this.rooms, ['pinned', 'index'], ['asc', 'desc'])
    //         this.rooms = [...roomsOrdered]
    //       }

    //       if (this.messages.filter(x => x.roomId === payload.doc.roomId).length > 0) {
    //         const lastMessageInside = this.formatMessage(this.rooms[idx], payload.doc, true)
    //         const messageIndex = this.messages.findIndex(m => m._id === lastMessageInside._id)

    //         if (messageIndex === -1) {
    //           this.messages = this.messages.concat([lastMessageInside])
    //         } else {
    //           this.messages[messageIndex] = lastMessageInside
    //           this.messages = [...this.messages]
    //         }

    //         // update read status
    //         if (!this.stealthMode) {
    //           if (lastMessageInside.senderId.length > 16) {
    //             this.setReadMessages(
    //               [lastMessageInside.source_id],
    //               { subId: this.user.sub_id, roomId: this.rooms[idx].roomId },
    //               lastMessageInside.senderId,
    //             )

    //             // //console.log([lastMessageInside.source_id], { subId : this.user.sub_id, roomId : this.rooms[idx].roomId }, lastMessageInside.senderId, "lastMessageInside");
    //           } else {
    //             // //console.log([lastMessageInside.source_id], { subId : this.user.sub_id, roomId : this.rooms[idx].roomId }, "lastMessageInside");
    //             this.setReadMessages([lastMessageInside.source_id], {
    //               subId: this.user.sub_id,
    //               roomId: this.rooms[idx].roomId,
    //             })
    //           }
    //         }
    //       }
    //     } else if (
    //       (payload.doc.roomId && payload.doc.roomId.startsWith(this.currentUserId)) ||
    //       this.currentUserId === 'all'
    //     ) {
    //       // await this.fdbGetAllContactFromRoom(this.user.sub_id)
    //       const idxAll = this.contactListTable.findIndex(x => x.roomId === payload.doc.roomId)

    //       if (idxAll > -1) {
    //         const curRoom = this.contactListTable[idxAll]
    //         const temp = {
    //           index: payload.doc.timestamp._seconds,
    //           avatar: curRoom.profile_picture,
    //           ...curRoom,
    //         }
    //         const lastMessage = this.formatLastMessage(payload.doc, temp, true)

    //         if (lastMessage) {
    //           temp.lastMessage = lastMessage
    //         }
    //         let rooms2 = [temp, ...this.rooms]

    //         // remove duplicate by room id
    //         rooms2 = rooms2.filter(
    //           (room, index, that) =>
    //             index === that.findIndex(t => t !== undefined && room !== undefined && t.roomId === room.roomId),
    //         )

    //         const roomsOrdered = _orderBy(rooms2, ['pinned', 'index'], ['asc', 'desc'])
    //         this.rooms = [...roomsOrdered]

    //         // //console.log(rooms2, ' rooms2')
    //       } else {
    //         // Create Rooms
    //       }
    //     }
    //   }
    // })

    this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_CONTACTS, async payload => {
      const roomIndex = this.rooms.findIndex(room => room.roomId === payload.doc._id)
      const roomOriginIndex = this.roomsOrigin.findIndex(room => room.roomId === payload.doc._id)

      // if (!payload.doc._deleted) {
      //   if (payload.doc.$loki) {
      //     delete payload.doc.$loki
      //   }
      //   this.updateOrInsertContactsById(payload.doc)
      // }
      if (
        this.userGetNotifSound() &&
        !payload.doc.fromMe &&
        payload.doc.lastMessage &&
        payload.doc.lastMessage.content_notification
      ) {
        this.play()
        this.lastNotif = new Date().getTime() / 1000
      }
      if (roomIndex >= 0) {
        if (payload.doc.lastMessage) {
          const lastMessage = this.formatLastMessage(payload.doc.lastMessage, payload.doc)
          this.rooms[roomIndex].lastMessage = lastMessage
          this.rooms[roomIndex].index = lastMessage.seconds
          this.rooms[roomIndex].formattedTimestamp = lastMessage.formattedTimestamp
        }
        this.rooms[roomIndex].unreadCount = payload.doc.unread_count
        this.rooms[roomIndex].avatar = payload.doc.profile_picture
        this.rooms[roomIndex].unreplied = payload.doc.unreplied
        this.rooms[roomIndex].message_container = payload.doc.message_container
        this.rooms[roomIndex].roomId = payload.doc._id
        this.rooms = [...this.rooms]

        if (roomOriginIndex >= 0) {
          this.roomsOrigin[roomOriginIndex] = this.rooms[roomIndex]
          this.roomsOrigin = [...this.roomsOrigin]
        }

        if (this.messages.length > 0) {
          if (this.messages[0].roomId === payload.doc._id && payload.doc.message_container.length > 0) {
            const message = payload.doc.message_container[payload.doc.message_container.length - 1]
            const formattedMessage = this.formatMessage(this.rooms[roomIndex], message, true)
            const messageIndex = this.messages.findIndex(m => m._id === message.id)

            if (messageIndex === -1) {
              this.messages = this.messages.concat([formattedMessage])
            } else {
              this.messages[messageIndex] = formattedMessage
            }

            this.messages = this.messages.filter(
              (message, index, that) => index === that.findIndex(t => t._id === message._id),
            )

            this.messages = _orderBy(this.messages, ['couch_timestamp'], ['asc'])

            this.messages = [...this.messages]
          }
        }
      } else if (payload.doc.lastMessage) {
        const couchDbInstance = await this.getInstanceCouchData(payload.doc.instance_id)
        const lastMessage = this.formatLastMessage(payload.doc.lastMessage, payload.doc)
        const room = {
          ...payload.doc,
          unreplied: payload.doc.unreplied,
          unreadCount: payload.doc.unread_count,
          avatar: payload.doc.profile_picture,
          roomId: payload.doc._id,
          lastMessage,
          instance: couchDbInstance,
          index: lastMessage.seconds,
          formattedTimestamp: lastMessage.formattedTimestamp,
        }

        this.contactListTable.push(room)

        let rooms = JSON.parse(JSON.stringify(this.rooms))

        const emptyRoomIndex = rooms.findIndex(room => room.roomId === payload.doc._id)

        if (emptyRoomIndex == -1) {
          rooms.push(room)

          // remove duplicate by room id
          rooms = rooms.filter(
            (room, index, that) =>
              index === that.findIndex(t => t !== undefined && room !== undefined && t.roomId === room.roomId),
          )

          const roomsOrdered = _orderBy(rooms, ['pinned', 'index'], ['asc', 'desc'])
          this.rooms = [...roomsOrdered]
        }

        if (roomOriginIndex >= 0) {
          this.roomsOrigin[roomOriginIndex] = room
          this.roomsOrigin = [...this.roomsOrigin]
        } else {
          this.roomsOrigin.concat([room])
        }
      }
    })
    this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
      if (payload.id === 'message_templates' || payload.id === 'category_message') {
        await this.getDataTemplate('message_templates', true)
        const refs = this.$refs.dialog_category
        if (refs) refs.getDataCategory(true)

        // //console.log(this.selectTable, 'selectTable after delete')
      }
    })

    // await this.$eventBus.$on('banner-alert', data => {
    //   if (data) {
    //     if (this.$vuetify.breakpoint.xs === true) {
    //       this.screen.height = 'calc(100vh - 200px)'
    //     } else {
    //       this.screen.height = 'calc(100vh - 150px)'
    //     }
    //   } else {
    //     this.screen.height = 'calc(100vh - 50px)'
    //   }
    //   //console.log('banner-alert', data)
    // })
    const firstTime = performance.now()
    const self = this

    this.headerContactsRoom = [
      { text: this.$t('contacts.phoneNumber'), value: 'phone_number_show' },
      { text: this.$t('name'), value: 'name' },
    ]

    const elem = document.documentElement
    const width = this.$store.getters['widget/getScreen']

    if (width < 450) {
      this.screen.height = 'calc(100vh - 120px)'
      elem.style.setProperty('--height', `${this.screen.height}`)
    } else {
      this.screen.height = 'calc(100vh - 75px)'
      elem.style.setProperty('--height', `${this.screen.height}`)
    }

    this.$eventBus.$on('mobile-check-header', d => {
      if (d === true) {
        /* this.$eventBus.$on('banner-alert', data => {
          if (data) {
            if (this.$vuetify.breakpoint.xs === true) {
              this.screen.height = 'calc(100vh - 200px)'
            } else {
              this.screen.height = 'calc(100vh - 150px)'
            }
          } else {
            this.screen.height = 'calc(100vh - 75px)'
          }
          //console.log('banner-alert', data)
        }) */

        // this.screen.height = 'calc(100vh - 120px)'
        elem.style.setProperty('--height', `${this.screen.height}`)
      } else {
        /* this.$eventBus.$on('banner-alert', data => {
          if (data) {
            if (this.$vuetify.breakpoint.xs === true) {
              this.screen.height = 'calc(100vh - 180px)'
            } else {
              this.screen.height = 'calc(100vh - 50px)'
            }
          } else {
            this.screen.height = 'calc(100vh - 50px)'
          }
          //console.log('banner-alert', data)
        }) */

        // this.screen.height = 'calc(100vh - 75px)'
        elem.style.setProperty('--height', `${this.screen.height}`)
      }
    })

    // mobile detect screen
    this.$store.commit('widget/setScreen', {
      height: window.screen.height,
      width: window.screen.width,
    })

    window.addEventListener('resize', () => {
      this.$store.commit('widget/setScreen', {
        height: window.screen.height,
        width: window.screen.width,
      })
    })

    this.loadingDialogText = 'Load Settings'

    // await this.fdbGetInstances(this.user.sub_id)

    for (const data of this.listInstances.value) {
      if (data.phone_number) {
        if (this.user.limited_channel) {
          if (this.user.enabled_channel.length > 0 && this.user.enabled_channel.includes(data._id)) {
            if (data.status != 0) {
              this.optionsInstances.push({
                label: data.label,
                value: data,
              })
            }

            this.statusInstancesList.push({
              _id: data._id,
              label: data.label,
              status: data.status,
              model: false,
            })
          }
        } else {
          if (data.status != 0) {
            this.optionsInstances.push({
              label: data.label,
              value: data,
            })
          }

          this.statusInstancesList.push({
            _id: data._id,
            label: data.label,
            status: data.status,
            model: false,
          })
        }
      }
    }

    const all = {
      label: 'All Channels',
      value: {
        all_sender: true,
        phone_number: null,
        color: '#3fc19f',
      },
    }

    if (this.optionsInstances.length > 0) {
      this.optionsInstances.unshift(all)
      await this.changeInstance(this.getValueChannel, true)

      // this.selectedInstanceVal = this.optionsInstances[0].value
      // this.selectedInstance = this.optionsInstances[0].value
      // this.$store.commit('inboxNavbar/setValueChannel', this.optionsInstances[0].value)
    } else if ((this.user.role === 'Member' || this.user.role === 'Operator') && this.user.view_assign_only) {
      // this.dialogWarningNotConnect = true
    } else {
      // this.$router.push({ name: 'channels' }, () => {})
    }

    // if (this.filterChat != null) {
    //   this.selectedSettingFilter = this.filterChat
    // }

    // await this.fetchRooms()

    await this.$store.dispatch('auth/fetchListUser', {
      sub_id: this.user.sub_id,
    })

    // this.fetchRooms()
    this.loadingDialog = false

    if (this.$route.params && this.$route.params.data) {
      const room = this.$route.params.data
      this.selectedRoom = room.roomId
      this.roomId = room.roomId
      this.currentUserId = room.roomId
      this.addHashToLocation(room.phone_number)
    }

    if (this.$route.params && this.$route.params.phoneNumber) {
      const { phoneNumber } = this.$route.params
      const room = this.roomsOrigin.find(room => room.phone_number == phoneNumber)

      if (room) {
        this.selectedRoom = room.roomId
        this.roomId = room.roomId
        this.currentUserId = room.roomId
        this.currentRoom = room
      }
    }

    this.showFooter = this.sharedRoomActive.type.value == 'can_reply'
    this.selectedRoom = this.sharedRoomActive.roomId
    this.roomId = this.sharedRoomActive.roomId
    this.currentUserId = this.sharedRoomActive.roomId
    this.currentRoom = this.roomsOrigin.find(room => room.roomId == this.sharedRoomActive.roomId)
  },

  created() {
    const elem = document.documentElement

    if (window.screen.width <= 450) {
      this.screen.height = 'calc(100vh - 125px)'
      elem.style.setProperty('--height', `${this.screen.height}`)
    } else {
      this.screen.height = 'calc(100vh - 80px)'
      elem.style.setProperty('--height', `${this.screen.height}`)
    }

    window.addEventListener('resize', () => {
      if (window.screen.width <= 450) {
        this.screen.height = 'calc(100vh - 125px)'
        elem.style.setProperty('--height', `${this.screen.height}`)
      } else {
        this.screen.height = 'calc(100vh - 80px)'
        elem.style.setProperty('--height', `${this.screen.height}`)
      }
    })
  },
  methods: {
    showHideMessageTemplate() {
      const refs = this.$refs['message-template']
      refs.dialog.addMessageTEmplate = true
      refs.getDataCategory()
      refs.status_message_template = 'add'
      refs.dataIndex = 0
      // eslint-disable-next-line prefer-destructuring
      refs.contactList = refs.contactListItems[0]
      refs.mustacheList = refs.mustacheAll[refs.contactList.value]
      refs.formAddTemplate = {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: true,
        link_attachment: '',
        last_update: '',
        attachments: [],
        contact_list: refs.contactListItems[0],
        id: '',
      }
      refs.propsUppyUploader = null
      refs.categoryName = null
    },
    changeRoomsLoaded(val) {
      this.roomsLoaded = val
    },
    filterNewContacts(val) {
      this.roomsNewContactsFiltered = this.roomsNewContacts.filter(x => {
        return x.roomName.toLowerCase().includes(val.toLowerCase())
      })
    },
    getContactList(selectedRoom) {
      if (selectedRoom) {
        const phoneNumber = selectedRoom.substr(10)
        const selectedPhoneNumber = this.allContactInbox.filter(x => x.phone_number == phoneNumber)
        const listIds = []
        for (var data of selectedPhoneNumber) {
          if (!listIds.find(x => x.list_id == data.list_id)) {
            if (data.list_name !== 'default') {
              listIds.push({
                list_id: data.list_id,
                list_name: data.list_name,
              })
            }
          }
        }

        return listIds
      }

      return []
    },
    openContactList(idList) {
      this.$router.push({ name: 'contacts-list-custom', params: { idlist: idList } })
    },
    async getContactCouchData(id) {
      const number = id
      let name = id

      if (!id.includes('-') && id.length <= 16) {
        const countryCode = `+${id.substr(0, 2)}` // +62
        const splitOne = id.substr(2, 3)
        const splitTwo = id.substr(5, 4)
        const splitThree = id.substr(9, id.length - 1)
        name = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      let contact = {
        _id: id,
        name,
        phone_number: id,
        phone_number_show: id,
        avatar: `${process.env.VUE_APP_WA_API_URL}ppic/default/${id}/a.jpg`,
      }

      try {
        contact = await this.pouchContacts.remote.get(number)

        if (contact) {
          // map labels
          contact.labels = this.setLabels(contact)

          // map pouch
          const index = this.contactsPouch.findIndex(item => item._id == contact._id)

          if (index == -1) {
            this.contactsPouch.push(contact)
          }
        }
      } catch (err) {
        // console.log(err)
      }

      return contact
    },
    async getUnreadCouchData(roomId) {
      let unread = 0

      try {
        const contact = await this.pouchContacts.remote.get(roomId)

        if (contact) {
          unread = contact.unread_count ? contact.unread_count : 0
        }
      } catch (err) {
        // console.log(err)
      }

      return unread
    },
    async getInstanceCouchData(id) {
      let instance = null

      if (this.listInstances && this.listInstances.value && this.listInstances.value.length > 0) {
        instance = this.listInstances.value.find(item => {
          return item._id == id
        })
      }

      return instance
    },
    async getUsernameCouchData(users, instance) {
      const formattedUsers = []
      let currentUser = null

      try {
        for (const user of users) {
          user.username = ''
          currentUser = user

          if (user._id != instance.phone_number) {
            let contact = this.contactsPouch.find(item => item._id == user._id)

            if (!contact) {
              // get from remote
              contact = await this.pouchContacts.remote.get(user._id || user)
            }

            if (contact) {
              if (instance) {
                user.username = contact.name ? contact.name : ''
              }
            }
          }

          formattedUsers.push(user)
        }
      } catch (err) {
        // console.log(err)

        if (currentUser) {
          formattedUsers.push(currentUser)
        }
      }

      return formattedUsers
    },
    async getInstanceData() {},

    /// //////////////////////////// START DEMO VAC METHODS
    async resetRooms() {
      //console.log('RESET ROOMS')
      this.loadingRooms = true
      this.loadingLastMessageByRoom = 0
      this.roomsLoadedCount = 0
      this.rooms = []
      this.roomsOrigin = []
      this.roomsNewContacts = []
      this.roomsNewContactsFiltered = []
      this.roomsLoaded = true
      this.startRooms = null
      this.endRooms = null
      this.roomsListeners.forEach(listener => listener())
      this.roomsListeners = []
      this.resetMessages()
    },

    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.lastLoadedMessage = null
      this.lastMessages = null
      this.isLoadFirestore = false
      this.currentRoomContainer = []
      this.previousLastLoadedMessage = null
      this.listeners.forEach(listener => listener())
      this.listeners = []
    },

    async fetchRooms() {
      await this.resetRooms()
      await this.mappingRooms()
      await this.fetchMoreRooms()
    },

    async mappingRooms() {
      //console.log('MAPPING ROOMS')
      let formattedRooms = []
      const formattedRoomsNewContact = []
      for (const room of this.contactListTable) {
        if (!room) continue

        room.roomId = room._id

        // default field
        if (!room.users) {
          room.users = []
        }

        if (!room.typingUsers) {
          room.typingUsers = []
        }

        if (!room.roomStatus) {
          room.roomStatus = 'on_queue'
        }

        if (!room.archived) {
          room.archived = false
        }

        if (!room.assign_to) {
          room.assign_to = false
        }

        if (!room.last_interaction) {
          room.last_interaction = null
        }

        if (!room.notes) {
          room.notes = []
        }

        if (!room.pinned) {
          room.pinned = 0
        }

        const roomContacts = room.users.filter(user => user._id !== this.currentUserId)

        room.roomName =
          room.roomName || room.name
            ? room.name
            : roomContacts.length > 0
            ? roomContacts.map(user => user.username).join(', ') || 'Myself'
            : 'Myself'

        const roomAvatar = room.profile_picture ? room.profile_picture : require('../../plugins/demo/assets/avatar.png')

        const couchDbInstance = await this.getInstanceCouchData(room.instance_id)

        room.labels = this.setLabels(room.roomId)

        if (room.lastMessage) {
          const vacIndex = room.lastMessage.timestamp._seconds
          const format = this.$moment.unix(room.lastMessage.timestamp._seconds).isSame(this.$moment(), 'day')
            ? 'HH:mm'
            : 'DD/MM/YYYY'

          room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage.timestamp._seconds).format(format)

          if (room.phone_number_show == 'Group') {
            room.lastMessage.username = room.lastMessage.username = room.lastMessage.sender_name

            if (room.lastMessage.username == room.lastMessage.sender_id) {
              const user = room.users.find(x => x && room.lastMessage.sender_id === x._id)

              if (user) {
                room.lastMessage.username = user.username ? user.username : user._id
              }

              if (
                /\d{10,11}/.test(room.lastMessage.username) ||
                /\d{12,13}/.test(room.lastMessage.username) ||
                /\d{14,15}/.test(room.lastMessage.username)
              ) {
                room.lastMessage.username = this.formatPhoneNumber(room.lastMessage.username, false)
              }
            }
          }

          // parse content last message
          if (room.lastMessage.data) {
            if (room.lastMessage.data.type == 'document') {
              const file = room.lastMessage.files[0]
              room.lastMessage.content = file.name
            } else if (room.lastMessage.data.type == 'vcard') {
              const file = room.lastMessage.files[0]
              room.lastMessage.content = file.name
            } else if (room.lastMessage.data.type == 'sticker') {
              room.lastMessage.content = 'Sticker'
            } else if (room.lastMessage.data.type == 'buttons') {
              let buttonContent = ''

              if (room.lastMessage.buttonOptions.header) {
                buttonContent += room.lastMessage.buttonOptions.header
              }

              if (room.lastMessage.buttonOptions.content) {
                buttonContent += ` ${room.lastMessage.buttonOptions.content}`
              }

              if (room.lastMessage.buttonOptions.footer) {
                buttonContent += ` ${room.lastMessage.buttonOptions.footer}`
              }

              room.lastMessage.content = buttonContent
            }
          }

          // format phone number
          room.users.forEach(user => {
            if (user._id == user.username) {
              user.username = this.formatPhoneNumber(user._id, false)
            }
          })

          formattedRooms.push({
            ...room,
            avatar: roomAvatar,
            index: vacIndex,
            instance: couchDbInstance,
            unreadCount: room.unread_count,
          })
        } else {
          formattedRoomsNewContact.push({
            ...room,
            lastMessage: {
              content: '',

              // timestamp: formatTimestamp(new Date(room.lastUpdated.seconds), room.lastUpdated),
            },
            avatar: roomAvatar,
            index: room.name,
            instance: couchDbInstance,
            unreadCount: 0,
            message_container: [],
          })
        }
      }

      formattedRooms = _orderBy(formattedRooms, ['pinned', 'index'], ['desc', 'desc'])
      this.roomsNewContacts = this.roomsNewContacts.concat(formattedRoomsNewContact)
      this.roomsNewContactsFiltered = this.roomsNewContacts
      this.roomsOrigin = this.roomsOrigin.concat(formattedRooms)

      // remove duplicate by room id
      this.roomsOrigin = this.roomsOrigin.filter(
        (room, index, that) =>
          index === that.findIndex(t => t !== undefined && room !== undefined && t.roomId === room.roomId),
      )

      this.roomSpliced = [...this.roomsOrigin]
    },

    async fetchMoreRooms() {
      if (this.roomSpliced.length === 0) {
        this.roomsLoaded = true

        return
      }

      this.roomsLoaded = false

      const tempRooms = this.roomSpliced.splice(0, 8)
      this.rooms = this.rooms.concat(tempRooms)

      // remove duplicate by room id
      this.rooms = this.rooms.filter(
        (room, index, that) =>
          index === that.findIndex(t => t !== undefined && room !== undefined && t.roomId === room.roomId),
      )

      this.rooms = [...this.rooms]
      if (this.rooms.length > 0) {
        this.loadingRooms = false
        this.showAddRoom = true
        this.showFilterRoom = true
      }

      if (this.roomSpliced.length === 0) {
        this.roomsLoaded = true
      }
      this.roomsLoadedCount = this.rooms.length
    },

    async filterContactRooms() {
      let filteredRooms = JSON.parse(JSON.stringify(this.roomsOrigin))

      // filter channel
      if (this.getValueChannel && this.getValueChannel.value) {
        if (!this.getValueChannel.value.all_sender) {
          filteredRooms = this.roomsOrigin.filter(room => room.instance_id == this.getValueChannel.value._id)
        }
      }

      // filter room
      if (this.filterChat == 'assigned') {
        this.rooms = filteredRooms.filter(
          room =>
            room.assign_to != null &&
            room.assign_to != false &&
            room.assign_to.email == this.user.email &&
            room.archived == false,
        )
      } else if (this.filterChat == 'unreplied') {
        this.rooms = filteredRooms.filter(room => room.unreplied == true && room.archived == false)
      } else if (this.filterChat == 'unread') {
        this.rooms = filteredRooms.filter(room => room.unread_count > 0 && room.archived == false)
      } else {
        this.rooms = filteredRooms.filter(room => room.archived == false)
      }

      this.rooms = [...this.rooms]
      if (this.rooms.length > 0) {
        this.loadingRooms = false
        this.showAddRoom = true
        this.showFilterRoom = true
      }
      this.loadingDialog = false

      // this.roomsLoaded = true
      this.roomsLoadedCount = this.rooms.length
    },

    formatLastMessage(message, room) {
      if (!message.timestamp) return

      let { content } = message

      if (message.files?.length) {
        const file = message.files[0]
        content = file.name
      }

      if (message.data.type == 'document') {
        const file = message.files[0]
        content = file.name
      } else if (message.data.type == 'vcard') {
        const file = message.files[0]
        content = file.name
      } else if (message.data.type == 'sticker') {
        content = 'Sticker'
      } else if (message.data.type == 'buttons') {
        let buttonContent = ''

        if (message.buttonOptions.header) {
          buttonContent += message.buttonOptions.header
        }

        if (message.buttonOptions.content) {
          buttonContent += ` ${message.buttonOptions.content}`
        }

        if (message.buttonOptions.footer) {
          buttonContent += ` ${message.buttonOptions.footer}`
        }

        content = buttonContent
      }

      if (room.phone_number_show == 'Group') {
        message.username =
          message.sender_id !== this.currentUserId
            ? room.users
              ? room.users.find(user => message.sender_id === user._id)?.username
              : ''
            : ''
      }

      // const username =
      //   message.sender_id !== this.currentUserId
      //     ? room.users.find(user => message.sender_id === user._id)?.username
      //     : ''

      // check timestamp
      let timestamp = this.$moment.unix()

      if (message.timestamp.seconds) {
        timestamp = message.timestamp.seconds
      } else if (message.timestamp._seconds) {
        timestamp = message.timestamp._seconds
      }
      const format = this.$moment.unix(message.timestamp._seconds).isSame(this.$moment(), 'day')
        ? 'HH:mm'
        : 'DD/MM/YYYY'

      return {
        ...message,
        ...{
          content,
          formattedTimestamp: this.$moment.unix(message.timestamp._seconds).format(format),
          timestamp: formatTimestamp(new Date(timestamp * 1000), message.timestamp),
          seconds: timestamp,

          // distributed: true,
          // seen: message.sender_id === this.currentUserId ? message.seen : null,
          // new: message.sender_id !== this.currentUserId && (!message.seen || !message.seen[this.currentUserId]),
        },
      }
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    async fetchMessages({ room, options = {} }) {
      if (this.isFetchMessages) {
        return
      }

      this.isFetchMessages = true

      setTimeout(() => {
        this.isFetchMessages = false
      }, 150)

      if (room && room.roomId !== this.selectedRoom && options && !options.reset) {
        return
      }

      if (room) {
        if (!room.roomId) {
          room.roomId = room._id
        }

        this.selectedRoom = room.roomId
        this.addHashToLocation(room.phone_number)
      } else {
        return
      }

      this.selectedRoom = room.roomId

      let currentRoom = this.rooms.find(item => item.roomId == this.selectedRoom)

      if (!currentRoom) {
        currentRoom = this.roomsOrigin.find(item => item.roomId == this.selectedRoom)
      }

      if (!currentRoom) {
        currentRoom = this.roomsNewContacts.find(item => item.roomId == this.selectedRoom)
      }

      this.currentRoom = currentRoom

      if (options.reset) {
        this.resetMessages()

        this.roomId = room.roomId

        if (room.roomId.substr(room.roomId.indexOf('-') + 1).length > 16) {
          this.loadingDialogText = 'Sync Group Participants'
          this.loadingDialog = true

          const response = await this.$store.dispatch('inboxNavbar/getUsers', {
            subId: this.user.sub_id,
            roomId: room.roomId,
          })

          this.loadingDialog = false

          if (response.status) {
            this.currentRoom.users = response.data ? response.data : []
            room.users = response.data ? response.data : []

            // format phone number
            this.currentRoom.users.forEach(user => {
              if (user._id == user.username) {
                user.username = this.getContactsName(user._id)

                if (
                  /\d{10,11}/.test(user.username) ||
                  /\d{12,13}/.test(user.username) ||
                  /\d{14,15}/.test(user.username)
                ) {
                  user.username = this.formatPhoneNumber(user._id, false)
                }
              }
            })

            room.users.forEach(user => {
              if (user._id == user.username) {
                user.username = this.getContactsName(user._id)

                if (
                  /\d{10,11}/.test(user.username) ||
                  /\d{12,13}/.test(user.username) ||
                  /\d{14,15}/.test(user.username)
                ) {
                  user.username = this.formatPhoneNumber(user._id, false)
                }
              }
            })
          }
        }
      } else {
        if (this.currentRoomContainer.length > 0) {
          const filteredChats = this.currentRoomContainer ? this.currentRoomContainer.splice(-20) : []
          for (var message of filteredChats) {
            if (message.roomId === message.roomId) {
              const formattedMessage = this.formatMessage(room, message, true)
              const messageIndex = this.messages.findIndex(m => m.source_id === message.source_id)

              if (messageIndex === -1) {
                this.messages = this.messages.concat([formattedMessage])
              } else {
                this.messages[messageIndex] = formattedMessage
              }

              // set read message
              if (!this.stealthMode) {
                if (formattedMessage.sender_id > 16) {
                  this.setReadMessages([message.source_id], room, formattedMessage.senderId)
                } else {
                  this.setReadMessages([message.source_id], room)
                }
              }
            }
          }
        } else if (!this.isLoadFirestore) {
          // this.messagesLoaded = true
          this.isLoadFirestore = true
          const result = await this.getMessagesFirestore(this.user.sub_id, room.roomId, this.lastMessages)
          await this.sleep(200)
          for (var message of result.messages) {
            if (this.selectedRoom === message.roomId) {
              const formattedMessage = this.formatMessage(room, message, true)
              const messageIndex = this.messages.findIndex(m => m.source_id === message.source_id)

              if (messageIndex === -1) {
                this.messages = this.messages.concat([formattedMessage])
              } else {
                this.messages[messageIndex] = formattedMessage
              }
            }
          }

          this.isLoadFirestore = false
          if (result.messages.length === 0 || this.lastMessages === result.lastMessages) {
            setTimeout(() => {
              this.messagesLoaded = true
            }, 0)
          } else {
            this.lastMessages = result.lastMessages
            this.messagesLoaded = false
          }
        }

        this.messages = this.messages.filter(x => x.roomId === room.roomId)
        this.messages = this.messages.filter(
          (message, index, that) => index === that.findIndex(t => t.source_id === message.source_id),
        )

        this.messages = _orderBy(this.messages, ['couch_timestamp'], ['asc'])

        this.messages = [...this.messages]

        return
      }

      this.currentUserId = room.roomId
      if (this.currentRoomContainer.length == 0) {
        const tempContainer = room.message_container.filter(x => x.roomId == room.roomId)
        this.currentRoomContainer = _orderBy(tempContainer, ['couch_timestamp'], ['asc'])
      }

      const filteredChats = this.currentRoomContainer.length > 0 ? this.currentRoomContainer.splice(-20) : []
      if (options.reset) {
        this.messages = []
        for (var message of filteredChats) {
          if (this.messages.length > 0 && this.messages[0].roomId !== message.roomId) break
          const formattedMessage = this.formatMessage(room, message, true)
          const messageIndex = this.messages.findIndex(m => m.source_id === message.source_id)
          if (messageIndex === -1) {
            this.messages = this.messages.concat([formattedMessage])
          } else {
            this.messages[messageIndex] = formattedMessage
          }

          // set read message
          if (!this.stealthMode) {
            if (formattedMessage.sender_id > 16) {
              this.setReadMessages([message.source_id], room, formattedMessage.senderId)
            } else {
              this.setReadMessages([message.source_id], room)
            }
          }
        }

        this.messages = this.messages.filter(x => x.roomId === room.roomId)
        this.messages = this.messages.filter(
          (message, index, that) => index === that.findIndex(t => t.source_id === message.source_id),
        )

        this.messages = _orderBy(this.messages, ['couch_timestamp'], ['asc'])

        this.messages = [...this.messages]
        this.messagesLoaded = true

        setTimeout(() => {
          this.messagesLoaded = false
          this.fetchMessages({ room })
        }, 50)

        // //console.log(this.messages, ' == messages')
        // //console.log(this.currentRoomContainer.length, ' == length container 2')
      }

      // this.messagesLoaded = true

      setTimeout(() => {
        const element = document.getElementById('last-message-container')

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }, 500)

      // }
    },

    formatMessage(room, message, newStructure = false) {
      let dataMessage = null

      if (newStructure) {
        dataMessage = message
      } else {
        dataMessage = message.data()
      }

      const senderUser = room.users.find(user => dataMessage.sender_id === user._id)

      const { timestamp } = dataMessage

      // set labels
      dataMessage = this.setMessageLabels(dataMessage)

      // default
      dataMessage.selected = false

      if (room.phone_number_show == 'Group') {
        const seenLength = dataMessage.seen_by ? dataMessage.seen_by.length || 0 : 0

        // //console.log(room.users.length - 1, '=== messageroom', seenLength, dataMessage.seen_by)

        // formattedMessage.seen = (room.users.length - 1) == seenLength
        dataMessage.seen = room.users.length - 1 == seenLength
      }

      let username = dataMessage.sender_name

      if (/\d{10,11}/.test(username) || /\d{12,13}/.test(username) || /\d{14,15}/.test(username)) {
        username = this.formatPhoneNumber(username, false)
      }

      const formattedMessage = {
        ...dataMessage,
        ...{
          senderId: dataMessage.sender_id,
          _id: dataMessage.source_id,
          seconds: timestamp._seconds,
          timestamp: parseTimestamp(timestamp, 'HH:mm'),
          date: parseTimestamp(timestamp, 'DD MMMM YYYY'),
          username,

          // avatar: senderUser ? senderUser.avatar : null,
          distributed: true,
        },
      }

      return formattedMessage
    },

    async sendMessage({ content, roomId, files, replyMessage }) {
      // const message = {
      //   sender_id: this.currentUserId,
      //   content,
      //   timestamp: new Date(),
      //   fromMe: true,
      // }

      // if (files) {
      //   message.files = this.formattedFiles(files)
      // }

      // if (replyMessage) {
      //   message.replyMessage = {
      //     _id: replyMessage._id,
      //     content: replyMessage.content,
      //     sender_id: replyMessage.senderId,
      //   }

      //   if (replyMessage.files) {
      //     message.replyMessage.files = replyMessage.files
      //   }
      // }

      // const { id } = await firestoreService.addMessage(roomId, message, this.roomsPath)

      // if (files) {
      //   for (let index = 0; index < files.length; index++) {
      //     await this.uploadFile({ file: files[index], messageId: id, roomId })
      //   }
      // }

      // send message api
      // const body = {
      //   subId: this.user.sub_id,
      //   roomId: this.selectedRoom,
      //   content: content,
      // }
      this.loadSendMessage = true

      const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send`

      const formData = new FormData()
      if (replyMessage && replyMessage?.original_message) {
        formData.append('reply', JSON.stringify(replyMessage.original_message))
      }
      formData.append('roomId', this.selectedRoom)
      formData.append('subId', this.user.sub_id)
      formData.append('content', content)

      if (files) {
        for (let index = 0; index < files.length; index++) {
          // //console.log("ada gambr ", files[index]);
          formData.append('attachments', files[index].blob)
        }
      }
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      await this.$axios
        .post(endpoint, formData, config)
        .then(async response => {
          if (this.autoAssignChatOnReply) {
            await this.assignContact()
          }

          if (this.stealthMode) {
            const room = this.rooms.find(x => x.roomId == this.selectedRoom)
            const filteredMessages = this.messages.filter(x => !x.fromMe)
            const sourceIds = []
            for (const msg of filteredMessages) {
              sourceIds.push(msg.source_id)
            }

            if (room && room.phone_number_show != 'Group') {
              this.setReadMessages(sourceIds, room)
            } else if (room) {
              this.setReadMessages(sourceIds, room, room.roomOwnerId)
            }
          }

          this.loadSendMessage = false
        })
        .catch(error => {
          console.log(error)

          this.loadSendMessage = false
        })

      // await firestoreService.updateRoom(roomId, { lastUpdated: new Date() }, this.roomsPath)
    },

    async editMessage({ messageId, newContent, roomId, files }) {
      const newMessage = { edited: new Date() }
      newMessage.content = newContent

      if (files) {
        newMessage.files = this.formattedFiles(files)
      } else {
        newMessage.files = firestoreService.deleteDbField
      }

      await firestoreService.updateMessage(roomId, messageId, newMessage, this.roomsPath)

      if (files) {
        for (let index = 0; index < files.length; index++) {
          if (files[index]?.blob) {
            await this.uploadFile({ file: files[index], messageId, roomId })
          }
        }
      }
    },

    async deleteMessage({ message, roomId }) {
      await firestoreService.updateMessage(
        roomId,
        message._id,
        {
          deleted: new Date(),
        },
        this.roomsPath,
      )

      const { files } = message

      if (files) {
        files.forEach(file => {
          storageService.deleteFile(this.currentUserId, message._id, file)
        })
      }
    },

    async uploadFile({ file, messageId, roomId }) {
      return new Promise(resolve => {
        let type = file.extension || file.type
        if (type === 'svg' || type === 'pdf') {
          type = file.type
        }

        const uploadTask = storageService.uploadFileTask(this.currentUserId, messageId, file, type)

        uploadTask.on(
          'state_changed',
          snap => {
            const progress = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
            this.updateFileProgress(messageId, file.localUrl, progress)
          },
          _error => {
            resolve(false)
          },
          async () => {
            const url = await storageService.getFileDownloadUrl(uploadTask.snapshot.ref)

            const messageDoc = await firestoreService.getMessage(roomId, messageId, this.roomsPath)

            const { files } = messageDoc.data()

            files.forEach(f => {
              if (f.url === file.localUrl) {
                f.url = url
              }
            })

            await firestoreService.updateMessage(roomId, messageId, { files }, this.roomsPath)
            resolve(true)
          },
        )
      })
    },

    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find(message => message._id === messageId)

      if (!message || !message.files) return

      message.files.find(file => file.url === fileUrl).progress = progress
      this.messages = [...this.messages]

      this.messages = this.messages.filter(
        (message, index, that) => index === that.findIndex(t => t._id === message._id),
      )

      this.messages = _orderBy(this.messages, 'seconds', 'asc')
    },

    formattedFiles(files) {
      const formattedFiles = []

      files.forEach(file => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        }

        if (file.audio) {
          messageFile.audio = true
          messageFile.duration = file.duration
        }

        formattedFiles.push(messageFile)
      })

      return formattedFiles
    },

    openFile({ file }) {
      // window.open(file.file.url, '_blank')
      this.actionFile(file.file)
    },

    async openUserTag({ user }) {
      // if (user) {
      //   window.location.href = `${window.location.protocol}//${window.location.host}/inbox/${user._id}`
      // }

      if (this.selectedRoomObject) {
        this.resetMessages()

        const roomId = `${this.selectedRoomObject.instance_id}-${user._id}`
        const room = this.roomsOrigin.find(room => room.roomId == roomId)

        if (room) {
          // change room
          this.currentRoom = room
          this.roomId = roomId
          this.selectedRoom = roomId
        } else {
          // new room
          this.selectedInstanceRoom = {
            label: this.selectedRoomObject.instance.label,
            value: this.selectedRoomObject.instance,
          }

          this.overlayDownload = true

          await this.actionNewChat({
            value: user,
            item: {
              phone_number: user._id,
            },
          })

          this.overlayDownload = false
        }
      }
    },

    async loadRoom(query) {
      query.forEach(async room => {
        if (this.loadingRooms) return
        await firestoreService.updateRoom(room.id, { lastUpdated: new Date() }, this.roomsPath)
        this.roomId = room.id
        await this.fetchRooms()
      })
    },

    async sendMessageReaction({ reaction, remove, messageId, roomId }) {
      firestoreService.updateMessageReactions(
        roomId,
        messageId,
        this.currentUserId,
        reaction.unicode,
        remove ? 'remove' : 'add',
        this.roomsPath,
      )
    },

    addRoom() {
      this.resetForms()
      this.addNewRoom = true
    },

    async createRoom() {
      this.disableForm = true

      const { id } = await firestoreService.addUser(
        {
          username: this.addRoomUsername,
        },
        `users-${this.subId}`,
      )
      await firestoreService.updateUser(id, { _id: id }, `users-${this.subId}`)

      await firestoreService.addRoom(
        {
          users: [id, this.currentUserId],
          lastUpdated: new Date(),
        },
        this.roomsPath,
      )

      this.addNewRoom = false
      this.addRoomUsername = ''
      await this.fetchRooms()
    },

    inviteUser(roomId) {
      this.resetForms()
      this.inviteRoomId = roomId
    },

    async addRoomUser() {
      this.disableForm = true

      const { id } = await firestoreService.addUser(
        {
          username: this.invitedUsername,
        },
        `users-${this.subId}`,
      )
      await firestoreService.updateUser(id, { _id: id }, `users-${this.subId}`)

      await firestoreService.addRoomUser(this.inviteRoomId, id, this.roomsPath)

      this.inviteRoomId = null
      this.invitedUsername = ''
      await this.fetchRooms()
    },

    removeUser(roomId) {
      this.resetForms()
      this.removeRoomId = roomId
      this.removeUsers = this.rooms.find(room => room.roomId === roomId).users
    },

    async deleteRoomUser() {
      this.disableForm = true

      await firestoreService.removeRoomUser(this.removeRoomId, this.removeUserId, this.roomsPath)

      this.removeRoomId = null
      this.removeUserId = ''
      await this.fetchRooms()
    },

    async deleteRoom(roomId) {
      const room = this.rooms.find(r => r.roomId === roomId)
      if (
        room.users.find(user => user._id === 'SGmFnBZB4xxMv9V4CVlW') ||
        room.users.find(user => user._id === '6jMsIXUrBHBj7o2cRlau')
      ) {
        return alert('Nope, for demo purposes you cannot delete this room')
      }

      firestoreService.getMessages(roomId, this.roomsPath).then(messages => {
        messages.forEach(message => {
          firestoreService.deleteMessage(roomId, message.id, this.roomsPath)
          if (message.data().files) {
            message.data().files.forEach(file => {
              storageService.deleteFile(this.currentUserId, message.id, file)
            })
          }
        })
      })

      await firestoreService.deleteRoom(roomId, this.roomsPath)

      await this.fetchRooms()
    },

    resetForms() {
      this.disableForm = false
      this.addNewRoom = null
      this.addRoomUsername = ''
      this.inviteRoomId = null
      this.invitedUsername = ''
      this.removeRoomId = null
      this.removeUserId = ''
    },

    async updateRoom(ev) {
      // await firestoreService.updateRoom(ev.room.roomId, ev.doc, this.roomsPath)
      await this.updateRoomCouchDb(ev.room.subId, ev.room.instance_id, ev.room.roomId, ev.doc)

      const roomIndex = this.rooms.findIndex(item => item.roomId == ev.room.roomId)

      let roomStatus = ''

      if (roomIndex >= 0) {
        roomStatus = this.rooms[roomIndex].roomStatus
        this.rooms[roomIndex][ev.field] = ev.doc[ev.field]

        if (ev.field == 'assign_to') {
          let doc = {
            roomAssignedTo: ev.doc.assign_to.email,
          }

          await this.updateRoomCouchDb(ev.room.subId, ev.room.instance_id, ev.room.roomId, doc)

          this.rooms[roomIndex].roomAssignedTo = ev.doc.roomAssignedTo

          doc = {
            roomStatus: 'assigned',
          }

          await this.updateRoomCouchDb(ev.room.subId, ev.room.instance_id, ev.room.roomId, doc)

          this.rooms[roomIndex].roomStatus = ev.doc.roomStatus
        }
      }
    },

    getChannelPhone(id) {
      const listInstance = this.$store.getters['auth/getListInstance']
      if (listInstance.length > 0) {
        const obj = listInstance.find(x => x._id === id)

        return obj ? obj.phone_number : '-'
      }

      return '-'
    },
    userGetNotifSound() {
      if (!(this.lastNotif === null || new Date().getTime() / 1000 - this.lastNotif > 5)) return false

      // if (!(!notification.contact.assign_to || notification.contact.assign_to.email === this.user.email)) return false

      // if (this.user.limited_channel) {
      //   let getNotif = false
      //   this.user.enabled_channel.forEach(channel_id => {
      //     if (notification.contact.ch) {
      //       const keys = Object.keys(notification.contact.ch)
      //       if (keys.includes(this.getChannelPhone(channel_id))) {
      //         getNotif = true
      //       }
      //     }
      //   })

      //   return getNotif
      // }
      if (this.user.package === 'basic') return false

      return true
    },
    play() {
      const audio = new Audio(this.sound)
      const resp = audio.play()
      if (resp !== undefined) {
        resp
          .then(_ => {
            // autoplay starts!
          })
          .catch(error => {
            // show error
          })
      }
    },
    skipDownload() {
      this.loadingDialog = false

      // this.loadingRooms = false
    },

    //   if (this.instances) {
    //     for (const data of this.instances) {
    //       if (data.phone_number) {
    //         const isReady = await this.$store.dispatch('auth/checkChannelReady', `${data.sub_id  }-${  data.instance_id}`)
    //         await this.$store.dispatch('auth/checkStatusMd', {
    //           id: `${data.sub_id}-${data.instance_id}`,
    //           ready: isReady
    //         })
    //         //console.log('check status channel', `${data.sub_id}-${data.instance_id}`)
    //         if (!data.label || data.label == '-') {
    //           data.label = self.$store.getters['auth/getInstanceName']
    //             ? self.$store.getters['auth/getInstanceName']
    //             : data.phone_number
    //           isChange = true
    //         }
    //         if (!data.status || data.status !== this.$store.getters['auth/getStatusInstance']) {
    //           data.status = this.$store.getters['auth/getStatusInstance']
    //           isChange = true
    //         }

    //         // this.syncContactsWa(data)
    //       }
    //     }
    //   }
    // },
    urlify(message) {
      if (!message) return ''

      const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g

      message = message.replace(urlRegex, function (url) {
        let hyperlink = url

        if (!hyperlink.match('^https?://')) {
          hyperlink = `http://${hyperlink}`
        }

        if (url.includes(process.env.VUE_APP_ATTACH_URL)) {
          return `<a href="${hyperlink}" target="_blank">Attachment</a>`
        }

        return `<a href="${hyperlink}" target="_blank">Attachment</a>`
      })

      return message

      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    async roomInfo(room) {
      this.toggleSidebar()
    },
    async changeInstance(instance, firstLoad = false) {
      if (!firstLoad) {
        this.currentUserId = 'all'
        this.selectedRoom = null
        this.currentRoom = null
        this.isShownSidebar = false
      }

      if (instance) {
        if (instance.value && instance.value.instance_id) {
          this.currentUserId = instance.value.instance_id
        }
      }

      // await this.fetchRooms()
      await this.filterContactRooms()
    },
    async changeSettingFilter() {
      this.selectedRoom = null
      this.currentUserId = 'all'
      this.roomId = null
      this.resetMessages()

      // await this.fetchRooms()
    },
    openDialogSavedReplies(mode, replies = null) {
      this.dialogSavedReplies = true
      this.activeSavedReplies = null

      if (mode == 'new') {
        this.isNewModeSavedReplies = true
        this.savedRepliesTag = null
        this.savedRepliesName = null
      } else {
        this.isNewModeSavedReplies = false
        this.savedRepliesTag = replies.tag ? replies.tag : ''
        this.savedRepliesName = replies.name ? replies.name : ''
        this.activeSavedReplies = replies
      }
    },
    openDialogNotes(mode, notes = null) {
      this.dialogNotes = true
      this.activeNotes = null

      if (mode == 'new') {
        this.isNewModeNotes = true
        this.notesName = null
      } else {
        this.isNewModeNotes = false
        this.notesName = notes.name
        this.activeNotes = notes
      }
    },
    toggleSidebar(status) {
      this.isShownSidebar = !this.isShownSidebar

      const doc = document.getElementById('chat').getElementsByClassName('background-sidebar')

      switch (status) {
        case 'desktop':
          doc[0].classList.add('active')

          // this.isShownSidebar = true

          this.isShownSidebar = false

          break
        default:
          if (this.$vuetify.breakpoint.smAndDown) {
            doc[0].classList.add('mobile')
          }

          if (doc[0].classList.contains('active') === true) {
            doc[0].classList.remove('active')
            doc[0].classList.remove('mobile')
            this.isShownSidebar = false
          } else {
            doc[0].classList.add('active')
            this.isShownSidebar = true
          }

          break
      }
    },

    // openFile({ file }) {
    //   if (file.action == 'preview') {
    //     window.open(file.file.url, '_blank')
    //   } else {
    //     this.actionFile(file.file)
    //   }
    // },
    updateStatusInstancesList(status, instance) {
      const index = this.statusInstancesList.findIndex(item => item._id == instance._id)

      if (index >= 0) {
        this.$set(this.statusInstancesList[index], 'status', status)

        if (status == 0 && status == 3) {
          this.$set(this.statusInstancesList[index], 'model', true)
        } else {
          this.$set(this.statusInstancesList[index], 'model', false)
        }
      }

      const model = []

      this.statusInstancesList.forEach(instance => {
        model.push(instance.model)
      })

      const checker = arr => arr.every(v => v === false)

      if (checker(model)) {
        this.statusInstancesAlert = false
      } else {
        this.statusInstancesAlert = true
      }
    },
    toastsErrorSendMessage() {
      this.$dialog.message.error(this.$t('dialog.errorSendMessage'), { position: 'top-right' })
    },
    toastsErrorUploadAttachment() {
      this.$dialog.message.error(this.$t('dialog.errorUploadAttachment'), { position: 'top-right' })
    },
    async menuActionHandler({ roomId, action }) {
      this.currentAction = action.name

      if (action.name == 'selectMessages') {
        if (!this.showCheckbox) {
          this.clearSelectedMessages()
        }

        this.showCheckbox = true
      } else if (action.name == 'addLabel') {
        this.setRoomLabels(roomId, action.name)
      } else if (action.name == 'quickReply') {
        this.dialogShownSavedReplies = !this.dialogShownSavedReplies
      } else if (action.name == 'archiveContact') {
        await this.archiveContact()
      } else if (action.name == 'shareRoom') {
        this.dialogSharedRoom = true
        this.sharedRoomUrl = null
      }
    },
    async messageActionHandler({ roomId, action, message }) {
      this.currentAction = action.name
      this.currentMessage = message

      if (action.name == 'labelMessage') {
        this.setRoomLabels(roomId, action.name, message)
      } else if (action.name == 'noteMessage') {
        let note = message.content

        if (message.files && message.files.length > 0) {
          if (note) {
            note += ' | '
          }

          note += message.files[0].url
        }

        this.notesName = note
        await this.saveNotes()
      }
    },
    selectMessagesActionHandler({ roomId, action, messages }) {
      if (action == 'close') {
        this.showCheckbox = false
      } else if (action == 'labelMessages') {
        this.currentAction = action
        this.currentMessages = messages
        this.keyLabels++
        this.labels = JSON.parse(JSON.stringify(this.labelsOrigin))
        this.selectedLabels = []

        if (this.labels && this.labels.length > 0) {
          this.dialogLabels = !this.dialogLabels
        } else {
          this.alertDialog = true
          this.alertTitle = 'Oops...'
          this.alertText = `${this.$t('label')} ${this.$t('notFound')}`
        }
      } else if (action == 'exportMessages') {
        const formattedMessages = []

        messages.forEach(message => {
          message.sender_id = `+${message.sender_id}`
          message.instance_name = this.currentRoom.instance ? this.currentRoom.instance.label : ''
          message.instance_phone_number = this.currentRoom.instance ? `+${this.currentRoom.instance.phone_number}` : ''

          if (message.files && message.files.length > 0) {
            message.attachment = message.files[0].url
          }

          const date = this.$moment.unix(message.seconds)
          message.formatted_timestamp = date.format()

          formattedMessages.push(message)
        })

        this.exportMessages(formattedMessages)
        this.showCheckbox = false
      }
    },
    setRoomLabels(roomId, action, message = null) {
      this.keyLabels++
      this.labels = JSON.parse(JSON.stringify(this.labelsOrigin))
      this.selectedLabels = []

      // get selected label from contact labels
      let alertText = null
      const selectedLabels = []

      if (action == 'addLabel') {
        const room = this.rooms.find(room => room.roomId == roomId)

        if (room && room.labels && room.labels.length > 0) {
          room.labels.forEach(label => {
            const isLabelExists = this.labels.find(i => i.id == label.label.id)
            const indexLabel = this.labels.findIndex(i => i.id == label.label.id)

            if (isLabelExists) {
              selectedLabels.push(label.label)
            }

            // remove selected label
            // if (indexLabel >= 0) {
            //   this.labels.splice(indexLabel, 1)
            // }
          })
        }

        alertText = this.$t('inbox.addedLabelContact')
      } else if (action == 'labelMessage') {
        const messages = this.label.value.filter(item => item.chat != null)

        messages.forEach(item => {
          if (item.chat._id == message._id) {
            const isLabelExists = this.labels.find(i => i.id == item.label.id)
            const indexLabel = this.labels.findIndex(i => i.id == item.label.id)

            if (isLabelExists) {
              selectedLabels.push(item.label)
            }

            // remove selected label
            // if (indexLabel >= 0) {
            //   this.labels.splice(indexLabel, 1)
            // }
          }
        })

        alertText = this.$t('inbox.addedLabelMessage')
      }

      // uncomment to show selected label
      this.selectedLabels = selectedLabels

      if (this.labels && this.labels.length > 0) {
        this.dialogLabels = !this.dialogLabels
      } else {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = alertText
      }
    },
    async updateRoomLabels(label) {
      const roomIndex = this.rooms.findIndex(item => item.roomId == this.selectedRoom)
      const room = this.rooms.find(item => item.roomId == this.selectedRoom)

      if (room) {
        room.labels = [...label]
        room.labels = room.labels.filter((item, index, that) => index === that.findIndex(t => t.id === item.id))
      }

      if (roomIndex >= 0) {
        this.rooms = [...this.rooms]
      }
    },
    async updateMessageLabels(label, batch = false) {
      if (batch) {
        for (const message of this.currentMessages) {
          await this.putMessageLabels(label, message._id)
        }
      } else {
        await this.putMessageLabels(label, this.currentMessage._id)
      }
    },
    async putMessageLabels(label, id) {
      label = label.filter(item => item.chat._id == id)
      const messageIndex = this.messages.findIndex(item => item._id == id)

      if (messageIndex >= 0) {
        if (this.messages[messageIndex].labels && this.messages[messageIndex].labels.length > 0) {
          this.messages[messageIndex].labels = []
          this.messages[messageIndex].labels = label
          this.messages[messageIndex].labels = this.messages[messageIndex].labels.filter(
            (item, index, that) => index === that.findIndex(t => t.id === item.id),
          )
        } else {
          this.messages[messageIndex].labels = label
        }
      }

      this.messages = this.messages.filter(
        (message, index, that) => index === that.findIndex(t => t._id === message._id),
      )

      this.messages = _orderBy(this.messages, 'seconds', 'asc')

      setTimeout(() => {
        this.messages = [...this.messages]
      })
    },
    showHideContactListItem(index = 0) {
      const doc = document.getElementsByClassName('collapse')[index]

      if (doc) {
        if (doc.classList.contains('active') === true) {
          doc.classList.remove('active')
        } else {
          doc.classList.add('active')
        }
      }
    },
    setContactListItem(item) {
      this.dialogContactList = true
      this.activeContactList = item
      this.contactListName = item.list_name
    },
    inputFromDownloadMessage(from) {
      this.datepickerFromDownloadMessageDialog = false

      if (this.toDownloadMessages) {
        if (this.$moment(this.toDownloadMessages).unix() < this.$moment(from).unix()) {
          this.toDownloadMessages = null
        }
      }
    },
    downloadMessages() {
      if (this.fromDownloadMessages && this.toDownloadMessages) {
        const startDate = `${this.fromDownloadMessages} 00:00:00`
        const endDate = `${this.toDownloadMessages} 23:59:59`

        const messages = this.messages.filter(message => {
          message.sender_id = `+${message.sender_id}`
          message.instance_name = this.currentRoom.instance ? this.currentRoom.instance.label : ''
          message.instance_phone_number = this.currentRoom.instance ? `+${this.currentRoom.instance.phone_number}` : ''

          if (message.files && message.files.length > 0) {
            message.attachment = message.files[0].url
          }

          const date = this.$moment.unix(message.seconds)
          message.formatted_timestamp = date.format()

          return date.isBetween(startDate, endDate, null, '[]')
        })

        this.exportMessages(messages)
        this.fromDownloadMessages = null
        this.toDownloadMessages = null
        this.datepickerDownloadMessageDialog = false
      }
    },
    async addRoomDialog() {
      if (!this.contactListsRoom) {
        this.overlayGlobal = true

        this.contactListsRoom = await this.fdbGetContactListAddRoom(this.user.sub_id)

        if (this.contactListsRoom && Object.keys(this.contactListsRoom).length > 0) {
          if (this.contactListsRoom.default) {
            this.contactListRoom = this.optionsContactListsRoom[0]
            this.tableContactsRoom = this.contactListsRoom.default
          }
        }

        this.overlayGlobal = false
      }

      this.dialogAddRoom = true
      this.selectedContactRoom = []
      this.phoneNumberContactRoom = null
      this.panelContactsRoom = 0

      this.selectedInstanceRoom = this.optionsInstancesRoom[0]

      if (this.getGlobalChannel) {
        if (!this.getGlobalChannel.value.all_sender) {
          this.selectedInstanceRoom = this.getGlobalChannel
        }
      }
    },
    setReadMessages(sourceIds, room, senderId = null) {
      const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send-read`
      const body = {
        subId: room.subId,
        roomId: room.roomId,
        messageId: sourceIds,
        server: 'wabailey',
      }

      if (senderId) {
        body.senderId = senderId
      }

      this.$axios
        .post(endpoint, body)
        .then(response => {
          // //console.log(body)
        })
        .catch(error => {
          console.log(error)
        })
    },
    addHashToLocation(params) {
      if (this.$route.params && this.$route.params.phoneNumber) {
        history.replaceState({}, null, `/inbox/${encodeURIComponent(params)}`)
      } else {
        history.pushState({}, null, `${this.$route.path}/${encodeURIComponent(params)}`)
      }
    },
    onSelectCountryCode({ name, iso2, dialCode }) {
      this.countryCodeContactRoom = dialCode
    },
    getContactsName(phoneNumber) {
      const foundContact = this.contactListTable.find(x => x.phone_number === phoneNumber)

      if (foundContact) {
        return foundContact.name
      }

      return phoneNumber
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/src/styles/globalsScreen';

.table-contacts ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 578px) {
  .sm-text {
    font-size: 12px;
  }

  .display-none {
    display: none;
  }

  .display-mt-lg {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 578px) {
  .display-lg {
    display: flex;
  }
}

:root {
  --height: calc(100vh - 120px);
}

@mixin desktop {
  @media all and (min-width: 970px) {
    @content;
  }
}

@mixin mobile {
  @media all and (max-width: 970px) {
    @content;
  }
}

@include desktop {
  .mobile-padding-channel {
    display: flex;
  }

  .mobile-padding-channel-show {
    display: none;
  }
}

@include mobile {
  .mobile-padding-channel {
    display: none;
  }

  .mobile-padding-channel-mobile {
    display: flex;
  }
}

.collapse {
  overflow: hidden;
  width: 100%;
  height: 0;
  visibility: hidden;
  display: none;
  border: 0.5px solid #e5e5e5;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0 10px 0;

  &.active {
    overflow: hidden;
    width: 100%;
    height: auto;
    visibility: visible;
    display: block;
  }
}

.testAbs {
  height: 100px;
  width: 100px;
  content: ' ';
  position: absolute;
  background-color: chocolate;
  z-index: 10;
  bottom: 0;
}
#background-sidebar {
  @include screen('0px') {
    position: fixed !important;
    height: 100% !important;
  }
  @include screen('md') {
    position: relative !important;
    height: calc(100vh - 80px) !important;
  }
}
#chat {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px 0 0 0;

  .sidebar {
    border: none;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    -webkit-tap-highlight-color: transparent;
  }
  .background-sidebar {
    //top: 0;
    width: 0;
    overflow: hidden auto;
    right: 0;
    padding: 10px;
    z-index: 0;
    opacity: 0;
    transition-property: width, opacity;
    transition-duration: 300ms;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: blue;
    }

    ::-webkit-scrollbar-thumb {
      background-color: blue;
    }

    &.active {
      min-width: 360px;
      max-width: 500px;
      opacity: 1;
      z-index: 1;
    }

    &.mobile {
      width: 100%;
      opacity: 1;
      z-index: 1;
    }

    &.light-bg {
      background-color: white;
      //backdrop-filter: blur(50px);
    }

    &.dark-bg {
      background-color: #181a1b;
      //backdrop-filter: blur(50px);
    }

    &.no-alert {
      height: var(--height);
    }

    &.alert {
      height: calc(100vh - 120px);
    }
  }

  .quick-reply {
    position: absolute;
    right: 5%;
    bottom: 120px;
    z-index: 1;

    &.active {
      right: 38%;
    }
  }

  @include desktop {
    .quick-menu {
      position: absolute;
      left: 20%;
      bottom: 80px;
      z-index: 1;
    }
  }

  @include mobile {
    .quick-menu {
      position: absolute;
      right: 5%;
      bottom: 80px;
      z-index: 1;
    }
  }

  .menu-sidebar {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 0;
    //background-color: rgba(220, 204, 204, 0.17);
    z-index: 15;
    //backdrop-filter: blur(10px);
    padding: 10px;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition-property: width, opacity;
    transition-duration: 100ms;

    @include mobile {
      &.active {
        width: 100%;
        visibility: visible;
        opacity: 1;
      }
    }

    @include desktop {
      &.active {
        width: 30%;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .menu-quick-reply {
    position: absolute;
    min-height: 10%;
    height: 150px;
    //max-height: 150px;
    overflow: hidden auto;
    width: 200px;
    z-index: 15;
    top: 52%;
    border-radius: 5px;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition-property: width, opacity;
    transition-duration: 100ms;
    display: flex;
    flex-flow: column;

    &.light-bg {
      background-color: #efefef;
      //backdrop-filter: blur(50px);
    }

    &.dark-bg {
      background-color: black;
      //backdrop-filter: blur(50px);
    }

    @include desktop {
      &.active {
        right: 25px;
        visibility: visible;
        opacity: 1;
      }
    }

    @include mobile {
      &.active {
        right: 15px;
        top: 60%;

        visibility: visible;
        opacity: 1;
      }
    }
  }

  .card-note {
    background-color: #ffffff67;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #adadad;
  }

  .bg-visible {
    display: block;

    &.hide {
      display: none;
    }
  }
}

.bottom-action {
  display: none;
}

@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }

  .bottom-action {
    display: inherit;
  }

  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.height-dialog {
  height: 400px;
  overflow-y: auto;
}
// Reversed input variant
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
.v-input--expand .v-input__slot {
  // justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}
</style>
