<template>
  <div class="vac-room-container">
    <slot
      name="room-list-item"
      v-bind="{ room }"
    >
      <!-- Avatar -->
      <!-- {{roomActions}} -->
      <slot
        name="room-list-avatar"
        v-bind="{ room }"
      >
        <div
          v-if="room.avatar"
          class="vac-avatar"
          :style="{ 'background-image': `url('${room.avatar}')` }"
        />
      </slot>
      <div class="vac-name-container vac-text-ellipsis">
        <div class="d-flex">
          <div

            class="room-contact-name me-1 mt-1"
          >
            {{ room && room.instance && room.instance.label && (room.instance.label.includes('-') || room.instance.label.includes('Default') || room.instance.label.includes('62'))
              ? room.instance.label_server
              : room.instance.label }}
          </div>
          <div
            v-if="room.labels && room.labels.length > 0"
            class="mr-1"
          >
            <!-- <pre>{{room.labels}}</pre> -->
            <v-tooltip
              v-for="(label, index) in room.labels"
              :key="index"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  fab
                  size="15px"
                  v-bind="attrs"
                  :color="label.label.color"
                  v-on="on"
                >
                  <!-- @click="goToLabel()" -->
                  {{ icons.mdiLabel }}
                </v-icon>
              </template>
              <span>Label Name : {{ label.labelName }}</span>
            </v-tooltip>
          </div>
          <!-- <pre>  {{room.roomContactListLabel}}</pre> -->
          <div
            v-if="room.roomContactListLabel && room.roomContactListLabel.length > 0"
            class="mr-1"
          >
            <v-tooltip
              v-for="(label, index) in room.roomContactListLabel"
              :key="index"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  fab
                  size="15px"
                  v-bind="attrs"
                  :color="label.label_color"
                  v-on="on"
                >
                  <!-- @click="goToContacts(label)" -->
                  {{ icons.mdiLabelVariant }}
                </v-icon>
              </template>
              <span>Customer Group : {{ label.list_name }}</span>
            </v-tooltip>
          </div>
        </div>

        <!-- Contact Name -->
        <!-- <pre>{{room.roomContactListLabel}}</pre> -->
        <!-- <div
          v-if="room.roomContactName"
          class="room-contact-name"
        >
          {{ room.roomContactName }}
        </div> -->
        <div class="vac-title-container">
          <div
            v-if="room.instance"
            :class="`${room.instance.color ? 'vac-state-circle' : ''}`"
            :style="{ 'background-color': room.instance.color }"
          />
          <div
            :style="markUnrepliedBold ? 'font-weight:800' : ''"
            class="vac-room-name vac-text-ellipsis"
          >
            <!-- {{ getContactsNameRoom(room) }} -->

            <!-- <v-tooltip
              v-if="getContactsNameRoom"
              color="warning"
              type="info"
              dense
              bottom
              prominent
              border="right"
            >
              <span
                class="subtitle-2"
              >{{ bannerAlertText }}</span>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    size="14"
                    color="warning"
                  >
                    {{ icons.mdiInformation }}
                  </v-icon>
                  {{ room.roomName }}
                </div>
              </template>
            </v-tooltip> -->
            <!-- <div
              v-else
            > -->
            {{ room.roomName }}

            <!-- bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb -->
            <!-- </div> -->
          </div>
          <!-- Date send message -->
          <div
            v-if="room.lastMessage"
            class="vac-text-date"
          >
            {{ room.lastMessage.formattedTimestamp }}
          </div>
        </div>
        <div
          class="vac-text-last"
          :class="{
            'vac-message-new': room.lastMessage && room.lastMessage.new && !typingUsers,
          }"
        >
          <span v-if="isMessageCheckmarkVisible">
            <slot
              name="checkmark-icon"
              v-bind="{ message: room.lastMessage }"
            >
              <v-icon
                v-if="room.lastMessage && room.lastMessage.seen"
                class="vac-icon-check"
                small
                color="info"
              >
                {{ icons.mdiCheckAll }}
              </v-icon>
              <v-icon
                v-else-if="room.lastMessage && room.lastMessage.distributed"
                class="vac-icon-check"
                small
              >
                {{ icons.mdiCheckAll }}
              </v-icon>
              <v-icon
                v-else-if="room.lastMessage && room.lastMessage.send"
                class="vac-icon-check"
                small
              >
                {{ icons.mdiCheck }}
              </v-icon>
              <v-icon
                v-else-if="room.lastMessage && room.lastMessage.saved"
                class="vac-icon-check"
                small
              >
                {{ icons.mdiClockOutline }}
              </v-icon>
            </slot>
          </span>

          <div
            v-if="room.lastMessage && !room.lastMessage.deleted && isAudio"
            class="vac-text-ellipsis"
          >
            <slot name="microphone-icon">
              <svg-icon
                name="microphone"
                class="vac-icon-microphone"
              />
            </slot>
            {{ formattedDuration }}
          </div>
          <format-message
            v-else-if="room.lastMessage"
            :content="getLastMessage"
            :deleted="!!room.lastMessage.deleted && !typingUsers"
            :users="room.users"
            :unreplied="markUnrepliedBold"
            :linkify="false"
            :all-contacts="allContacts"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :single-line="true"
            :payload-message="room.lastMessage"
          >
            <template #deleted-icon="data">
              <slot
                name="deleted-icon"
                v-bind="data"
              />
            </template>
          </format-message>
          <div
            v-if="!room.lastMessage && typingUsers"
            class="vac-text-ellipsis"
          >
            {{ typingUsers }}
          </div>
          <div class="vac-room-options-container">
            <div
              v-if="room.unreadCount && room.unreadCount > 0"
              class="vac-badge-counter"
            >
              <span class="text-unread my-auto text--black">*</span>
              <!-- {{ room.unreadCount }} -->
            </div>
            <div
              v-if="room && room.pinned"
              class="vac-badge-counter"
            >
              <v-icon>
                {{ icons.mdiPin }}
              </v-icon>
            </div>
            <slot
              name="room-list-options"
              v-bind="{ room }"
            >
              <div
                v-if="roomActions.length"
                class="vac-svg-button vac-list-room-options"
                @click.stop="openMenu(room)"
                @click="$store.commit('global/setCurrentRoom', room)"
              >
                <slot name="room-list-options-icon">
                  <svg-icon
                    name="dropdown"
                    param="room"
                  />
                </slot>
              </div>
              <transition
                v-if="roomActions.length"
                name="vac-slide-left"
              >
                <div
                  v-if="roomMenuOpened === room.roomId"
                  v-click-outside="closeRoomMenu"
                  class="vac-menu-options"
                >
                  <div class="vac-menu-list">
                    <div
                      v-for="action in roomActions"
                      :key="action.name"
                    >
                      <div
                        class="vac-menu-item"
                        @click.stop="roomActionHandler(action)"
                      >
                        {{ truncateActionName(action.title) }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </slot>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import {
  mdiCheck,
  mdiCheckAll,
  mdiClockOutline,
  mdiInformation,
  mdiLabel,
  mdiLabelVariant,
  mdiPin,
  mdiStar,
} from '@mdi/js'
import vClickOutside from 'v-click-outside'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import typingText from '../../../utils/typing-text'

const { isAudioFile } = require('../../../utils/media-file')

export default {
  name: 'RoomContent',

  setup() {
    return {
      icons: {
        mdiLabelVariant,
        mdiLabel,
        mdiStar,
        mdiPin,
        mdiCheck,
        mdiCheckAll,
        mdiClockOutline,
        mdiInformation,
      },
    }
  },

  components: {
    SvgIcon,
    FormatMessage,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    room: { type: Object, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    textMessages: { type: Object, required: true },
    roomActions: { type: Array, required: true },
    allContacts: { type: Array, default: () => [] },
    allContactsFirestore: { type: Array, default: () => [] },
  },

  data() {
    return {
      roomMenuOpened: null,
      roomChannelDisconnected: false,
      userData: [],
    }
  },

  computed: {
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    bannerAlertText() {
      //console.log(this.$store.getters['inbox/getBannerAlertText'], `INI BANNER`)

      return this.$store.getters['inbox/getBannerAlertText']
    },
    instances() {
      const inst = this.$store.getters['auth/getListInstance']
      if (inst) {
        return inst.value
      }

      return []
    },
    markUnrepliedBold() {
      // //console.log(this.room.unreadCount, `INI UNREADNYA`)

      return this.room && this.room.unreadCount > 0
    },
    getLastMessage() {
      const isTyping = this.typingUsers
      if (isTyping) return isTyping

      const content = this.room.lastMessage.deleted ? this.textMessages.MESSAGE_DELETED : this.room.lastMessage.content

      if (!this.room.users) {
        return content
      }

      if (this.room.users.length <= 1) {
        return content
      }

      const user = this.room.users.find(user => user._id === this.room.lastMessage.senderId)

      // //console.log(user, `NI USER`)
      if (this.room.lastMessage.username && !this.room.lastMessage.fromMe) {
        return `${this.getContactsName(this.room.lastMessage.sender_id, this.room)} - ${content}`
      }

      if (!user || user._id === this.currentUserId) {
        return content
      }

      return `${this.getContactsName(user._id, this.room)} - ${content}`
    },
    userStatus() {
      if (!this.room.users || this.room.users.length !== 2) return

      const user = this.room.users.find(u => u._id !== this.currentUserId)
      if (user && user.status) return user.status.state

      return null
    },
    typingUsers() {
      return typingText(this.room, this.currentUserId, this.textMessages)
    },
    isMessageCheckmarkVisible() {
      return (
        !this.typingUsers &&
        this.room.lastMessage &&
        !this.room.lastMessage.deleted &&
        this.room.lastMessage.fromMe &&
        (this.room.lastMessage.saved ||
          this.room.lastMessage.send ||
          this.room.lastMessage.distributed ||
          this.room.lastMessage.seen)
      )
    },
    formattedDuration() {
      const file = this.room.lastMessage.files[0]

      if (!file.duration) {
        if (file.extension) {
          return `${file.name}.${file.extension}`
        }

        return file.name
      }

      let s = Math.floor(file.duration)

      return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
    },
    isAudio() {
      return this.room.lastMessage.files ? isAudioFile(this.room.lastMessage.files[0]) : false
    },
    getContactsNameRoom() {
      let isChannelDisconnected = false
      this.instances.forEach(instance => {
        if (instance._id === this.room.instance_id && instance.status == 1) {
          isChannelDisconnected = false

          // return room.roomName
        } else if (instance._id === this.room.instance_id && instance.status !== 1) {
          isChannelDisconnected = true
        }
      })

      return isChannelDisconnected
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  async mounted() {
    // this.userData = []
    // for (const user of this.room.users) {
    //   const userData = await this.$store.dispatch('inboxNavbar/getUserData', {
    //     phone_number: user._id,
    //     sub_id: this.user.sub_id,
    //     token: this.user.token,
    //   })
    //   if (userData.status) {
    //     // //console.log(userData)
    //     this.userData.push(userData.data)
    //   }
    // }
  },

  emits: ['room-action-handler'],

  methods: {
    truncateActionName(action) {
      if (this.$vuetify.breakpoint.xs) {
        if (action.length <= 30) {
          return action
        }

        return `${action.slice(0, 30)}...`
      }

      if (action.length <= 20) {
        return action
      }

      return `${action.slice(0, 20)}...`
    },
    goToLabel() {
      this.$router.push({ name: 'label-list' })
    },
    goToContacts(item) {
      // let stilAble = this.listContactValue.find(el => {
      //   return el.id === item.id
      // })

      // if (stilAble) {
      //   if (item.deleteable) {
      // //console.log(item, `INI ITEM`)

      this.$router.push({ name: 'contacts-list-custom', params: { idlist: item.list_id } })

      //   } else {
      //     this.$router.push('contacts-list')
      //   }
      // } else {
      //   this.$router.push({ name: 'contacts-group-list' })
      // }
    },

    // getContactsNameRoom(room) {
    //   // return room.roomName

    //   // if (this.allContactsFirestore) {
    //   //   const foundContact = this.allContactsFirestore.filter(x => x.phone_number === phoneNumber)

    //   //   if (foundContact.length > 0) {
    //   //     let name = `${foundContact[0].name} (`
    //   //     for (const contact of foundContact) {
    //   //       name += `${contact.list_name}, `
    //   //     }
    //   //     name = name.substring(0, name.length - 2)
    //   //     name += ')'

    //   //     if (name.endsWith('()')) {
    //   //       name = name.substring(0, name.length - 2)
    //   //     }

    //   //     return name
    //   //   }
    //   // }
    //   // if (this.allContacts) {
    //   //   const foundContact = this.allContacts.find(x => x.phone_number === phoneNumber)

    //   //   if (foundContact) {
    //   //     return foundContact.name
    //   //   }
    //   // }
    //   // return room.roomName
    // },
    getContactsName(phoneNumber, room) {
      const roomContact = this.allContactData.filter(user => user.phone_number === phoneNumber)
      const roomUser = room.users.find(user => user._id === phoneNumber)

      if (phoneNumber === '6282260567687') {
        //console.log(roomContact, roomUser, this.room.users, room.users, phoneNumber, `INI TEST NAME`)
      }

      if (this.contactListTableAll) {
        const foundContact = this.contactListTableAll.find(x => x.phone_number === phoneNumber)

        // //console.log(foundContact, `INI KONTAK LIST TABLE`)
        if (foundContact) {
          return foundContact.name
        }
      }

      if (this.couchAllContact) {
        const foundContact = this.couchAllContact.find(x => x.phone_number === phoneNumber)

        // //console.log(foundContact, `INI KONTAK COUCH`)

        if (foundContact) {
          return foundContact.name
        }
      }

      if (roomContact) {
        if (roomContact.bailey_name) {
          return roomContact.bailey_name
        }

        if (roomContact.notify_name) {
          return roomContact.notify_name
        }

        if (roomContact.contact_list_name) {
          return roomContact.contact_list_name
        }

        if (roomContact.username) {
          return roomContact.username
        }
      }

      if (roomUser) {
        if (roomUser.username !== phoneNumber) {
          return roomUser.username
        }
      }

      // if (roomContact) {
      //   if (roomContact.bailey_name) {
      //     return roomContact.bailey_name
      //   }

      //   if (roomContact.notify_name) {
      //     return roomContact.notify_name
      //   }

      //   if (!roomContact.notify_name && !roomContact.bailey_name) {
      //     // //console.log(roomContact, `INI ROOM KONTAK`)
      //     const foundContact = this.allContacts.find(contact => contact.phone_number === roomContact._id)
      //     if (foundContact) {
      //       return foundContact.name
      //     }

      //     if (roomContact.username.replace(/[+ -]/g, '') !== roomContact._id) {
      //       return roomContact.username
      //     }
      //   }
      // }

      return this.formatPhoneNumber(phoneNumber)

      // return roomContact && roomContact.username ? roomContact.username : phoneNumber
    },
    formatPhoneNumber(phoneNumber, group = false) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    roomActionHandler(action) {
      this.closeRoomMenu()
      this.$emit('room-action-handler', { action, roomId: this.room.roomId })
    },
    closeRoomMenu() {
      this.roomMenuOpened = null
    },
    openMenu(room) {
      this.roomMenuOpened = room.roomId
    },
  },
}
</script>
<style scoped>
.room-contact-name {
  font-size: 11px;
  color: #20a5fd;
}
.text-unread {
  font-size: 24px;
  color: #20a5fd;
  margin-left: 5px;
}
</style>
